import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Deletebooking, fetchAllbookings, Updatebooking, AddOneHourToPackage } from "../../store/actions/bookingActions";
import { getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import AdminNav from "./AdminNav";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { Table, Button, Card, Tag, message, Input, Space, Popconfirm, Select, Modal, Spin, Progress, List, Typography, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, StopOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import Loader2 from "../Loader2";
import { useMediaQuery } from 'react-responsive';

const { Option } = Select;
const { Text } = Typography;

const AdminBookings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.bookings.Allbookinglist);
  const [statusFilter, setStatusFilter] = useState("All");
  const timezone = localStorage.getItem('AdminTimezone') || 'America/New_York';
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('AdminHourFormat') || '24');
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isLoading, setIsLoading] = useState(true);
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [teacherJoined, setTeacherJoined] = useState(false);
  const [waitTimeLeft, setWaitTimeLeft] = useState(30);
  const [currentUTCTime, setCurrentUTCTime] = useState(moment().utc());
  const [currentLocalTime, setCurrentLocalTime] = useState(moment().tz(timezone));
  const [isJoinModalVisible, setIsJoinModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [isAppDownloaded, setIsAppDownloaded] = useState(localStorage.getItem('isAppDownloaded') === 'true');
  const admin = useSelector((state) => state.students.user);

  const showJoinModal = (id) => {
    setSelectedBookingId(id);
    setIsJoinModalVisible(true);
  };

  const handleJoinChoice = (choice) => {
    setIsJoinModalVisible(false);
    if (choice === 'browser') {
      joinBrowserRoom(selectedBookingId);
    } else if (choice === 'windows') {
      joinWindowsApp(selectedBookingId);
    }
  };

  const copyBookingId = () => {
    navigator.clipboard.writeText(selectedBookingId).then(() => {
      message.success('Booking ID copied to clipboard');
    }, (err) => {
      message.error('Failed to copy booking ID');
      console.error('Could not copy text: ', err);
    });
  };

  const checkAppInstallation = () => {
    return new Promise((resolve) => {
      const protocolCheck = 'speakable-online://';
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
  
      let isInstalled = false;
      const checkFocus = () => {
        if (document.hasFocus()) {
          isInstalled = true;
          cleanup();
        }
      };
  
      const interval = setInterval(checkFocus, 100);
  
      const cleanup = () => {
        clearInterval(interval);
        document.body.removeChild(iframe);
        window.removeEventListener('blur', onBlur);
        resolve(isInstalled);
      };
  
      const onBlur = () => {
        isInstalled = true;
        cleanup();
      };
  
      window.addEventListener('blur', onBlur);
  
      setTimeout(() => {
        if (!isInstalled) {
          cleanup();
        }
      }, 1000);
  
      iframe.src = protocolCheck;
    });
  };

  const joinBrowserRoom = (id) => {
    const roleInfo = encodeURIComponent(JSON.stringify({ role: 'admin', adminId: admin._id }));
    window.open(`/room/meeting/${id}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
  };

  const downloadApp = () => {
    const downloadUrl = 'https://speakable.online/api/Speakable-Online-Setup-2024.10.0.exe';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Speakable-Online-Setup-2024.10.0.exe';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsAppDownloaded(true);
    localStorage.setItem('isAppDownloaded', 'true');
    message.success('Downloading Speakable-Online application. Please install it after downloading.');
  };

  const joinWindowsApp = async (id) => {
    const isAppInstalled = await checkAppInstallation();
  
    const jitsiDomain = "meet.speakable.online";
    const roomName = id;
    const userInfo = encodeURIComponent(JSON.stringify({
      displayName: `${admin.Username} (Admin)`,
      email: admin.email,
      role: 'admin'
    }));
  
    const jitsiAppUrl = `speakable-online://${jitsiDomain}/${roomName}?jwt=${admin._id}&userInfo=${userInfo}#config.prejoinPageEnabled=false`;
    
    if (isAppInstalled) {
      window.location.href = jitsiAppUrl;
    } else if (!isAppDownloaded) {
      Modal.confirm({
        title: 'Speakable-Online App Not Detected',
        content: 'The Speakable-Online app does not appear to be installed. Would you like to download it now?',
        onOk() {
          downloadApp();
        },
        onCancel() {
          message.info('You can join the meeting via browser instead.');
        },
      });
    } else {
      message.info('Please install the Speakable-Online application and try again.');
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchAllbookings());
      } catch (error) {
        console.error("Error fetching bookings:", error);
        message.error("Failed to fetch bookings. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchBookings();
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentUTCTime(moment().utc());
      setCurrentLocalTime(moment().tz(timezone));
    }, 1000);

    return () => clearInterval(timer);
  }, [timezone]);

  const deleteBooking = (id) => {
    dispatch(Deletebooking(id))
      .unwrap()
      .then(() => {
        message.success('Booking deleted successfully');
        dispatch(fetchAllbookings());
      })
      .catch((error) => {
        message.error('Failed to delete booking: ' + error.message);
      });
  };

  const editBooking = (id, teacherId, studentId, date, start, end) => {
    navigate(`/Admin-Dashboard/Bookings/edit-Booking/${id}`, {
      state: { 
        teacherId, 
        studentId,
        selectedDate: date, 
        currentStart: start, 
        currentEnd: end
      }
    });
  };

  const cancelBooking = (record) => {
    const updatedData = {
      status: "Cancelled",
      Scheduled_Dates: record.Scheduled_Dates
    };
  
    dispatch(Updatebooking({ BookingID: record._id, updatedData }))
      .unwrap()
      .then(() => {
        message.success('Booking cancelled successfully');
        
        dispatch(AddOneHourToPackage({ 
          studentId: record.Student_ID._id, 
          packageId: record.Package_ID._id
        }))
          .unwrap()
          .then(() => {
            message.success('One hour added back to the student\'s package wallet');
          })
          .catch((error) => {
            message.error('Failed to add hour back to package wallet: ' + (error.response?.data?.message || error.message));
            console.error('Error adding hour to package wallet:', error);
          });
  
        dispatch(fetchAllbookings());
      })
      .catch((error) => {
        message.error('Failed to cancel booking: ' + (error.response?.data?.message || error.message));
        console.error('Error cancelling booking:', error);
      });
  };

  const joinRoom = (id, teacherId) => {
    setCurrentBookingId(id);
    setCurrentTeacherId(teacherId);
    setIsJoiningRoom(true);
    setTeacherJoined(false);
    setWaitTimeLeft(30);
  };

  const handleCancelJoining = () => {
    setIsJoiningRoom(false);
    setCurrentBookingId(null);
    setCurrentTeacherId(null);
    setTeacherJoined(false);
    setWaitTimeLeft(30);
  };

  const checkTeacherStatus = useCallback(async () => {
    if (!currentBookingId || !currentTeacherId) return;

    try {
      const result = await dispatch(getTeacherSessionStatus({ 
        bookingId: currentBookingId, 
        teacherId: currentTeacherId 
      }));

      if (result.payload && result.payload.isActive) {
        setTeacherJoined(true);
      }
    } catch (error) {
      console.error("Error checking teacher status:", error);
    }
  }, [currentBookingId, currentTeacherId, dispatch]);

  useEffect(() => {
    let intervalId;
    if (isJoiningRoom && !teacherJoined) {
      intervalId = setInterval(checkTeacherStatus, 5000); // Check every 5 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isJoiningRoom, teacherJoined, checkTeacherStatus]);

  useEffect(() => {
    let timerId;
    if (teacherJoined) {
      timerId = setInterval(() => {
        setWaitTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerId);
            setIsJoiningRoom(false);
            navigate(`/room/meeting/${currentBookingId}`);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [teacherJoined, currentBookingId, navigate]);

  const renderTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const momentTime = moment.utc().hour(hours).minute(minutes);
    return hourFormat === '12' 
      ? momentTime.tz(timezone).format('hh:mm A')
      : momentTime.tz(timezone).format('HH:mm');
  };

  const getBookingStatus = (date, start, end, originalStatus) => {
    const now = moment().utc();
    const bookingStart = moment.utc(`${date} ${start}`);
    const bookingEnd = moment.utc(`${date} ${end}`);
  
    if (originalStatus === "Rescheduled") {
      if (now.isAfter(bookingEnd)) return "Completed";
      if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return "In Progress";
      return "Rescheduled";
    }
    if (originalStatus === "Cancelled") return "Cancelled";
    if (now.isAfter(bookingEnd)) return "Completed";
    if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return "In Progress";
    return "Scheduled";
  };

  const processBookings = (bookings) => {
    const processedBookings = [];

    bookings.forEach(booking => {
      if (Array.isArray(booking.Scheduled_Dates)) {
        booking.Scheduled_Dates.forEach(dateObj => {
          if (typeof dateObj === 'object') {
            const date = Object.keys(dateObj)[0];
            const timeSlots = dateObj[date];

            if (Array.isArray(timeSlots) && timeSlots.length > 0) {
              let mergedSlots = [];
              let currentSlot = null;

              timeSlots.forEach((slot, index) => {
                if (!currentSlot) {
                  currentSlot = { ...slot, slots: [slot] };
                } else if (moment(slot.start, 'HH:mm').isSame(moment(currentSlot.end, 'HH:mm'))) {
                  // Extend the current slot
                  currentSlot.end = slot.end;
                  currentSlot.slots.push(slot);
                } else {
                  // Push the current slot and start a new one
                  mergedSlots.push(currentSlot);
                  currentSlot = { ...slot, slots: [slot] };
                }

                if (index === timeSlots.length - 1) {
                  mergedSlots.push(currentSlot);
                }
              });

              mergedSlots.forEach((slot) => {
                const startDateTime = moment.utc(`${date} ${slot.start}`);
                const endDateTime = moment.utc(`${date} ${slot.end}`);
                const localStartDateTime = startDateTime.clone().tz(timezone);
                const localEndDateTime = endDateTime.clone().tz(timezone);

                processedBookings.push({
                  ...booking,
                  date,
                  start: slot.start,
                  end: slot.end,
                  localDate: localStartDateTime.format('YYYY-MM-DD'),
                  localStart: localStartDateTime.format('HH:mm'),
                  localEnd: localEndDateTime.format('HH:mm'),
                  slotId: `${booking._id}-${date}-${slot.start}-${slot.end}`,
                  sortableTimestamp: startDateTime.valueOf(),
                  isConsecutive: slot.slots.length > 1,
                  slots: slot.slots,
                  status: getBookingStatus(date, slot.start, slot.end, booking.Status)
                });
              });
            }
          }
        });
      }
    });

    return processedBookings.sort((a, b) => a.sortableTimestamp - b.sortableTimestamp);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'purple';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'green';
      case 'Cancelled':
        return 'red';
      case 'Rescheduled':
        return 'blue';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: t("AdminBookings.subheading1"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
      render: (text, record) => record.Package_ID?.Package_Name || 'N/A',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Package Name"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.Package_ID?.Package_Name?.toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    {
      title: t("AdminBookings.subheading2"),
      dataIndex: ['Student_ID', 'Username'],
      key: 'studentName',
      render: (text, record) => record.Student_ID?.Username || 'N/A',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Student Name"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.Student_ID?.Username?.toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },
    {
      title: t("AdminBookings.subheading3"),
      dataIndex: ['Teacher_ID'],
      key: 'teacherName',
      render: (teachers) => Array.isArray(teachers) ? teachers.map(teacher => teacher.Username).join(', ') : 'N/A',
    },
    {
      title: t("AdminBookings.subheading4"),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status || 'N/A'}
        </Tag>
      ),
      filters: [
        { text: 'All', value: 'All' },
        { text: 'Scheduled', value: 'Scheduled' },
        { text: 'Rescheduled', value: 'Rescheduled' },
        { text: 'Scheduled + Rescheduled', value: 'ScheduledRescheduled' },
        { text: 'In Progress', value: 'In Progress' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Cancelled', value: 'Cancelled' },
      ],
      onFilter: (value, record) => {
        if (value === 'All') return true;
        if (value === 'ScheduledRescheduled') {
          return record.status === 'Scheduled' || record.status === 'Rescheduled';
        }
        return record.status === value;
      },
    },
    {
      title: t("AdminBookings.subheading5"),
      key: 'scheduledDate',
      render: (text, record) => (
        <span>
          {moment(record.localDate).format('MMMM Do, YYYY')}
          <br />
          {`${record.localStart} - ${record.localEnd}`}
          {record.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              Consecutive Booking
            </Tag>
          )}
        </span>
      ),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("AdminBookings.subheading6"),
      key: 'actions',
      render: (text, record) => {
        const isButtonDisabled = record.status === "Cancelled" || record.status === "Completed";

        return (
          <Space>
            {!isButtonDisabled && (
              <>
                <Button 
                  icon={<EditOutlined />} 
                  onClick={() => editBooking(record._id, record.Teacher_ID[0]?._id, record.Student_ID?._id, record.date, record.start, record.end)}
                />
                <Popconfirm
                  title="Are you sure you want to cancel this booking?"
                  onConfirm={() => cancelBooking(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<StopOutlined />}>
                    Cancel
                  </Button>
                </Popconfirm>
              </>
            )}
            <Button 
              icon={<DeleteOutlined />} 
              onClick={() => deleteBooking(record._id)}
              danger
            />
            <Button 
              type="primary"
              onClick={() => showJoinModal(record._id)}
              disabled={isButtonDisabled}
              style={{ backgroundColor: '#74be57', borderColor: '#44643c' }}
            >
              {t("AdminBookings.JoinRoom")}
            </Button>
          </Space>
        );
      },
    },
  ];

const processedBookings = processBookings(bookings);

const filteredBookings = processedBookings.filter(booking => 
  statusFilter === "All" || 
  (statusFilter === "ScheduledRescheduled" ? 
    (booking.status === "Scheduled" || booking.status === "Rescheduled") : 
    booking.status === statusFilter)
);

const renderMobileCard = (item) => {
  const isButtonDisabled = item.status === "Cancelled" || item.status === "Completed";
  

  return (
    <Card style={{ marginBottom: 16 }}>
      <Space direction="vertical" size="small">
        <Text strong>{t("AdminBookings.subheading1")}: {item.Package_ID?.Package_Name || 'N/A'}</Text>
        <Text>{t("AdminBookings.subheading2")}: {item.Student_ID?.Username || 'N/A'}</Text>
        <Text>{t("AdminBookings.subheading3")}: {Array.isArray(item.Teacher_ID) ? item.Teacher_ID.map(teacher => teacher?.Username).join(", ") : 'N/A'}</Text>
        <Text>{t("AdminBookings.subheading4")}: 
            <Tag color={getStatusColor(item.status)} style={{ marginLeft: 8 }}>
              {item.status || 'N/A'}
            </Tag>
          </Text>
        <Text>{t("AdminBookings.subheading5")}: {moment(item.localDate).format('MMMM Do, YYYY')}</Text>
        <Text>Time: {item.localStart} - {item.localEnd}
          {item.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              Consecutive Booking
            </Tag>
          )}
        </Text>
        <Space>
          {!isButtonDisabled && (
            <>
              <Button 
                icon={<EditOutlined />} 
                onClick={() => editBooking(item._id, item.Teacher_ID[0]?._id, item.Student_ID?._id, item.date, item.start, item.end)}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure you want to cancel this booking?"
                onConfirm={() => cancelBooking(item)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<StopOutlined />}>
                  Cancel
                </Button>
              </Popconfirm>
            </>
          )}
          <Button 
            icon={<DeleteOutlined />} 
            onClick={() => deleteBooking(item._id)}
            danger
          >
            Delete
          </Button>f
          <Button 
      type="primary"
      onClick={() => showJoinModal(item._id)}
      disabled={item.status === "Cancelled" || item.status === "Completed"}
      style={{ width: '100%', marginTop: '10px' }}
    >
      {t("AdminBookings.JoinRoom")}
    </Button>
        </Space>
      </Space>
    </Card>
  );
};

if (isLoading) {
  return (
    <Loader2 
      loading={true}
      text="Loading Bookings"
      fullScreen={true}
      size={300}
      customStyles={{
        backgroundColor: 'rgba(15, 15, 15, 0.5)'
      }}
    />
  );
}

return (
  <>
    <AdminNav />
    <div style={{ padding: '20px' }}>
      <Card title={t("AdminBookings.heading")} style={{ width: '100%' }}>
        {/* <div style={{ marginBottom: '20px' }}>
          <Text strong>Current UTC Time: </Text>
          <Text>{currentUTCTime.format('YYYY-MM-DD HH:mm:ss')}</Text>
          <br />
          <Text strong>Current Local Time ({timezone}): </Text>
          <Text>{currentLocalTime.format('YYYY-MM-DD HH:mm:ss')}</Text>
        </div> */}
        <Space style={{ marginBottom: 16 }}>
        <Select
  defaultValue="All"
  style={{ width: 180 }}
  onChange={(value) => setStatusFilter(value)}
>
  <Option value="All">All Statuses</Option>
  <Option value="Scheduled">Scheduled</Option>
  <Option value="Rescheduled">Rescheduled</Option>
  <Option value="ScheduledRescheduled">Scheduled + Rescheduled</Option>
  <Option value="In Progress">In Progress</Option>
  <Option value="Completed">Completed</Option>
  <Option value="Cancelled">Cancelled</Option>
</Select>
          </Space>
        {isMobile ? (
          <List
            dataSource={filteredBookings}
            renderItem={renderMobileCard}
            pagination={{ pageSize: 10 }}
          />
        ) : (
          <Table 
            columns={columns} 
            dataSource={filteredBookings}
            rowKey="slotId"
            pagination={{ pageSize: 10 }}
            style={{width: '100%'}}
            sortDirections={['ascend', 'descend']}
            defaultSortOrder="ascend"
            defaultSortField="sortableTimestamp"
          />
        )}
      </Card>
    </div>
    <Modal
      visible={isJoiningRoom}
      title="Joining Room"
      footer={null}
      onCancel={handleCancelJoining}
    >
      <div style={{ textAlign: 'center' }}>
        {!teacherJoined ? (
          <>
            <Spin size="large" />
            <p style={{ marginTop: 16 }}>Waiting for the teacher to join...</p>
          </>
        ) : (
          <>
            <Progress type="circle" percent={Math.round((30 - waitTimeLeft) / 30 * 100)} />
            <p style={{ marginTop: 16 }}>Teacher is setting up. Please wait for {waitTimeLeft} seconds...</p>
          </>
        )}
      </div>
    </Modal>
    <Modal
        title="Join Meeting"
        visible={isJoinModalVisible}
        onCancel={() => setIsJoinModalVisible(false)}
        footer={null}
      >
        <p>How would you like to join the meeting?</p>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 32px)' }}
              value={selectedBookingId}
              readOnly
              placeholder="Booking ID"
            />
            <Tooltip title="Copy Booking ID">
              <Button icon={<CopyOutlined />} onClick={copyBookingId} />
            </Tooltip>
          </Input.Group>
          <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button onClick={() => handleJoinChoice('browser')}>Join with Browser</Button>
            <Space>
              <Button onClick={() => handleJoinChoice('windows')}>
                Join with Windows App
              </Button>
              {!isAppDownloaded && (
                <Tooltip title="Download Speakable-Online App">
                  <Button 
                    icon={<DownloadOutlined />} 
                    onClick={downloadApp}
                    type="text"
                  />
                </Tooltip>
              )}
            </Space>
          </Space>
        </Space>
      </Modal>
  </>
);
};

export default AdminBookings;