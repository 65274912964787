import React, { useEffect, useState } from "react";
import AdminNav from "../admin-dashboard-components/AdminNav";
import Select from 'react-select';
import moment from 'moment-timezone';
import { useParams } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../helpers/axiosconfig"; 
import { useTranslation } from "react-i18next";

const AdminSettings = () => {
  const { email } = useParams();
  const [adminData, setAdminData] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState('America/New_York');
  const [hourFormat, setHourFormat] = useState('24');
  const [currentLanguage, setcurrentLanguage] = useState("Language");
  const [t, i18n] = useTranslation("global");


  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        // const response = await axiosInstance.get(`api/get_admin/${email}`);
        const response = await axiosInstance.get(`api/get_admin/admin@speakable.online`);
        // const response = await axios.get(`http://localhost:3000/api/api/get_admin/admin@speakable.online`);
        setAdminData(response.data);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }

      const storedTimezone = localStorage.getItem('AdminTimezone');
      if (storedTimezone) {
        setSelectedTimezone(storedTimezone);
      }

      const storedHourFormat = localStorage.getItem('AdminHourFormat');
      if (storedHourFormat) {
        setHourFormat(storedHourFormat);
      }
    };

    fetchAdminData();
  }, [email]);

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('AdminTimezone', timezone);
  };

  const handleChangeLanguage = (lang) => {
    if (lang === "pl") {
      setcurrentLanguage("Polsku");
    }
    if (lang === "en") {
      setcurrentLanguage("English");
    }
    i18n.changeLanguage(lang);
  };

  const handleHourFormatChange = (event) => {
    const format = event.target.value;
    setHourFormat(format);
    localStorage.setItem('AdminHourFormat', format);
  };

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  if (!adminData) {
    return <div>Loading...</div>;
  }

  const currentTime = moment().tz(selectedTimezone).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm');

  return (
    <>
      <AdminNav />
      <div className="StudentProfile_mainPage_style">
        <div className="StudentProfile_header_style">
          <div className="Profile_header_imgdiv">
            <img
              src="path_to_default_admin_image.jpg"
              alt="Admin"
            />
          </div>
        </div>

        <div className="row StudentName_style">
          <h1 className="studentName">{adminData.Username}</h1>
          <p className="studenttext">{adminData.UserType}</p>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 ">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-person userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">Email:</p>
                <p className="userDetailTextData">{adminData.Email}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-h-square-fill userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Preferred Timezone:</p>
                <Select
                  id="timezone"
                  className="form-control"
                  value={timezoneOptions.find(option => option.value === selectedTimezone)}
                  onChange={handleTimezoneChange}
                  options={timezoneOptions}
                  isSearchable
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-clock userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Hour Format:</p>
                <select
                  value={hourFormat}
                  onChange={handleHourFormatChange}
                  className="form-control"
                >
                  <option value="12">12-hour</option>
                  <option value="24">24-hour</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-clock userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Language:</p>
                <div className="dropdown">
            <button
              className="btn btn-outline-success dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i class="bi bi-globe-central-south-asia mx-2"></i>
              {currentLanguage}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleChangeLanguage("en")}
                >
                  {" "}
                  <img
                    style={{
                      border: "0.01px solid rgb(8 8 8 / 46%)",
                      marginRight: "15px",
                    }}
                    width={25}
                    height={15}
                    src={require("../../assets/English.webp")}
                    alt="English"
                  />{" "}
                  English
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleChangeLanguage("pl")}
                >
                  {" "}
                  <img
                    style={{
                      border: "0.01px solid rgb(8 8 8 / 46%)",
                      marginRight: "15px",
                    }}
                    width={25}
                    height={15}
                    src={require("../../assets/Polish.png")}
                    alt="polish"
                  />{" "}
                  Polish
                </a>
              </li>
            </ul>
          </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-clock-history userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Current Time:</p>
                <p>{currentTime}</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AdminSettings;