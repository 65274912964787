// walletActions.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from "../../helpers/axiosconfig";

export const fetchWallet = createAsyncThunk(
  'wallet/fetchWallet',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`wallet/${userId}`);
      return response.data;
    } catch (error) {
      toast.error('Failed to fetch wallet data');
      return rejectWithValue(error.message);
    }
  }
);

export const fetchWalletBalance = createAsyncThunk(
  'wallet/fetchWalletBalance',
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`wallet/balance/${studentId}`);
      return response.data;
    } catch (error) {
      toast.error('Failed to fetch wallet balance');
      return rejectWithValue(error.message);
    }
  }
);



export const purchaseHours = createAsyncThunk(
  'wallet/purchaseHours',
  async ({ studentId, packageId, hours, amount }, { rejectWithValue }) => {
    try {
      const response = await axios.post('wallet/purchase', { studentId, packageId, hours, amount });
      toast.success(`Successfully purchased ${hours} Sessions for ${amount}`);
      return {
        packageId,
        newBalance: response.data.newBalance,
        newPackageBalance: response.data.newPackageBalance
      };
    } catch (error) {
      toast.error('Failed to purchase Sessions');
      return rejectWithValue(error.message);
    }
  }
);

export const useHours = createAsyncThunk(
  'wallet/useHours',
  async ({ studentId, packageId, hours }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth?.token || localStorage.getItem('token'); 

      const response = await axios.post('/wallet/use', 
        { studentId, packageId, hours },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to use Sessions from wallet');
    }
  }
);

export const fetchWalletTransactions = createAsyncThunk(
  'wallet/fetchTransactions',
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`wallet/transactions/${studentId}`);
      return response.data.transactions;
    } catch (error) {
      toast.error('Failed to fetch wallet transactions');
      return rejectWithValue(error.message);
    }
  }
);

export const bookLessonWithWallet = createAsyncThunk(
  'wallet/bookLesson',
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('wallet/book-lesson', formData);
      toast.success('Lesson booked successfully');
      return response.data;
    } catch (error) {
      toast.error('Failed to book lesson');
      return rejectWithValue(error.message);
    }
  }
);

export const addHoursToWallet = createAsyncThunk(
  'wallet/addHours',
  async ({ studentId, hours }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`wallet/${studentId}/add`, { hours });
      toast.success(`${hours} Sessions added to wallet`);
      return response.data;
    } catch (error) {
      toast.error('Failed to add Sessions to wallet');
      return rejectWithValue(error.message);
    }
  }
);

export const deductHoursFromWallet = createAsyncThunk(
  'wallet/deductHours',
  async ({ studentId, hours }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`wallet/${studentId}/deduct`, { hours });
      toast.success(`${hours} Sessions deducted from wallet`);
      return response.data;
    } catch (error) {
      toast.error('Failed to deduct Sessions from wallet');
      return rejectWithValue(error.message);
    }
  }
);

export const addPendingHoursToWallet = createAsyncThunk(
  'wallet/addPendingHours',
  async ({ studentId, hours }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`wallet/${studentId}/add-pending`, { hours });
      return response.data;
    } catch (error) {
      toast.error('Failed to add pending Sessions to wallet');
      return rejectWithValue(error.message);
    }
  }
);

export const confirmPendingHours = createAsyncThunk(
  'wallet/confirmPendingHours',
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`wallet/${studentId}/confirm-pending`);
      toast.success('Pending Sessions confirmed');
      return response.data;
    } catch (error) {
      toast.error('Failed to confirm pending Sessions');
      return rejectWithValue(error.message);
    }
  }
);