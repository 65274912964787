import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Signin_user, initializeWebSocket } from "../store/actions/studentsActions";
import { Link, useNavigate } from "react-router-dom";
import FillEmailPopup from "./FillEmailPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegEye, FaRegEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

const SigninFormPopup = ({ handleClose, returnUrl = "/dashboard" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  // State variables
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [errors, setErrors] = useState({});
  const [FillEmail, setFillEmail] = useState(false);
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [animationPaused, setAnimationPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchref = useRef(false);

  // Get the auth state from Redux store with a default value
  const auth = useSelector((state) => state.auth) || {};
  const { loading = false, error = null, user = null } = auth;
  const [socket, setSocket] = useState(null);



  useEffect(() => {
    if (user) {
      toast.success("Signed in successfully!");
      handleClose();
      navigate(returnUrl); // Use the returnUrl prop here
    }
  }, [user, navigate, handleClose, returnUrl]);

  useEffect(() => {
    if (error) {
      toast.error(error.message || "Sign in failed. Please try again.");
    }
  }, [error]);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!Email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!Password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    } else if (Password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
      isValid = false;
    } else if (!/(?=.*\d)(?=.*[!@#$%^&*])/.test(Password)) {
      errors.password =
        "Password must contain at least one number and one special character";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const loginResult = await dispatch(Signin_user({ Email, Password })).unwrap();
        if (loginResult?.data) {
          const wsResponse = await dispatch(initializeWebSocket()).unwrap();
          console.log('WebSocket initialization response:', wsResponse);
        }
      } catch (err) {
        toast.error(err.message || "Sign in failed. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const closeFillEmailFormPopup = () => setFillEmail(false);
  const openFillEmailFormPopup = () => setFillEmail(true);
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const onChange = (value) => {
    setRecaptcha(value);
    setVerified(true);
  };
  const handleInputFocus = () => setAnimationPaused(true);
  const handleInputBlur = () => setAnimationPaused(false);

  return (
    <>
      <div className="form-popup">
        <div className={`form-popup-content ${animationPaused ? 'paused' : ''}`}>
          <form onSubmit={SubmitHandler}>
            <h5>{t("Signinform.heading")}</h5>
            <div className="form-group-sign">
              <input
                type="email"
                id="Email"
                name="Email"
                placeholder={t("Signinform.Email")}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
              <label className="text-end">
                hide{" "}
                <span onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder={t("Signinform.Password")}
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              {errors.password && <span className="error">{errors.password}</span>}
            </div>
            <label>
              {t("Signinform.Forgot")}
              <Link onClick={openFillEmailFormPopup}> {t("Signinform.Recover")}</Link>
            </label>
            <ReCAPTCHA
              sitekey="6LdyugQqAAAAAJZTuDB8Wg9Z6kDOGv77l0VDmmvg"
              onChange={onChange}
              ref={recaptchref}
            />
            <div className="d-flex mt-4">
              <button
                type="submit"
                className="btn btn-outline-success mx-3"
                disabled={!verified || isLoading || loading}
              >
                {(isLoading || loading) ? 'Signing in...' : t("Signinform.Submit")}
              </button>
              <button
                onClick={handleClose}
                className="btn btn-outline-success mx-3"
                type="button"
              >
                {t("Signinform.Close")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {FillEmail && <FillEmailPopup handleClose={closeFillEmailFormPopup} />}
    </>
  );
};

export default SigninFormPopup;