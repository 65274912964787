import { useState, useEffect } from 'react';

const currencyMap = {
  'Asia/Kolkata': { code: 'INR', symbol: '₹' },
  'Europe/Warsaw': { code: 'PLN', symbol: 'zł' },
  'America/New_York': { code: 'USD', symbol: '$' },
  // Add more timezones and their corresponding currencies as needed
};

export const useCurrencyConversion = (timezone) => {
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        // Replace with your preferred currency API
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/PLN');
        const data = await response.json();
        setExchangeRates(data.rates);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
        setLoading(false);
      }
    };

    fetchExchangeRates();
  }, []);

  const convertPrice = (priceInPLN) => {
    if (loading || !currencyMap[timezone]) {
      return `${priceInPLN} PLN`;
    }

    const targetCurrency = currencyMap[timezone].code;
    const exchangeRate = exchangeRates[targetCurrency];
    const convertedPrice = (priceInPLN * exchangeRate).toFixed(2);
    return `${currencyMap[timezone].symbol}${convertedPrice}`;
  };

  return { convertPrice, loading };
};