import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PaymentRedirect = () => {
  const { redirectToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await fetch(`/api/payment-redirect/${redirectToken}`);
        if (response.ok) {
          const data = await response.json();
          navigate('/Your-Bookings-has-been-confirmed', { state: { orderId: data.orderId }, replace: true });
        } else {
          // Handle error
          console.error('Failed to process payment redirect');
          navigate('/');
        }
      } catch (error) {
        console.error('Error during payment redirect:', error);
        navigate('/');
      }
    };

    handleRedirect();
  }, [redirectToken, navigate]);

  return <div>Processing your payment...</div>;
};

export default PaymentRedirect;