import React, { useState } from 'react';
import moment from 'moment-timezone';
import './SmallCalendar.css';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const SmallCalendar = ({ 
  onChange, 
  availableDates,
  selectedTimezone,
  hourFormat,
  teacherTimezone 
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [noSlotsMessage, setNoSlotsMessage] = useState('');

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const getPreviousMonth = (date) => new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const getNextMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const convertTime = (dateStr, timeStr, fromTimezone, toTimezone) => {
    // Create a datetime string by combining the date and time
    const datetime = moment.tz(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm', fromTimezone);
    return datetime.tz(toTimezone).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
  };

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    setSelectedTimeSlots([]);
    setNoSlotsMessage('');
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    setSelectedTimeSlots([]);
    setNoSlotsMessage('');
  };

  const handleDateClick = (day) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDate(newDate);
    onChange(newDate);

    // Format date string
    const dateString = moment(newDate).format('YYYY-MM-DD');
    
    const slots = availableDates[dateString]?.slots || [];
    console.log('Date String:', dateString);
    console.log('Available slots:', slots);

    if (slots.length === 0) {
      setNoSlotsMessage('No time slots available for this date');
      setSelectedTimeSlots([]);
    } else {
      setNoSlotsMessage('');
      // Convert each time slot
      const convertedSlots = slots.map(slot => ({
        start: convertTime(dateString, slot.start, teacherTimezone, selectedTimezone),
        end: convertTime(dateString, slot.end, teacherTimezone, selectedTimezone)
      }));
      
      console.log('Converted slots:', convertedSlots);
      setSelectedTimeSlots(convertedSlots);
    }
  };

  const isDateAvailable = (year, month, day) => {
    const dateString = moment(new Date(year, month, day)).format('YYYY-MM-DD');
    return availableDates && 
           availableDates[dateString] && 
           Array.isArray(availableDates[dateString].slots) && 
           availableDates[dateString].slots.length > 0;
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDayOfMonth = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());
    const days = [];

    // Empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected = day === selectedDate.getDate() &&
                        currentDate.getMonth() === selectedDate.getMonth() &&
                        currentDate.getFullYear() === selectedDate.getFullYear();
      const isToday = day === new Date().getDate() &&
                      currentDate.getMonth() === new Date().getMonth() &&
                      currentDate.getFullYear() === new Date().getFullYear();
      const isAvailable = isDateAvailable(currentDate.getFullYear(), currentDate.getMonth(), day);

      days.push(
        <div
          key={day}
          onClick={() => handleDateClick(day)}
          className={`calendar-day ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''} ${isAvailable ? 'available' : ''}`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <div className="current-date">
          <div className="day">{daysOfWeek[selectedDate.getDay()]}</div>
          <div className="date">{selectedDate.getDate()}</div>
        </div>
        <div className="month-year">
          <div>{months[selectedDate.getMonth()]}</div>
          <div>{selectedDate.getFullYear()}</div>
        </div>
      </div>
      <div className="calendar-body">
        <div className="month-navigation">
          <button onClick={handlePrevMonth} className="nav-button">&#8249;</button>
          <div className="current-month">{months[currentDate.getMonth()]} {currentDate.getFullYear()}</div>
          <button onClick={handleNextMonth} className="nav-button">&#8250;</button>
        </div>
        <div className="weekdays">
          {daysOfWeek.map(day => (
            <div key={day} className="weekday">{day}</div>
          ))}
        </div>
        <div className="days">
          {renderCalendarDays()}
        </div>
      </div>
      
      <div className="calendar-time-slots">
        {selectedTimeSlots.length > 0 ? (
          <>
            <h4>Available Time Slots</h4>
            <div className="time-slots-list">
              {selectedTimeSlots.map((slot, index) => (
                <div key={index} className="time-slot-item">
                  {slot.start} - {slot.end}
                </div>
              ))}
            </div>
          </>
        ) : (
          noSlotsMessage && (
            <div className="no-slots-message">
              {noSlotsMessage}
            </div>
          )
        )}
      </div>

      <div className="calendar-footer">
        <span>{months[getPreviousMonth(currentDate).getMonth()]}</span>
        <span>{months[currentDate.getMonth()]}</span>
        <span>{months[getNextMonth(currentDate).getMonth()]}</span>
      </div>
    </div>
  );
};

export default SmallCalendar;