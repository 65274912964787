import { createSlice } from "@reduxjs/toolkit";
import { 
  fetchWallet,
  fetchWalletBalance, 
  purchaseHours, 
  fetchWalletTransactions,
  bookLessonWithWallet,
  addHoursToWallet,
  deductHoursFromWallet,
  addPendingHoursToWallet,
  confirmPendingHours,
  useHours,
} from '../actions/walletActions';

const initialState = {
  balance: 0,
  packageBalances: {},
  pendingHours: 0,
  transactions: [],
  loading: false,
  error: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    // Add any synchronous reducers here if needed
    resetWalletError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Wallet
      .addCase(fetchWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWallet.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.transactions = action.payload.transactions;
        state.loading = false;
      })
      .addCase(fetchWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Wallet Balance
      .addCase(fetchWalletBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.packageBalances = action.payload.packageBalances;
        state.loading = false;
      })
      .addCase(fetchWalletBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Purchase Hours
      .addCase(purchaseHours.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(purchaseHours.fulfilled, (state, action) => {
        state.balance = action.payload.newBalance;
        state.packageBalances = {
          ...state.packageBalances,
          [action.payload.packageId]: action.payload.newPackageBalance
        };
        state.loading = false;
      })
      .addCase(purchaseHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Use Hours
      .addCase(useHours.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(useHours.fulfilled, (state, action) => {
        state.balance = action.payload.newBalance;
        state.packageBalances = {
          ...state.packageBalances,
          [action.payload.packageId]: action.payload.newPackageBalance
        };
        state.loading = false;
      })
      .addCase(useHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Book Lesson With Wallet
      .addCase(bookLessonWithWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bookLessonWithWallet.fulfilled, (state, action) => {
        state.balance = action.payload.newBalance;
        state.loading = false;
      })
      .addCase(bookLessonWithWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Wallet Transactions
      .addCase(fetchWalletTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(fetchWalletTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add Hours To Wallet
      .addCase(addHoursToWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addHoursToWallet.fulfilled, (state, action) => {
        state.balance = action.payload.newBalance;
        state.loading = false;
      })
      .addCase(addHoursToWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Deduct Hours From Wallet
      .addCase(deductHoursFromWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deductHoursFromWallet.fulfilled, (state, action) => {
        state.balance = action.payload.newBalance;
        state.loading = false;
      })
      .addCase(deductHoursFromWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add Pending Hours To Wallet
      .addCase(addPendingHoursToWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPendingHoursToWallet.fulfilled, (state, action) => {
        state.pendingHours = action.payload;
        state.loading = false;
      })
      .addCase(addPendingHoursToWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Confirm Pending Hours
      .addCase(confirmPendingHours.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(confirmPendingHours.fulfilled, (state) => {
        state.balance += state.pendingHours;
        state.pendingHours = 0;
        state.loading = false;
      })
      .addCase(confirmPendingHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetWalletError } = walletSlice.actions;
export default walletSlice.reducer;