import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { async_removeuser, updateStudent } from "../store/actions/studentsActions";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import moment from 'moment-timezone';

const StudentDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const student = useSelector((state) => state.students.user);
  const selectedTimezone = useSelector((state) => state.students.user.Timezone);

  const [menuInactive, setMenuInactive] = useState(false);
  const [currentLanguage, setcurrentLanguage] = useState("Language");
  const [isUpdatingTimezone, setIsUpdatingTimezone] = useState(false);
  const [t, i18n] = useTranslation("global");

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  useEffect(() => {
    navigate("/Student-dashboard/dash");
  }, []);

  const logoutHandler = () => {
    dispatch(async_removeuser());
  };

  const menuHandler = () => {
    setMenuInactive(!menuInactive);
  };

  const closeMenuHandler = (e) => {
    e.preventDefault();
    setMenuInactive(!menuInactive);
  };

  const handleChangeLanguage = (lang) => {
    if (lang === "pl") {
      setcurrentLanguage("Polsku");
    }
    if (lang === "en") {
      setcurrentLanguage("English");
    }
    i18n.changeLanguage(lang);
  };

  const handleTimezoneChange = async (selectedOption) => {
    const newTimezone = selectedOption.value;
    setIsUpdatingTimezone(true);
    try {
      const updatedData = {
        ...student,
        Timezone: newTimezone,
      };
      await dispatch(updateStudent({ student_ID: student._id, updatedData }));
      window.location.reload();
    } catch (error) {
      console.error("Error updating timezone:", error);
      setIsUpdatingTimezone(false);
    }
  };

  return (
    <div className="col-md-12  Admin-Dashboard_main_div">
      <div
        className={`col-md-12 Admin-Dashboard_main_div ${
          menuInactive ? "inactive" : ""
        }`}
      >
        <div className="Admin-Dashboard_main_left_div">
          <NavLink
            to="/Student-dashboard/dash"
            className="Admin-Dashboard_main_left_header_div"
          >
            <h5 style={{ textAlign: "center" }}>{t("StudentDash.heading")}</h5>
            <h6 style={{ fontSize: "12px" }}>{t("StudentDash.panel")}</h6>
          </NavLink>

          {/* Language and Timezone Selectors */}
          <div className="Admin-Dashboard_selectors">
            
            
            <div className="timezone-selector">
              <Select
                value={timezoneOptions.find(option => option.value === selectedTimezone)}
                onChange={handleTimezoneChange}
                options={timezoneOptions}
                isDisabled={isUpdatingTimezone}
                placeholder={t("StudentDash.SelectTimezone")}
              />
              {isUpdatingTimezone && <span>{t("common.Updating")}</span>}
            </div>
          </div>
          
          {!menuInactive ? (
            <div className="Admin-Dashboard_main_left_router_div">
              <NavLink
                to="/Student-dashboard/dash"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/dash"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("StudentDash.panelheading1")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/profile"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/profile"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("StudentDash.panelheading2")}</span>
              </NavLink>
              {/* <NavLink
                to="/Student-dashboard/Packages"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/Packages"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-basket3-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading3")}</span>
              </NavLink> */}
              <NavLink
                to="/Student-dashboard/Bookings"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/Bookings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-bookmark-dash-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading4")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/Payments"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/Payments"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-currency-dollar"></i>
                </span>
                <span>{t("StudentDash.panelheading5")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/Enquirys"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/Enquirys"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-chat-right-quote-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading6")}</span>
              </NavLink>
              {/* New Wallet NavLink */}
              <NavLink
                to="/Student-dashboard/Wallet"
                className={`Admin-Dashboard_router_col_ ${
                  location.pathname === "/Student-dashboard/Wallet"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-wallet2"></i>
                </span>
                <span>{t("StudentDash.panelheading8")}</span>
              </NavLink>
              <NavLink
                onClick={logoutHandler}
                className={`Admin-Dashboard_router_col_ inactive `}
              >
                <span>
                  <i className="bi bi-box-arrow-right"></i>
                </span>
                <span>{t("StudentDash.panelheading7")}</span>
              </NavLink>
              <NavLink
                className="Admin-Dashboard_router_col_2"
                onClick={menuHandler}
              >
                <span>
                  <i className="bi bi-list"></i>
                </span>
                <span>Menu</span>
              </NavLink>
            </div>
          ) : (
            <div className="Admin-Dashboard_main_left_router_div_2">
              <NavLink
                to="/Student-dashboard/dash"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/dash"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("StudentDash.panelheading1")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/profile"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/profile"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-person-fill-gear"></i>
                </span>
                <span>{t("StudentDash.panelheading2")}</span>
              </NavLink>
              {/* <NavLink
                to="/Student-dashboard/Packages"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/Packages"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-basket3-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading3")}</span>
              </NavLink> */}
              <NavLink
                to="/Student-dashboard/Bookings"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/Bookings"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-bookmark-dash-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading4")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/Payments"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/Payments"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-currency-dollar"></i>
                </span>
                <span>{t("StudentDash.panelheading5")}</span>
              </NavLink>
              <NavLink
                to="/Student-dashboard/Enquirys"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/Enquirys"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-chat-right-quote-fill"></i>
                </span>
                <span>{t("StudentDash.panelheading6")}</span>
              </NavLink>
              {/* New Wallet NavLink for collapsed menu */}
              <NavLink
                to="/Student-dashboard/Wallet"
                className={`Admin-Dashboard_router_col_3 ${
                  location.pathname === "/Student-dashboard/Wallet"
                    ? "active"
                    : "inactive"
                }`}
              >
                <span>
                  <i className="bi bi-wallet2"></i>
                </span>
                <span>{t("StudentDash.panelheading8")}</span>
              </NavLink>
              <NavLink
                onClick={logoutHandler}
                className={`Admin-Dashboard_router_col_3 inactive `}
              >
                <span>
                  <i className="bi bi-box-arrow-right"></i>
                </span>
                <span>{t("StudentDash.panelheading7")}</span>
              </NavLink>
              <NavLink
                className="Admin-Dashboard_router_col_2"
                onClick={closeMenuHandler}
              >
                <span>
                  <i className="bi bi-x-square"></i>
                </span>
                <span>{t("StudentDash.Close")}</span>
              </NavLink>
            </div>
          )}
        </div>
        <div className="Admin-Dashboard_main_right_div">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;