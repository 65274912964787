import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetch5courses } from '../../store/actions/coursesActions';
import { useTranslation } from 'react-i18next';

const CourseCard = ({ course }) => {
  const { t } = useTranslation("global");
  const [showFullDescription, setShowFullDescription] = useState(false);

  const truncateDescription = (text, sentences) => {
    const sentenceRegex = /[^\.!\?]+[\.!\?]+/g;
    const allSentences = text.match(sentenceRegex);
    if (allSentences && allSentences.length > sentences) {
      return allSentences.slice(0, sentences).join(' ');
    }
    return text;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncatedDescription = truncateDescription(course.Description, 4);

  return (
    <div className="course-card">
      <div className="course-card-inner">
        <div className="course-card-front">
          <div className="card-content">
            <div className="image-container">
            <img 
  src={course.Course_Images ? `https://ik.imagekit.io/8s3jwexmv/${course.Course_Images}` : "/api/placeholder/400/250"} 
  alt={course.Course_Name || t("Courses.defaultImageAlt")} 
  className="course-image"
  loading="lazy"  // Add lazy loading
/>
            </div>
            <h3 className="course-name">{course.Course_Name}</h3>
          </div>
        </div>
        <div className="course-card-back">
          <div className="card-content">
            <p className="course-description">
              {showFullDescription ? course.Description : truncatedDescription}
            </p>
            {course.Description !== truncatedDescription && (
              <button onClick={toggleDescription} className="read-more-btn">
                {showFullDescription ? t("Courses.readLess") : t("Courses.readMore")}
              </button>
            )}
            <Link to={`/CourseDetails/${course._id}`} className="learn-more-btn">
              {t("Courses.learnMore")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Courses = () => {
  const { t } = useTranslation("global");
  const courses = useSelector((state) => state.courses.courseslist);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetch5courses());
  }, [dispatch]);

  return (
    <div className="courses-container">
      <h2 className="courses-title">{t("Courses.title")}</h2>
      <div className="courses-grid">
        {courses?.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
      <style jsx>{`
        .courses-container {
          padding: 40px 20px;
          max-width: 1200px;
          margin: 0 auto;
          border-radius: 20px;
        }

        .courses-title {
          font-size: 24px;
          color: #107314;
          text-align: center;
          margin-bottom: 30px;
          font-weight: 600;
        }

        .courses-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 30px;
          justify-items: center;
        }

        .course-card {
          width: 300px;
          height: 400px;
          perspective: 1000px;
        }

        .course-card-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.6s;
          transform-style: preserve-3d;
        }

        .course-card:hover .course-card-inner {
          transform: rotateY(180deg);
        }

        .course-card-front, .course-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        }

        .course-card-front {
          background-color: #fff;
        }

        .course-card-back {
          background-color: #fff;
          color: #333;
          transform: rotateY(180deg);
        }

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding: 20px;
        }

        .image-container {
          width: 100%;
          height: 200px;
          overflow: hidden;
          border-radius: 8px;
        }

        .course-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .course-name {
          margin: 15px 0;
          font-size: 18px;
          color: #107314;
          font-weight: 600;
        }

        .course-description {
          font-size: 14px;
          color: #333;
          margin-bottom: 20px;
          max-height: 200px;
          overflow-y: auto;
        }

        .read-more-btn {
          background: none;
          border: none;
          color: #107314;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
          text-decoration: underline;
        }

        .read-more-btn:hover {
          color: #0a5a0e;
        }

        .learn-more-btn {
          background-color: #107314;
          color: white;
          padding: 12px 20px;
          border-radius: 8px;
          text-decoration: none;
          transition: all 0.3s ease;
          font-size: 16px;
          font-weight: 600;
          display: inline-block;
        }

        .learn-more-btn:hover {
          background-color: #0a5a0e;
        }
      `}</style>
    </div>
  );
};

export default Courses;
