import React, { useEffect, useState } from 'react';
import { Modal, Button, Upload, List, message, Checkbox } from 'antd';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { uploadToLocal, listLocalFiles, deleteLocalFile, downloadMultipleFiles } from '../../store/actions/teachersActions';
import moment from 'moment';

const YourMaterialModal = ({ isVisible, onClose, bookingId }) => {
  const dispatch = useDispatch();
  const { localFiles, fileLoading, fileError, downloadLoading, downloadError } = useSelector(state => state.teachers || {});
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (isVisible && bookingId) {
      dispatch(listLocalFiles(bookingId));
    }
  }, [isVisible, bookingId, dispatch]);

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append('bookingId', bookingId);
    formData.append('files', file);

    try {
      await dispatch(uploadToLocal({ formData, bookingId }));
      message.success(`${file.name} uploaded successfully`);
      if (onSuccess) onSuccess();
      dispatch(listLocalFiles(bookingId));
    } catch (error) {
      console.error('File upload failed:', error);
      message.error(`File upload failed: ${error.message || 'Unknown error'}`);
      if (onError) onError(error);
    }
  };

  const handleDelete = async (fileId, fileName) => {
    try {
      await dispatch(deleteLocalFile({ bookingId, fileName }));
      message.success(`File deleted successfully`);
      dispatch(listLocalFiles(bookingId));
    } catch (error) {
      console.error('File deletion failed:', error);
      message.error(`File deletion failed: ${error.message || 'Unknown error'}`);
    }
  };

  const handleDownloadMultiple = async () => {
    if (selectedFiles.length === 0) {
      message.warning('Please select files to download');
      return;
    }
  
    try {
      await dispatch(downloadMultipleFiles({ bookingId, fileNames: selectedFiles })).unwrap();
      message.success('Files downloaded successfully');
    } catch (error) {
      console.error('File download failed:', error);
      message.error(`File download failed: ${error || 'Unknown error'}`);
    }
  };

  const handleSelectFile = (fileName, checked) => {
    if (checked) {
      setSelectedFiles(prev => [...prev, fileName]);
    } else {
      setSelectedFiles(prev => prev.filter(f => f !== fileName));
    }
  };

  const renderFileItem = (file) => {
    if (!file) return null;

    return (
      <List.Item
        key={file._id}
        actions={[
          <Checkbox
            key="select"
            onChange={(e) => handleSelectFile(file.name, e.target.checked)}
            checked={selectedFiles.includes(file.name)}
          >
            Select
          </Checkbox>,
          <Button 
            key="delete" 
            type="text" 
            icon={<DeleteOutlined />} 
            onClick={() => handleDelete(file._id, file.name)}
          >
            Delete
          </Button>,
        ]}
      >
        <List.Item.Meta 
          title={file.originalName || 'Unnamed File'}
          description={file.createdAt ? moment(file.createdAt).format('MMMM D, YYYY, h:mm A') : 'Date not available'}
        />
      </List.Item>
    );
  };

  return (
    <Modal
      title="Your Material"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="download" onClick={handleDownloadMultiple} disabled={selectedFiles.length === 0}>
          Download Selected
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Upload
        name="files"
        multiple
        customRequest={handleUpload}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Upload Files</Button>
      </Upload>

      {fileError && <p style={{ color: 'red' }}>Error: {fileError.message || JSON.stringify(fileError)}</p>}
      {downloadError && <p style={{ color: 'red' }}>Download Error: {downloadError.message || JSON.stringify(downloadError)}</p>}

      {fileLoading || downloadLoading ? (
        <p>Loading...</p>
      ) : Array.isArray(localFiles) && localFiles.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={localFiles.filter(file => file !== null && file !== undefined)}
          renderItem={renderFileItem}
        />
      ) : (
        <p>No files available. Please upload files.</p>
      )}
    </Modal>
  );
};

export default YourMaterialModal;