import React, { useEffect, useState } from "react";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackage } from "../../store/actions/packagesActions";
import { Create_Payment, storePaymentDetails } from "../../store/actions/paymentActions";
import { Country, State, City } from "country-state-city";
import { fetchWalletBalance, purchaseHours } from "../../store/actions/walletActions";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Loader2 from "../Loader2"; 

const CheckDetails = () => {
  const { Package_ID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { packageName, hours, totalAmount } = location.state || {};
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [nip, setNip] = useState("");
  const [street, setStreet] = useState("");
  const [t] = useTranslation("global");
  const user = useSelector((state) => state.students.user);
  const pack = useSelector((state) => state.packages.currentPackage);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const paymentResponseUrl = useSelector(
    (state) => state.payments.paymentResponseUrl
  );

  const PackageName = pack?.Package_Name;
  const Email = user?.Email;
  const Phone = user?.Phone_Number;
  const StudentName = user?.Username;

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true);
      try {
        await dispatch(fetchPackage(Package_ID));
      } catch (error) {
        console.error("Error fetching package:", error);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchData();
  }, [Package_ID, dispatch]);

  useEffect(() => {
    if (paymentResponseUrl.length > 0) {
      setLoading(false);
      window.location.href = paymentResponseUrl;
    }
  }, [paymentResponseUrl]);

  const openPdf = (url) => {
    window.open(url, '_blank');
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = {
        Package_ID,
        Desciption: PackageName,
        totalAmount: totalAmount,
        Email,
        Phone,
        StudentName,
        Country: selectedCountry?.name,
        State: selectedState?.name,
        City: selectedCity?.name,
        Street: street,
        CompanyName: companyName,
        NIP: nip,
        hours: hours
      };
      const paymentResult = await dispatch(Create_Payment(formData));
      
      if (Create_Payment.fulfilled.match(paymentResult)) {
        const { orderId, paymentResponseUrl } = paymentResult.payload;
        
        // Store payment details
        const paymentData = {
          Student_ID: user._id,
          Package_ID: Package_ID,
          Purchase_Amount: totalAmount,
          Sessions: hours,
          Status: 'Pending',
          OrderID: orderId,
          invoice: {
            orderId: orderId,
            packageName: PackageName,
            studentName: StudentName,
            studentEmail: Email
          },
          orderFulfilled: false
        };
        
        const storePaymentResult = await dispatch(storePaymentDetails(paymentData));
        
        if (storePaymentDetails.fulfilled.match(storePaymentResult)) {
          
          localStorage.setItem(
            "pendingPurchase",
            JSON.stringify({
              studentId: user._id,
              packageId: Package_ID,
              hours: hours,
              amount: totalAmount,
              packageName: PackageName,
            })
          );

          // Redirect to the payment gateway
          window.location.href = paymentResponseUrl;
        } else {
          console.error("Failed to store payment details:", storePaymentResult.error);
        }
      } else {
        console.error("Payment initiation failed:", paymentResult.error);
      }
    } catch (error) {
      console.error("Error during payment process:", error.message);
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return (
      <Loader2 
        loading={true}
        text="Loading Package Details"
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }

  return (
    <>
      <AdminNav />
      <form className="Student_mainPage_style" onSubmit={submitHandler}>
        <h2>{t("CheckDetails.OrderDetails")}</h2>
        <div className="d-flex flex-wrap confirm-Box">
          <div className="PersonalInfo">
            <h5>{t("CheckDetails.UserInfo")}</h5>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.Username")}</label>
              <input
                type="text"
                className="form-control"
                value={StudentName}
                required
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.Email")}</label>
              <input
                type="text"
                className="form-control"
                value={Email}
                required
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.PhoneNo")}.</label>
              <input
                type="text"
                className="form-control"
                value={Phone}
                required
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.NameofCompany")}</label>
              <input
                type="text"
                className="form-control"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.NIP")}</label>
              <input
                type="text"
                className="form-control"
                value={nip}
                onChange={(e) => setNip(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.Country")}</label>
              <Select
                options={Country.getAllCountries()}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["name"]}
                value={selectedCountry}
                onChange={(item) => setSelectedCountry(item)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.State")}</label>
              <Select
                options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["name"]}
                value={selectedState}
                onChange={(item) => setSelectedState(item)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.City")}</label>
              <Select
                options={City.getCitiesOfState(
                  selectedState?.countryCode,
                  selectedState?.isoCode
                )}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["name"]}
                value={selectedCity}
                onChange={(item) => setSelectedCity(item)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">{t("CheckDetails.Street")}</label>
              <input
                type="text"
                className="form-control"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="OrderInfo">
          <h5>{t("CheckDetails.YourOrder")}</h5>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">{t("CheckDetails.PackageName")}</label>
            <input
              type="text"
              className="form-control"
              value={packageName}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">{t("CheckDetails.HoursToPurchase")}</label>
            <input
              type="text"
              className="form-control"
              value={hours}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">{t("CheckDetails.AmountPayable")}</label>
            <input
              type="text"
              className="form-control"
              value={totalAmount}
              disabled
            />
          </div>
        </div>
        </div>

        <div className="custom-control custom-checkbox">
  <input
    type="checkbox"
    className="custom-control-input55"
    id="customCheck1"
    required
  />
  <label className="custom-control-label" htmlFor="customCheck1">
    {t("CheckDetails.IReadandacceptRules")}
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        openPdf('https://speakable.online/api/pdf/rulesandcondition.pdf');
      }}
      style={{ marginLeft: '5px' }}
    >
      rules
    </a>
  </label>
</div>



<div className="custom-control custom-checkbox">
  <input
    type="checkbox"
    className="custom-control-input"
    id="customCheck2"
    required
  />
  <label className="custom-control-label" htmlFor="customCheck2">
    {t("CheckDetails.Iread")}
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        openPdf('https://speakable.online/api/pdf/privacy.pdf');
      }}
      style={{ marginLeft: '5px' }}
    >
     privacy policy
    </a>
  </label>
</div>



        <button
          type="submit"
          className={`btn btn-outline-success ${loading ? "loading" : ""}`}
          disabled={loading}
        >
          {loading ? t("CheckDetails.Confirming") : t("CheckDetails.Confirm")}
        </button>
      </form>

      {loading && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}
        >
          <Loader2 
            loading={true}
            text="Processing Payment"
            size={300}
            customStyles={{
              backgroundColor: 'transparent'
            }}
          />
        </div>
      )}
    </>
  );
};

export default CheckDetails;