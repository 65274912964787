import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllpayments,
  Add_payment,
  Updatepayment,
  Deletepayment,
  GetPaymentsByStudentID,
  Create_Payment,
  getOrderDetails,
  SearchPaymentbyStudentUsername,
  storePaymentDetails,
  fetchPaymentDetails,
} from "../actions/paymentActions";
import { toast } from "react-toastify";

let initialState = {
  loading: false,
  paymentlist: [],
  Allpaymentlist: [],
  StudentID_Payments: [],
  paymentResponseUrl: "",
  orderId: "",
  orderStatus: "",
  storedPaymentDetails: null,
  fetchedPaymentDetails: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllpayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllpayments.fulfilled, (state, action) => {
        state.paymentlist = action.payload;
        state.Allpaymentlist = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllpayments.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Add_payment.fulfilled, (state, action) => {
        state.Allpaymentlist.push(action.payload);
        state.loading = false;
      })
      .addCase(Add_payment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Deletepayment.fulfilled, (state, action) => {
        state.loading = false;
        state.Allpaymentlist = state.Allpaymentlist.filter(
          (Payment) => Payment._id !== action.payload._id
        );
      })
      .addCase(Deletepayment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Updatepayment.fulfilled, (state, action) => {
        const { PaymentID, updatedData } = action.payload;
        const updatedPaymentIndex = state.Allpaymentlist.findIndex(
          (Payment) => Payment._id === PaymentID
        );

        if (updatedPaymentIndex !== -1) {
          const updatedPayment = {
            ...state.Allpaymentlist[updatedPaymentIndex],
            ...updatedData,
          };
          state.Allpaymentlist = [
            ...state.Allpaymentlist.slice(0, updatedPaymentIndex),
            updatedPayment,
            ...state.Allpaymentlist.slice(updatedPaymentIndex + 1),
          ];
        }
      })
      .addCase(Updatepayment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetPaymentsByStudentID.fulfilled, (state, action) => {
        state.loading = false;
        state.StudentID_Payments = action.payload;
      })
      .addCase(GetPaymentsByStudentID.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Create_Payment.pending, (state) => {
        state.loading = true;
      })
      .addCase(Create_Payment.fulfilled, (state, action) => {
        state.paymentResponseUrl = action.payload.paymentResponseUrl;
        state.orderId = action.payload.orderId;
        state.loading = false;
      })
      .addCase(Create_Payment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.orderStatus = action.payload.status;
        state.loading = false;
      })
      .addCase(getOrderDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(SearchPaymentbyStudentUsername.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchPaymentbyStudentUsername.fulfilled, (state, action) => {
        if (action.payload && action.payload.foundedPayment) {
          state.Allpaymentlist = action.payload.foundedPayment;
        } else {
          state.Allpaymentlist = [];
        }
        state.loading = false;
      })
      .addCase(SearchPaymentbyStudentUsername.rejected, (state) => {
        state.loading = false;
      })
      .addCase(storePaymentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(storePaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.storedPaymentDetails = action.payload.paymentDetails;
      })
      .addCase(storePaymentDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchPaymentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchedPaymentDetails = action.payload.paymentDetails;
      })
      .addCase(fetchPaymentDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentSlice.reducer;