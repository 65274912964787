import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePhoneNumber } from '../../store/actions/studentsActions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Modal = ({ isOpen, onClose, onConfirm, message }) => {
  const { t } = useTranslation("global");
  if (!isOpen) return null;

  return (
    <div className="language-modal-overlay">
      <div className="language-modal-content">
        <h5>{t("LanguageModal.title")}</h5>
        <p>{message}</p>
        <div className="language-modal-buttons">
          <button onClick={onClose} className="btn-cancel">
            {t("common.Cancel")}
          </button>
          <button onClick={onConfirm} className="btn-confirm">
            {t("common.Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const student = useSelector((state) => state.students.user);
  const { t, i18n } = useTranslation("global");
  const [currentLanguage, setCurrentLanguage] = useState(t("common.Language"));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingLanguage, setPendingLanguage] = useState({ code: '', name: '' });
  const [showPhoneUpdate, setShowPhoneUpdate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(student?.Phone_Number || '');
  const [phoneError, setPhoneError] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [savedOtp, setSavedOtp] = useState("");
  const { phoneUpdateLoading, phoneUpdateError } = useSelector(state => state.students);

  const handleEditStudentProfile = useCallback((id) => {
    navigate(`/Student-dashboard/profile/edit-studentProfile/${id}`);
  }, [navigate]);

  const handleChangeLanguage = useCallback((lang, langName) => {
    setPendingLanguage({ code: lang, name: langName });
    setShowConfirmation(true);
  }, []);

  const confirmLanguageChange = useCallback(() => {
    setCurrentLanguage(pendingLanguage.name);
    i18n.changeLanguage(pendingLanguage.code);
    setShowConfirmation(false);
  }, [pendingLanguage, i18n]);

  const cancelLanguageChange = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const handleSendOtp = useCallback(async () => {
    try {
      const response = await fetch("https://speakable.online/api/sendOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ number: phoneNumber }),
      });
      const data = await response.json();
      if (response.ok) {
        setIsOtpSent(true);
        setSavedOtp(data.otp.toString());
        console.log(t("OTP.sentSuccess"), data.otp);
      } else {
        console.error(t("OTP.sendFailed"));
        setPhoneError(t("OTP.sendFailedTryAgain"));
      }
    } catch (error) {
      console.error(t("OTP.sendError"), error);
      setPhoneError(t("OTP.sendErrorTryAgain"));
    }
  }, [phoneNumber, t]);

  const handleVerifyOtp = useCallback(() => {
    if (otp === savedOtp) {
      setVerifiedOtp(true);
      console.log(t("OTP.verifySuccess"));
    } else {
      console.error(t("OTP.verifyFailed"));
      setPhoneError(t("OTP.invalidTryAgain"));
    }
  }, [otp, savedOtp, t]);

  const handleUpdatePhoneNumber = useCallback(async () => {
    if (!verifiedOtp) {
      setPhoneError(t("OTP.verifyBeforeUpdate"));
      return;
    }
    try {
      await dispatch(updatePhoneNumber({ userId: student._id, Phone_Number: phoneNumber })).unwrap();
      setShowPhoneUpdate(false);
      setIsOtpSent(false);
      setVerifiedOtp(false);
    } catch (error) {
      if (error.status === 409) {
        setPhoneError(t("Phone.alreadyInUse"));
      } else {
        setPhoneError(error.message || t("Phone.updateFailed"));
      }
    }
  }, [verifiedOtp, dispatch, student._id, phoneNumber, t]);

  return (
    <>
      <div className="StudentProfile_mainPage_style">
        <div className="StudentProfile_header_style">
          <div className="Profile_header_imgdiv">
            <img
              src={`https://ik.imagekit.io/8s3jwexmv/${student?.Profile_Image}`}
              alt={t("StudentProfile.ProfileImage")}
            />
          </div>
        </div>

        <div className="row StudentName_style">
          <h1 className="studentName">{student?.Username}</h1>
          <p className="studenttext">{t(`UserType.${student?.UserType}`)}</p>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 ">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-person userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.FirstName")}:</p>
                <p className="userDetailTextData">{student?.firstName}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-geo-alt userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.LastName")}:</p>
                <p className="userDetailTextData">{student?.lastName}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-telephone userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.Phone")}:</p>
                <p className="userDetailTextData">{student?.Phone_Number}</p>
                <button onClick={() => setShowPhoneUpdate(!showPhoneUpdate)} className="btn btn-primary mt-2">
                  {showPhoneUpdate ? t("Phone.cancelUpdate") : t("Phone.update")}
                </button>
                {showPhoneUpdate && (
                  <div className="mt-2">
                    <PhoneInput
                      country={"us"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputProps={{
                        name: "Phone_number",
                        required: true,
                        className: "form-control",
                      }}
                    />
                    {!isOtpSent && (
                      <button onClick={handleSendOtp} className="btn btn-secondary mt-2" disabled={phoneUpdateLoading}>
                        {t("OTP.send")}
                      </button>
                    )}
                    {isOtpSent && !verifiedOtp && (
                      <>
                        <input
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder={t("OTP.enter")}
                          className="form-control mt-2"
                        />
                        <button onClick={handleVerifyOtp} className="btn btn-secondary mt-2" disabled={phoneUpdateLoading}>
                          {t("OTP.verify")}
                        </button>
                      </>
                    )}
                    {verifiedOtp && (
                      <button onClick={handleUpdatePhoneNumber} className="btn btn-success mt-2" disabled={phoneUpdateLoading}>
                        {phoneUpdateLoading ? t("Phone.updating") : t("Phone.confirmUpdate")}
                      </button>
                    )}
                    {(phoneError || phoneUpdateError) && <p className="text-danger mt-2">{phoneError || phoneUpdateError}</p>}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-telephone userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.StudentId")}:</p>
                <p className="userDetailTextData">{student?._id}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-12 ">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-person userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.Email")}:</p>
                <p className="userDetailTextData">{student?.Email}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-geo-alt userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("StudentProfile.Address")}:</p>
                <p className="userDetailTextData">{student?.Address}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-clock userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>{t("common.Language")}:</p>
                <div className="dropdown">
                  <button
                    className="btn btn-outline-success dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i className="bi bi-globe-central-south-asia mx-2"></i>
                    {currentLanguage}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleChangeLanguage("en", t("languages.English"))}
                      >
                        <img
                          style={{
                            border: "0.01px solid rgb(8 8 8 / 46%)",
                            marginRight: "15px",
                          }}
                          width={25}
                          height={15}
                          src={require("../../assets/English.webp")}
                          alt="English"
                        />{" "}
                        {t("languages.English")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleChangeLanguage("pl", t("languages.Polish"))}
                      >
                        <img
                          style={{
                            border: "0.01px solid rgb(8 8 8 / 46%)",
                            marginRight: "15px",
                          }}
                          width={25}
                          height={15}
                          src={require("../../assets/Polish.png")}
                          alt="polish"
                        />{" "}
                        {t("languages.Polish")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-h-square-fill userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>{t("Profile.selectTimezone")}:</p>
                <select value={student?.Timezone} disabled>
                  {moment.tz.names().map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <button
          className="btn btn-outline-success m-4"
          onClick={() => handleEditStudentProfile(student._id)}
        >
          {t("StudentProfile.Edit")}
        </button>
      </div>

      <Modal
        isOpen={showConfirmation}
        onClose={cancelLanguageChange}
        onConfirm={confirmLanguageChange}
        message={t("LanguageModal.confirmationMessage", { language: pendingLanguage.name })}
      />
    </>
  );
};

export default Profile;