import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { 
  fetchWalletBalance, 
  fetchWalletTransactions,
  purchaseHours
} from "../../store/actions/walletActions";
import { fetchAllpackages, addFreeHour, getStudentFreeHourUsage } from "../../store/actions/packagesActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { useTranslation } from "react-i18next";
import { FaSearch, FaClock, FaBookOpen, FaChalkboardTeacher } from 'react-icons/fa';
import ChatButton from "./ChatButton";
import { toast } from 'react-toastify';
import PurchasePopup from "../student-dashboard-components/purchasePopUp";
import { useCurrencyConversion } from "../currencyConversionUtil";
import Loader2 from "../Loader2";
import Modal from 'react-modal';

const WalletStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const student = useSelector((state) => state.students.user);
  const wallet = useSelector((state) => state.wallet);
  const packages = useSelector((state) => state.packages.packageslist);
  const studentFreeHourUsage = useSelector((state) => state.packages.studentFreeHourUsage);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const { convertPrice, loading: currencyLoading } = useCurrencyConversion(student?.Timezone || 'Europe/Warsaw');
  const [showModal, setShowModal] = useState(false);
  const [showFreePackageModal, setShowFreePackageModal] = useState(false);
  const [showFreeHoursUsedModal, setShowFreeHoursUsedModal] = useState(false);

  const fetchData = useCallback(() => {
    if (student?._id) {
      dispatch(fetchWalletBalance(student._id));
      dispatch(fetchWalletTransactions(student._id));
      dispatch(getStudentFreeHourUsage(student._id));
    }
  }, [dispatch, student]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await dispatch(fetchAllpackages());
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);

      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
  }, [dispatch, fetchData, t]);

  useEffect(() => {
    const intervalId = setInterval(fetchData, 30000); 
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const checkFreeHourUsage = (packageId) => {
    return studentFreeHourUsage.some(usage => 
      usage.packageId === packageId && usage.hourUsed === true
    );
  };

  const filteredPackages = packages?.filter(pack =>
    pack.Package_Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePurchaseClick = (pack) => {
    setSelectedPackage(pack);
    if (pack.Free_Package) {
      if (checkFreeHourUsage(pack._id)) {
        setShowFreeHoursUsedModal(true);
      } else {
        setShowFreePackageModal(true);
      }
    } else {
      setShowPopup(true);
    }
  };

  const calculateDiscountedPrice = (pack, hours) => {
    if (!pack.Package_Amount || !pack.Number_of_Lectures) {
      console.error("Invalid package data:", pack);
      return 0;
    }
    const pricePerHour = pack.Package_Amount / pack.Number_of_Lectures;
    const originalPrice = pricePerHour * hours;
    const discount = pack.Discount || 0;
    // Only apply discount if full package is selected
    if (hours === pack.Number_of_Lectures) {
      return originalPrice - (originalPrice * (discount / 100));
    }
    return originalPrice;
  };

  const handlePurchase = (packageId, hours, paymentType, selectedHours) => {
    const price = calculateDiscountedPrice(selectedPackage, selectedHours);
    navigate(`/Student-dashboard/CheckDetails/${packageId}`, {
      state: {
        packageName: selectedPackage.Package_Name,
        hours: selectedHours,
        totalAmount: convertPrice(price)
      }
    });
    setShowPopup(false);
  };

  const handleFreePackagePurchase = async () => {
    try {
      setIsLoading(true);
      const result = await dispatch(purchaseHours({
        studentId: student._id,
        packageId: selectedPackage._id,
        hours: selectedPackage.Number_of_Lectures,
        amount: 0
      }));
      
      if (purchaseHours.fulfilled.match(result)) {
        await dispatch(addFreeHour({
          StudentID: student._id,
          PackageID: selectedPackage._id,
          Free_hour_used: true
        }));
        
        await dispatch(fetchWalletBalance(student._id));
        await dispatch(getStudentFreeHourUsage(student._id));
        toast.success(t("FreePackageAdded"));
      } else {
      }
    } catch (error) {
      console.error("Error adding free package:", error);
    } finally {
      setIsLoading(false);
      setShowFreePackageModal(false);
    }
  };

  const handleBookNow = (packId) => {
    const packageHours = wallet.packageBalances[packId]?.hours || 0;
    if (packageHours > 0) {
      navigate(`/Student-dashboard/EditPackageTeacher/${packId}`);
    } else {
      setSelectedPackage(packages.find(p => p._id === packId));
      setShowModal(true);
    }
  };

  if (isLoading || currencyLoading) {
    return <Loader2 loading={true} text="Loading" fullScreen={true} size={300} customStyles={{ backgroundColor: 'rgba(15, 15, 15, 0.5)' }} />;
  }

  return (
    <div className="Admin-Dash_main_div">
      <AdminNav />
      <div className="Admin-Dash_content_box wallet-container">
        <div className="Admin-Dash_header_style">
          <h1 className="packages-title">{t("StudentWallet.WalletAndPackages")}</h1>
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input
              type="text"
              className="packages-search"
              placeholder={t("StudentWallet.SearchPackages")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="packages-grid">
          {filteredPackages?.map((pack) => (
            <div className="package-card admin-card" key={pack._id}>
              <h2 className="package-name">{pack.Package_Name}</h2>
              <div className="package-details">
                <div className="stat-box">
                  <FaClock className="icon" />
                  <span className="hours-available">
                    <strong>{wallet.packageBalances[pack._id]?.hours || 0}</strong>
                    <span className="hours-label">/{pack.Number_of_Lectures} {t("StudentWallet.Hours")}</span>
                  </span>
                </div>
                <div className="stat-box">
                  <FaChalkboardTeacher className="icon" />
                  <span>{pack.Number_of_Lectures} {t("StudentPackage.Lectures")}</span>
                </div>
                <div className="stat-box">
                  <FaBookOpen className="icon" />
                  <span>{pack.Course_IDs?.length} {t("StudentPackage.Courses")}</span>
                </div>
              </div>
              <div className="package-price">
  {pack.Free_Package ? (
    <span className="free-package">{t("StudentWallet.FreePackage")}</span>
  ) : (
    <>
      <span className="discounted-price">
        {convertPrice(calculateDiscountedPrice(pack, pack.Number_of_Lectures))}
      </span>
      {pack.Discount > 0 && (
        <>
          <span className="original-price">{convertPrice(pack.Package_Amount)}</span>
          <span className="discount-percentage">
            {pack.Discount}% {t("StudentWallet.Off")} ({t("StudentWallet.FullPackageOnly")})
          </span>
        </>
      )}
    </>
  )}
</div>
              <div className="course-info">
                {pack.Course_IDs && pack.Course_IDs.length > 0 && pack.Course_IDs[0] && (
                  <div>
                    <h3 className="course-name">{pack.Course_IDs[0].Course_Name}</h3>
                    <p className="course-description">{pack.Course_IDs[0].Description?.substring(0, 100)}...</p>
                  </div>
                )}
              </div>
              <div className="teachers-section">
                <h4>{t("StudentPackage.TeachersAssigned")}</h4>
                <div className="teachers-list">
                  {pack.Teacher_IDs?.map((teacher) => (
                    <span key={teacher._id} className="teacher-name">{teacher.Username}</span>
                  ))}
                </div>
              </div>
              <div className="package-actions">
                <button 
                  onClick={() => handlePurchaseClick(pack)}
                  className="book-now-button"
                >
                  {pack.Free_Package ? t("StudentWallet.GetFreeHours") : t("StudentWallet.PurchaseHours")}
                </button>
                <button 
                  onClick={() => handleBookNow(pack._id)}
                  className="book-now-button"
                >
                  {t("StudentPackage.BookNow")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showPopup && (
        <PurchasePopup
          pack={selectedPackage}
          onClose={() => setShowPopup(false)}
          onPurchase={handlePurchase}
          availableHours={wallet.packageBalances[selectedPackage._id]?.hours || 0}
          convertPrice={convertPrice}
          calculateDiscountedPrice={calculateDiscountedPrice}
        />
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="No Hours Available"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-content">
          <h2 className="modal-title">{t("StudentPackage.NoHoursAvailable")}</h2>
          <p className="modal-message">{t("StudentPackage.PurchaseHoursMessage")}</p>
          <div className="modal-buttons">
            <button 
              className="modal-button go-to-wallet"
              onClick={() => {
                setShowModal(false);
                handlePurchaseClick(selectedPackage);
              }}
            >
              {t("StudentPackage.PurchaseHours")}
            </button>
            <button 
              className="modal-button close"
              onClick={() => setShowModal(false)}
            >
              {t("StudentPackage.Close")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showFreePackageModal}
        onRequestClose={() => setShowFreePackageModal(false)}
        contentLabel="Free Package Confirmation"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-content">
          <h2 className="modal-title">{t("StudentWallet.FreePackageConfirmation")}</h2>
          <p className="modal-message">{t("StudentWallet.AddFreeHoursMessage", { hours: selectedPackage?.Number_of_Lectures })}</p>
          <div className="modal-buttons">
            <button 
              className="modal-button confirm"
              onClick={handleFreePackagePurchase}
            >
              {t("StudentWallet.ConfirmAddFreeHours")}
            </button>
            <button 
              className="modal-button close"
              onClick={() => setShowFreePackageModal(false)}
            >
              {t("StudentPackage.Cancel")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showFreeHoursUsedModal}
        onRequestClose={() => setShowFreeHoursUsedModal(false)}
        contentLabel="Free Hours Already Used"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-content">
          <h2 className="modal-title">{t("StudentWallet.FreeHoursUsed")}</h2>
          <p className="modal-message">{t("StudentWallet.FreeHoursUsedMessage")}</p>
          <div className="modal-buttons">
            <button 
              className="modal-button close"
              onClick={() => setShowFreeHoursUsedModal(false)}
            >
              {t("StudentPackage.Close")}
            </button>
          </div>
        </div>
      </Modal>
      <ChatButton />
    </div>
  );
};

export default WalletStudent;