import React, { useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const StudentInvoice = () => {
  const location = useLocation();
  const paymentData = location.state;
  const { id } = useParams();
  const invoiceRef = useRef(null);

  const downloadInvoice = () => {
    if (invoiceRef.current) {
      html2canvas(invoiceRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save("invoice.pdf");
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="invoice-container">

    <div className="invoice-container" ref={invoiceRef}>
      <div className="invoice-header">
        <div>Issued on {formatDate(paymentData?.createdAt)}, Białystok</div>
        <div>
          <div className="invoice-number">
            Invoice No. {formatDate(paymentData?.createdAt)}
          </div>
          <div>Sale date: {formatDate(paymentData?.createdAt)}</div>
          <div>Method of payment: {paymentData?.Method}</div>
          <div>Date of payment: {formatDate(paymentData?.createdAt)}</div>
        </div>
      </div>
      </div>
      <div className="invoice-details">
        <div>
          <div className="invoice-section-title">Seller:</div>
          <div>
            Speakable English Francis Prominski FRANCIS PROMINSKI
            <br />
            Aleja Jana Pawła II 61C/102
            <br />
            15-704 Białystok
            <br />
            NIP 5423343721
          </div>
        </div>
        <div>
          <div className="invoice-section-title">Buyer:</div>
          <div>
            {paymentData?.Student_ID?.Username}
            <br />
            {paymentData?.Student_ID?.Email}
            <br />
            {paymentData?.Student_ID?.Address}
          </div>
        </div>
      </div>
      <div className="invoice-section">
        <div className="invoice-section-title">INVOICE ITEMS</div>
        <table className="invoice-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Product/Service</th>
              <th>Base legal</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{paymentData?.Package_ID?.Package_Name}</td>
              <td>Amount</td>
              <td>1</td>
              <td>{paymentData?.Purchase_Amount}</td>
              <td>{paymentData?.Purchase_Amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="invoice-summary">
        <div className="invoice-section-title">SUMMARY</div>
        <table>
          <tbody>
            <tr>
              <td>Gross value</td>
              <td>{paymentData?.Package_ID?.Package_Name}</td>
            </tr>
            <tr>
              <td className="bold-text">Together:</td>
              <td>{paymentData?.Purchase_Amount}</td>
            </tr>
            <tr>
              <td className="bold-text">Paid:</td>
              <td>{paymentData?.Purchase_Amount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="footer-text">
        Invoice without the recipient's signature
        <br />
        The person authorized to issue an invoice
        <br />
        <span className="bold-text">FRANCIS PROMINSKI</span>
      </div>
      <div className="download-button">
        <button onClick={downloadInvoice}>Download Invoice</button>
      </div>
    </div>
  );
};

export default StudentInvoice;
