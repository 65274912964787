import React, { useState, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { Modal, Button } from 'antd';

const styles = {
  calendar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  monthYear: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#666',
  },
  weekdays: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  weekday: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#666',
  },
  days: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
  },
  day: {
    width: '100%',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background-color 0.2s',
  },
  slotContainer: {
    marginTop: '16px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  slot: {
    padding: '8px',
    margin: '4px 0',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  statusIndicator: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '4px',
  },
  dstIndicator: {
    fontSize: '12px',
    color: '#666',
    marginTop: '4px',
    padding: '4px',
    backgroundColor: '#fff3e0',
    borderRadius: '4px',
    textAlign: 'center',
  },
  dstInfoContainer: {
    marginTop: '10px',
    marginBottom: '10px',
    padding: '8px',
    backgroundColor: '#fff3e0',
    borderRadius: '4px',
    fontSize: '12px',
  },
  dstYearInfo: {
    marginBottom: '4px',
    padding: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '4px',
  },
  currentDstHighlight: {
    backgroundColor: '#ffe0b2',
    fontWeight: 'bold',
  }
};

const statusColors = {
  Scheduled: '#5C7153',
  Cancelled: '#F44336',
  Rescheduled: '#FFC107',
  Completed: '#2196F3',
  Available: '#4CAF50',
  OtherStudentBooking: '#FF9800',
};

// Function to get DST transitions for multiple years
const getDSTTransitions = (timezone, startYear, numYears) => {
  const transitions = [];
  
  for (let year = startYear; year < startYear + numYears; year++) {
    // Find start of DST (March)
    const marchDate = moment.tz(`${year}-03-31 01:00`, timezone);
    while (marchDate.format('d') !== '0') { // Go backward to last Sunday
      marchDate.subtract(1, 'day');
    }
    
    // Find end of DST (October)
    const octoberDate = moment.tz(`${year}-10-31 01:00`, timezone);
    while (octoberDate.format('d') !== '0') { // Go backward to last Sunday
      octoberDate.subtract(1, 'day');
    }

    transitions.push({
      year,
      start: {
        date: marchDate.format('MMMM D, YYYY'),
        time: '02:00 CET → 03:00 CEST'
      },
      end: {
        date: octoberDate.format('MMMM D, YYYY'),
        time: '03:00 CEST → 02:00 CET'
      }
    });
  }
  
  return transitions;
};
const MobileCalendar = ({
  selectedTeacherId,
  availability,
  selectedSlots,
  handleSlotToggle,
  selectedTimezone,
  freeHours,
  usedHours,
  t
}) => {
  const [currentDate, setCurrentDate] = useState(moment().tz(selectedTimezone));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDateSlots, setSelectedDateSlots] = useState([]);

  // Get DST transitions for next 5 years
  const dstTransitions = useMemo(() => {
    const currentYear = moment().year();
    return getDSTTransitions(selectedTimezone, currentYear, 5);
  }, [selectedTimezone]);

  useEffect(() => {
    console.log("MobileCalendar props:", {
      selectedTeacherId,
      availability: availability.length,
      selectedSlots: Object.keys(selectedSlots).length,
      selectedTimezone,
      freeHours,
      usedHours
    });
  }, [selectedTeacherId, availability, selectedSlots, selectedTimezone, freeHours, usedHours]);

  const formatSlotTime = useCallback((slot) => {
    const start = moment(slot.start).tz(selectedTimezone);
    const end = moment(slot.end).tz(selectedTimezone);
    const timeFormat = 'HH:mm';
    
    // Check if the slot is during DST transition
    const isDstTransitionDay = dstTransitions.some(transition => {
      return start.format('YYYY-MM-DD') === moment(transition.start.date).format('YYYY-MM-DD') ||
             start.format('YYYY-MM-DD') === moment(transition.end.date).format('YYYY-MM-DD');
    });

    if (isDstTransitionDay) {
      const startSuffix = start.isDST() ? ' CEST' : ' CET';
      const endSuffix = end.isDST() ? ' CEST' : ' CET';
      return {
        date: start.format('MMM D, YYYY'),
        startTime: `${start.format(timeFormat)}${startSuffix}`,
        endTime: `${end.format(timeFormat)}${endSuffix}`,
        isDstChange: true,
        offset: start.format('Z')
      };
    }

    return {
      date: start.format('MMM D, YYYY'),
      startTime: start.format(timeFormat),
      endTime: end.format(timeFormat),
      isDstChange: false,
      offset: start.format('Z')
    };
  }, [selectedTimezone, dstTransitions]);

  const prevMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, 'month').startOf('month'));
  };

  const nextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, 'month').startOf('month'));
  };

  const getSlotsForDate = useCallback((date) => {
    return availability
      .filter(slot => moment(slot.start).tz(selectedTimezone).isSame(date, 'day'))
      .sort((a, b) => moment(a.start).diff(moment(b.start)));
  }, [availability, selectedTimezone]);

  const availableDates = useMemo(() => {
    const dates = new Set();
    availability.forEach(slot => {
      const date = moment(slot.start).tz(selectedTimezone).format('YYYY-MM-DD');
      dates.add(date);
    });
    return dates;
  }, [availability, selectedTimezone]);

  const handleDateClick = (day) => {
    const clickedDate = currentDate.clone().date(day);
    setCurrentDate(clickedDate);
    const slotsForDate = getSlotsForDate(clickedDate);
    setSelectedDateSlots(slotsForDate);
    setIsModalVisible(true);
  };

  const isSlotSelected = useCallback((slot) => {
    return Object.values(selectedSlots).some(
      (selectedSlot) => moment(selectedSlot.start).isSame(slot.start) && 
                       moment(selectedSlot.end).isSame(slot.end)
    );
  }, [selectedSlots]);

  const renderSlot = (slot) => {
    const status = slot.extendedProps.status;
    const isBooked = status !== 'Available';
    const isSelected = isSlotSelected(slot);
    const isFreeHour = isSelected && usedHours < freeHours;
    
    const { date, startTime, endTime, isDstChange, offset } = formatSlotTime(slot);
    
    const slotStyle = {
      ...styles.slot,
      backgroundColor: isSelected ? '#4CAF50' : statusColors[status] || statusColors.Available,
      color: status === 'Available' ? '#000' : '#fff',
      opacity: isFreeHour ? 0.7 : 1,
    };

    return (
      <div key={slot.id}>
        <div
          style={slotStyle}
          onClick={() => !isBooked && handleSlotToggle(slot)}
        >
          <span style={{
            ...styles.statusIndicator,
            backgroundColor: statusColors[status]
          }}></span>
          <span>{`${date} ${startTime} - ${endTime}`}</span>
          <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>{slot.title}</span>
          {isFreeHour && (
            <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>
              {t('MobileCalendar.FreeHour')}
            </span>
          )}
        </div>
        {isDstChange && (
          <div style={styles.dstIndicator}>
            {offset === '+02:00' ? 'CEST (UTC+2)' : 'CET (UTC+1)'}
          </div>
        )}
      </div>
    );
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  // Check if current month is a DST transition month
  const isDstTransitionMonth = useMemo(() => {
    const currentYearMonth = currentDate.format('YYYY-MM');
    return dstTransitions.some(transition => {
      const startMonth = moment(transition.start.date).format('YYYY-MM');
      const endMonth = moment(transition.end.date).format('YYYY-MM');
      return currentYearMonth === startMonth || currentYearMonth === endMonth;
    });
  }, [currentDate, dstTransitions]);

  // Render DST information
  const renderDSTInfo = () => {
    const currentYearMonth = currentDate.format('YYYY-MM');
    
    return (
      <div style={styles.dstInfoContainer}>
        <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
          DST Transitions {dstTransitions[0].year}-{dstTransitions[dstTransitions.length - 1].year}
        </div>
        {dstTransitions.map((transition) => {
          const startMonth = moment(transition.start.date).format('YYYY-MM');
          const endMonth = moment(transition.end.date).format('YYYY-MM');
          const isCurrentTransition = currentYearMonth === startMonth || currentYearMonth === endMonth;
          
          return (
            <div 
              key={transition.year}
              style={{
                ...styles.dstYearInfo,
                ...(isCurrentTransition ? styles.currentDstHighlight : {})
              }}
            >
              <div><strong>{transition.year}:</strong></div>
              <div>→ Starts: {transition.start.date} at {transition.start.time}</div>
              <div>→ Ends: {transition.end.date} at {transition.end.time}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={styles.calendar}>
      <div style={styles.header}>
        <button onClick={prevMonth} style={styles.button}>&lt;</button>
        <h2 style={styles.monthYear}>
          {currentDate.format('MMMM YYYY')}
        </h2>
        <button onClick={nextMonth} style={styles.button}>&gt;</button>
      </div>

      {isDstTransitionMonth && renderDSTInfo()}

      <div style={styles.weekdays}>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} style={styles.weekday}>{day}</div>
        ))}
      </div>

      <div style={styles.days}>
        {[...Array(currentDate.startOf('month').day()).keys()].map((_, index) => (
          <div key={`empty-${index}`}></div>
        ))}
        {[...Array(currentDate.daysInMonth()).keys()].map((_, index) => {
          const day = index + 1;
          const date = currentDate.clone().date(day);
          const dateStr = date.format('YYYY-MM-DD');
          const hasSlots = availableDates.has(dateStr);
          const isDstTransitionDate = dstTransitions.some(transition => {
            return dateStr === moment(transition.start.date).format('YYYY-MM-DD') ||
                   dateStr === moment(transition.end.date).format('YYYY-MM-DD');
          });
          
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              style={{
                ...styles.day,
                backgroundColor: isDstTransitionDate ? '#fff3e0' : 
                               hasSlots ? '#e6ffe6' : 'transparent',
                color: currentDate.date() === day ? '#4CAF50' : 'inherit',
                fontWeight: currentDate.date() === day ? 'bold' : 'normal',
              }}
            >
              {day}
            </button>
          );
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              style={{
                ...styles.day,
                backgroundColor: isDstTransitionDate ? '#fff3e0' : 
                               hasSlots ? '#e6ffe6' : 'transparent',
                color: currentDate.date() === day ? '#4CAF50' : 'inherit',
                fontWeight: currentDate.date() === day ? 'bold' : 'normal',
              }}
            >
              {day}
              {isDstTransitionDate && (
                <span 
                  style={{ 
                    position: 'absolute', 
                    bottom: '2px', 
                    right: '2px', 
                    width: '4px', 
                    height: '4px', 
                    borderRadius: '50%', 
                    backgroundColor: '#ff9800' 
                  }} 
                />
              )}
            </button>
          );
        })}
      </div>
      
      {availableDates.size === 0 && (
        <p style={{ textAlign: 'center', marginTop: '20px' }}>
          {t('MobileCalendar.NoAvailableDates')}
        </p>
      )}
      
      <Modal
        title={
          <div>
            {t('StudentWallet.AvailableHours')}
            {isDstTransitionMonth && currentDate && (
              <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
                {dstTransitions.map(transition => {
                  if (currentDate.format('YYYY-MM') === moment(transition.start.date).format('YYYY-MM')) {
                    return `DST Starts: ${transition.start.date} at ${transition.start.time}`;
                  }
                  if (currentDate.format('YYYY-MM') === moment(transition.end.date).format('YYYY-MM')) {
                    return `DST Ends: ${transition.end.date} at ${transition.end.time}`;
                  }
                  return null;
                }).filter(Boolean)}
              </div>
            )}
          </div>
        }
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            {t('YourMaterialModal2.close')}
          </Button>
        ]}
      >
        <div style={styles.slotContainer}>
          {selectedDateSlots.length > 0 ? (
            <>
              {selectedDateSlots.map(renderSlot)}
              {isDstTransitionMonth && (
                <div style={{ ...styles.dstIndicator, marginTop: '12px' }}>
                  Note: Times are shown in {moment().tz(selectedTimezone).format('z')} timezone
                </div>
              )}
            </>
          ) : (
            <p>{t('MobileCalendar.NoSlotsAvailable')}</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MobileCalendar;