import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HiddenRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Push a new entry to the history stack without changing the URL
    window.history.pushState(null, '', '/');
  }, []);

  return <>{children}</>;
};

export default HiddenRoute;