import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Signup_Student } from "../store/actions/studentsActions";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const SignupFormPopup = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchref = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("global");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [savedOtp, setSavedOtp] = useState("");
  const [acceptRules, setAcceptRules] = useState(false);
  const [acceptPolicies, setAcceptPolicies] = useState(false);
  const [verified, setVerified] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const formRef = useRef(null);
  const [showEmailWarning, setShowEmailWarning] = useState(false);

  const openPdf = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = t("Signupform.firstNameRequired");
      isValid = false;
    }
    if (!lastName.trim()) {
      errors.lastName = t("Signupform.lastNameRequired");
      isValid = false;
    }

    if (!Email.trim()) {
      errors.email = t("Signupform.emailRequired");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      errors.email = t("Signupform.emailInvalid");
      isValid = false;
    }

    if (!Password.trim()) {
      errors.password = t("Signupform.passwordRequired");
      isValid = false;
    } else if (Password.length < 6) {
      errors.password = t("Signupform.passwordLength");
      isValid = false;
    } else if (!/(?=.*\d)(?=.*[!@#$%^&*])/.test(Password)) {
      errors.password = t("Signupform.passwordComplexity");
      isValid = false;
    }

    if (!verifiedOtp) {
      errors.otp = t("Signupform.otpRequired");
      isValid = false;
    }

    if (!acceptRules) {
      errors.acceptRules = t("Signupform.rulesRequired");
      isValid = false;
    }

    if (!acceptPolicies) {
      errors.acceptPolicies = t("Signupform.policiesRequired");
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    const handleInteraction = () => {
      setIsPaused(true);
    };

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('click', handleInteraction);
      formElement.addEventListener('focus', handleInteraction, true);
    }

    return () => {
      if (formElement) {
        formElement.removeEventListener('click', handleInteraction);
        formElement.removeEventListener('focus', handleInteraction, true);
      }
    };
  }, []);

  const handleFieldFocus = (e) => {
    if (e.target.name !== "Email" && e.target.name !== "otp" && !verifiedOtp) {
      setShowEmailWarning(true);
      e.target.blur(); // Remove focus from the field
    }
  };

  const handleSendOtp = async () => {
    try {
      const response = await fetch("https://speakable.online/api/sendOtpEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: Email }),
      });
      const data = await response.json();
      if (response.ok) {
        setIsOtpSent(true);
        setSavedOtp(data.otp.toString());
        console.log(t("Signupform.otpSentSuccess"), data.otp);
      } else {
        console.error(t("Signupform.otpSendFailed"));
      }
    } catch (error) {
      console.error(t("Signupform.otpSendError"), error);
    }
  };

  const handleVerifyOtp = () => {
    if (otp === savedOtp) {
      setVerifiedOtp(true);
      setShowEmailWarning(false);
      console.log(t("Signupform.otpVerified"));
    } else {
      console.error(t("Signupform.otpVerificationFailed"));
      setErrors(prevErrors => ({...prevErrors, otp: t("Signupform.invalidOtp")}));
    }
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    if (!verifiedOtp) {
      setShowEmailWarning(true);
      return;
    }
    if (validateForm()) {
      dispatch(
        Signup_Student({
          firstName: firstName,
          lastName: lastName,
          Username: `${firstName} ${lastName}`,
          Email: Email,
          Phone_Number: "",
          Password: Password,
          recaptcha,
        })
      )
        .unwrap()
        .then(() => {
          console.log(t("Signupform.signupSuccess"));
          navigate("/Student-dashboard");
        })
        .catch((error) => {
          console.error(t("Signupform.signupFailed"), error);
          setErrors(prevErrors => ({...prevErrors, general: error}));
        });
      recaptchref.current.reset();
    }
  };

  const onChange = (value) => {
    setRecaptcha(value);
    setVerified(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`register-popup ${isPaused ? 'paused' : ''}`}>
      <div className="register-content">
        <form ref={formRef} onSubmit={SubmitHandler} className="register-form">
          <h2 className="register-title">{t("Signupform.heading")}</h2>
          <div className="register-input-container">
            <input
              className="register-input"
              type="text"
              id="firstName"
              name="firstName"
              placeholder={t("Signupform.firstName")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            {errors.firstName && <span className="register-error">{errors.firstName}</span>}
            
            <input
              className="register-input"
              type="text"
              id="lastName"
              name="lastName"
              placeholder={t("Signupform.lastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            {errors.lastName && <span className="register-error">{errors.lastName}</span>}

            <div className="register-otp-container">
              <input
                className="register-input register-otp-input"
                type="email"
                id="Email"
                name="Email"
                placeholder={t("Signupform.Email")}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="button"
                className="register-otp-button"
                onClick={!isOtpSent ? handleSendOtp : handleVerifyOtp}
                disabled={verifiedOtp}
              >
                {!isOtpSent ? t("Signupform.sendOtp") : verifiedOtp ? t("Signupform.verified") : t("Signupform.verifyOtp")}
              </button>
            </div>
            {errors.email && <span className="register-error">{errors.email}</span>}
            {showEmailWarning && !verifiedOtp && (
              <span className="register-warning">{t("Signupform.verifyEmailWarning")}</span>
            )}
            
            {isOtpSent && !verifiedOtp && (
              <input
                className="register-input"
                type="text"
                id="otp"
                name="otp"
                placeholder={t("Signupform.enterOtp")}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            )}
            
            <div className="register-password-container">
              <input
                className="register-input"
                type={showPassword ? "text" : "password"}
                id="Password"
                name="Password"
                placeholder={t("Signupform.Password")}
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={handleFieldFocus}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="register-password-toggle"
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {errors.password && <span className="register-error">{errors.password}</span>}
            
            <div className="register-checkbox-container">
            <label className="register-checkbox-label">
              <input
                type="checkbox"
                className="register-checkbox"
                checked={acceptRules}
                onChange={(e) => setAcceptRules(e.target.checked)}
                onFocus={handleFieldFocus}
                required
              />
              <a 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  openPdf('https://speakable.online/api/pdf/rulesandcondition.pdf');
                }}
              >
                {t("Signupform.acceptRules")}
              </a>
            </label>
            <label className="register-checkbox-label">
              <input
                type="checkbox"
                className="register-checkbox"
                checked={acceptPolicies}
                onChange={(e) => setAcceptPolicies(e.target.checked)}
                onFocus={handleFieldFocus}
                required
              />
              <a 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  openPdf('https://speakable.online/api/pdf/privacy.pdf');
                }}
              >
                {t("Signupform.acceptPolicies")}
              </a>
            </label>
          </div>
          {errors.acceptRules && <span className="register-error">{errors.acceptRules}</span>}
          {errors.acceptPolicies && <span className="register-error">{errors.acceptPolicies}</span>}
            
            <ReCAPTCHA
              sitekey="6LdyugQqAAAAAJZTuDB8Wg9Z6kDOGv77l0VDmmvg"
              onChange={onChange}
              ref={recaptchref}
            />
          </div>
          <div className="register-button-container">
            <button 
              type="submit" 
              className={`register-submit-button ${verified && verifiedOtp ? 'active' : 'inactive'}`}
              disabled={!verified || !verifiedOtp}
            >
              {t("Signupform.SignUp")}
            </button>
            <button type="button" className="register-close-button" onClick={handleClose}>
              {t("Signupform.Close")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupFormPopup;