import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { imageUpload, videoUpload, Signup_Teacher_By_Admin } from "../../store/actions/teachersActions";
import { Getcourses } from "../../store/actions/coursesActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player';

const AdminAddTeachers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courses = useSelector((state) => state.courses.courseslist);
  const [t] = useTranslation("global");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formData, setFormData] = useState({
    password: "",
    phoneNumber: "",
    address: "",
    coursesAssign: [],
    purchasePrice: "",
    description: "",
    shortTitle: "",
    Email: "",
    Profile_Image: [],
    socialLinks: [
      { platform: "facebook", link: "" },
      { platform: "twitter", link: "" },
      { platform: "instagram", link: "" },
    ],
  });

  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [uploadedVideoFilename, setUploadedVideoFilename] = useState(null);

  useEffect(() => {
    dispatch(Getcourses());
  }, [dispatch]);

  const isFormValid = () => {
    const requiredFields = [
      "password",
      "phoneNumber",
      "address",
      "purchasePrice",
      "description",
      "shortTitle",
      "Email",
    ];
    const missingFields = requiredFields.filter(
      (field) => formData[field].trim() === ""
    );
    return { isValid: missingFields.length === 0, missingFields };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, missingFields } = isFormValid();

    if (isValid) {
      const teacherData = {
        Username: `${firstName} ${lastName}`,
        Password: formData.password,
        Phone_Number: formData.phoneNumber,
        Address: formData.address,
        Description: formData.description,
        Short_Title: formData.shortTitle,
        Purchase_Price: formData.purchasePrice,
        Profile_Image: formData.Profile_Image,
        SocialLinks: formData.socialLinks,
        Courses_assign: formData.coursesAssign,
        Email: formData.Email,
        Video: uploadedVideoFilename,
        UserType: "teacher",
        selectedDays: {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false
        },
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Availability: {}
      };

      try {
        await dispatch(Signup_Teacher_By_Admin(teacherData));
        toast.success("Teacher added successfully. You can add availability later.");
        navigate("/Admin-Dashboard/Teachers");
      } catch (error) {
        toast.error("Failed to add teacher: " + error.message);
      }
    } else {
      toast.error(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleCheckboxChange = (course) => {
    setFormData((prevData) => {
      const isSelected = prevData.coursesAssign.includes(course);
      if (isSelected) {
        return {
          ...prevData,
          coursesAssign: prevData.coursesAssign.filter((id) => id !== course),
        };
      } else {
        return {
          ...prevData,
          coursesAssign: [...prevData.coursesAssign, course],
        };
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialLinkChange = (index, platform, value) => {
    setFormData((prevData) => {
      const updatedSocialLinks = [...prevData.socialLinks];
      updatedSocialLinks[index] = {
        ...updatedSocialLinks[index],
        platform,
        link: value,
      };
      return {
        ...prevData,
        socialLinks: updatedSocialLinks,
      };
    });
  };

  const handleFileUpload = async (event) => {
    const image = event.target.files[0];
    const uploadResult = await dispatch(imageUpload(image));
    setFormData({
      ...formData,
      Profile_Image: formData.Profile_Image?.length
        ? [...formData.Profile_Image, uploadResult.payload]
        : [uploadResult.payload],
    });
  };

  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoFile(file);
      setVideoPreviewUrl(URL.createObjectURL(file));
      
      try {
        const result = await dispatch(videoUpload(file));
        if (videoUpload.fulfilled.match(result)) {
          setUploadedVideoFilename(result.payload);
          toast.success("Video uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        } else {
          throw new Error("Video upload failed");
        }
      } catch (error) {
        toast.error("Failed to upload video: " + error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      }
    }
  };

  const handleImageRemoval = async (val) => {
    setFormData({
      ...formData,
      Profile_Image: [...formData.Profile_Image.filter((img) => img != val)],
    });
  };

  const NextHandler = (e) => {
    e.preventDefault();
    const { isValid, missingFields } = isFormValid();
    const Alldata = { 
      Username: `${firstName} ${lastName}`,
      Password: formData.password,
      Phone_Number: formData.phoneNumber,
      Address: formData.address,
      Description: formData.description,
      Short_Title: formData.shortTitle,
      Purchase_Price: formData.purchasePrice,
      Profile_Image: formData.Profile_Image,
      SocialLinks: formData.socialLinks,
      Courses_assign: formData.coursesAssign,
      Email: formData.Email,
      Video: uploadedVideoFilename,  // Include the uploaded video filename
    };

    if (isValid) {
      if (uploadedVideoFilename) {
        console.log("Video filename included:", uploadedVideoFilename);
      } else {
        console.log("No video uploaded");
      }
      navigate("/Admin-Dashboard/Teachers/add-teacher/add-availability", {
        state: { Alldata },
      });
    } else {
      toast.error(
        `Please fill in all required fields: ${missingFields.join(", ")}`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <>
      <div className="Add_Teachers_main_div">
        <h5>{t("AdminAddTeacher.header")}</h5>
        <form>
          <div className="d-flex justify-content-between mb-3">
            {/* Image section */}
            <div className="image-upload-section" style={{ width: '48%', marginRight: '2%' }}>
              <h6 className="mb-2">{t("AdminAddTeacher.ProfileImage")}</h6>
              <div className="Addteacherimage_box">
                {formData.Profile_Image?.map((md, index) => {
                  return (
                    <div
                      className="col-6 col-sm-6 col-lg-3 mt-2 mt-md-0 mb-md-0 mb-2 "
                      key={index}
                    >
                      <img
                        className="w-100 active"
                        src={"https://ik.imagekit.io/8s3jwexmv/" + md}
                      />

                      <span
                        className="badge bg-danger badge-pill badge-round ml-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleImageRemoval(md);
                        }}
                      >
                        {t("AdminAddTeacher.Delete")}
                      </span>
                    </div>
                  );
                })}
              </div>
              {formData.Profile_Image?.length < 10 && (
                <div className="widthFormField">
                  <div className="card-body">
                    <p style={{ fontSize: "12px" }} className="card-text">
                      {t("AdminAddTeacher.SelectImage")}
                    </p>
                    <input
                      className="form-control"
                      encType="multipart/form-data"
                      type="file"
                      name="images"
                      id="formFile"
                      onChange={handleFileUpload}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Video Upload Section */}
            <div className="video-section" style={{ width: '48%' }}>
              <h6>{t("AdminAddTeacher.UploadVideo")}</h6>
              {videoPreviewUrl && (
                <div className="video-preview" style={{ width: '100%', height: '200px', marginBottom: '20px' }}>
                  <ReactPlayer
                    url={videoPreviewUrl}
                    controls
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
              <div style={{ maxWidth: '300px' }}>
              <input
                type="file"
                className="form-control mb-2"
                onChange={handleVideoUpload}
                accept="video/*"
                style={{ width: '100%' }}
              />
            </div>
            {uploadedVideoFilename && (
              <p>Uploaded video: {uploadedVideoFilename}</p>
            )}
          </div>
          </div>

          <div className="form_group_div  mt-2">
            <div className="form-group widthFormField">
              <input
                type="text"
                className="form-control"
                placeholder={t("AdminAddTeacher.pl1")}
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group widthFormField">
              <input
                type="text"
                className="form-control"
                placeholder={t("AdminAddTeacher.pl2")}
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group widthFormField">
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                placeholder={t("AdminAddTeacher.pl3")}
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form_group_div mt-2">
            <div className="form-group widthFormField ">
              <PhoneInput
                country={"us"}
                className="mt-2"
                value={formData.phoneNumber}
                name="phoneNumber"
                onChange={(value, country, e, formattedValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    phoneNumber: formattedValue,
                  }));
                }}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                  autoFocus: true,
                  className: "form-control teacherphonenumberinput",
                }}
              />
            </div>
            <div className="form-group widthFormField ">
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder={t("AdminAddTeacher.pl4")}
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group widthFormField">
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder={t("AdminAddTeacher.pl5")}
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form_group_div mt-2">
            <div className="form-group widthFormField ">
              <input
                type="text"
                className="form-control"
                id="shortTitle"
                placeholder={t("AdminAddTeacher.pl6")}
                name="shortTitle"
                value={formData.shortTitle}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group widthFormField ">
              <input
                type="number"
                className="form-control"
                id="purchasePrice"
                name="purchasePrice"
                placeholder={t("AdminAddTeacher.pl7")}
                value={formData.purchasePrice}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group widthFormField">
              <input
                type="Email"
                className="form-control"
                id="Email"
                name="Email"
                placeholder={t("AdminAddTeacher.pl8")}
                value={formData.Email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group mt-2 form_group_div ">
            {formData.socialLinks.map((socialLink, index) => (
              <div key={index} className="social-link-item widthFormField">
                <div className="form-group justify-content-between">
                  <input
                    type="text"
                    className="form-control "
                    placeholder={socialLink.platform}
                    value={socialLink.link}
                    onChange={(e) =>
                      handleSocialLinkChange(
                        index,
                        socialLink.platform,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="form-group widthFormField">
            <label htmlFor="Status">{t("AdminAddTeacher.SelectCourses")}</label>{" "}
            &nbsp;&nbsp;
            {courses?.map((values) => (
              <div key={values._id} className="form-check">
                <input
                  type="checkbox"
                  id={values._id}
                  value={values._id}
                  checked={formData.coursesAssign.includes(values._id)}
                  onChange={() => handleCheckboxChange(values._id)}
                  className="form-check-input"
                />
                <label htmlFor={values._id} className="form-check-label">
                  {values.Course_Name}
                </label>
              </div>
            ))}
          </div>
          <button
            onClick={NextHandler}
            className="btn btn-outline-success mt-3 w-100"
          >
            {t("AdminAddTeacher.NEXT")}
          </button>

          <button
          onClick={handleSubmit}
            type="submit"
            className="btn btn-outline-success mt-3 w-100"
          >
            {t("AdminAddTeacher.ADD_TEACHER")}
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminAddTeachers;