import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBookingsByTeacherID } from "../../store/actions/bookingActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { joinWindowsApp } from './frontendWindowsJitsiLauncher';
import { Table, Button, Card, Tag, message, List, Typography, Space, Select, Tooltip, Popover, Modal, Input } from 'antd';
import { connectWebSocket, sendWebSocketMessage, joinRoom, leaveRoom } from "../../store/actions/teachersActions";
import { teacherJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import { useMediaQuery } from 'react-responsive';
import { InfoCircleOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Text } = Typography;

const TeacherBookings = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const teacher = useSelector((state) => state.students.user);
  const Bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const teacherState = useSelector((state) => state.teacher);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [isAppDownloaded, setIsAppDownloaded] = useState(localStorage.getItem('isAppDownloaded') === 'true');
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(localStorage.getItem('selectedTimezone') || moment.tz.guess());
  const [utcTime, setUtcTime] = useState(null);

  const isConnected = teacherState?.isConnected || false;
  const lastMessage = teacherState?.lastMessage || null;

  const bookingRules = {
    cancelled: "Cancelled meetings can't be joined.",
    completed: "Completed meetings can't be joined.",
    consecutive: "Consecutive slots are made up of multiple meetings."
  };

  const showJoinModal = (id) => {
    setSelectedBookingId(id);
    setIsModalVisible(true);
  };

  const handleJoinChoice = (choice) => {
    setIsModalVisible(false);
    if (choice === 'browser') {
      joinBrowserRoom(selectedBookingId);
    } else if (choice === 'windows') {
      joinWindowsApp(selectedBookingId);
    }
  };

  const copyBookingId = () => {
    navigator.clipboard.writeText(selectedBookingId).then(() => {
      message.success('Booking ID copied to clipboard');
    }, (err) => {
      message.error('Failed to copy booking ID');
      console.error('Could not copy text: ', err);
    });
  };

  const joinBrowserRoom = async (id) => {
    setJoiningRoom(true);
    try {
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      } else {
        console.warn('WebSocket is not connected');
      }

      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId: id, teacherId: teacher._id }));
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }

      await dispatch(joinRoom(id));
      message.success('Successfully joined the room.');
      setCurrentBookingId(id);

      const roleInfo = encodeURIComponent(JSON.stringify({ role: 'teacher', teacherId: teacher._id }));
      window.open(`/room/meeting/${id}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  };

  const checkAppInstallation = () => {
    return new Promise((resolve) => {
      const protocolCheck = 'speakable-online://';
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
  
      let isInstalled = false;
      const checkFocus = () => {
        if (document.hasFocus()) {
          isInstalled = true;
          cleanup();
        }
      };
  
      const interval = setInterval(checkFocus, 100);
  
      const cleanup = () => {
        clearInterval(interval);
        document.body.removeChild(iframe);
        window.removeEventListener('blur', onBlur);
        resolve(isInstalled);
      };
  
      const onBlur = () => {
        isInstalled = true;
        cleanup();
      };
  
      window.addEventListener('blur', onBlur);
  
      setTimeout(() => {
        if (!isInstalled) {
          cleanup();
        }
      }, 1000);
  
      iframe.src = protocolCheck;
    });
  };

  const downloadApp = () => {
    const downloadUrl = 'https://speakable.online/api/Speakable-Online-Setup-2024.10.0.exe';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Speakable-Online-Setup-2024.10.0.exe';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsAppDownloaded(true);
    localStorage.setItem('isAppDownloaded', 'true');
    message.success('Downloading Speakable-Online application. Please install it after downloading.');
  };

  useEffect(() => {
    const isDownloaded = localStorage.getItem('isAppDownloaded') === 'true';
    setIsAppDownloaded(isDownloaded);
  }, []);

  const joinWindowsApp = async (id) => {
    setJoiningRoom(true);
    try {
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      } else {
        console.warn('WebSocket is not connected');
      }
  
      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId: id, teacherId: teacher._id }));
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
  
      await dispatch(joinRoom(id));
      message.success('Successfully joined the room.');
      setCurrentBookingId(id);
  
      const isAppInstalled = await checkAppInstallation();
  
      const jitsiDomain = "meet.speakable.online";
      const roomName = id;
      const userInfo = encodeURIComponent(JSON.stringify({
        displayName: `${teacher.Username} (Teacher)`,
        email: teacher.email,
        role: 'teacher'
      }));
  
      const jitsiAppUrl = `speakable-online://${jitsiDomain}/${roomName}?jwt=${teacher._id}&userInfo=${userInfo}#config.prejoinPageEnabled=false`;
      
      if (isAppInstalled) {
        window.location.href = jitsiAppUrl;
      } else if (!isAppDownloaded) {
        // If not installed and not downloaded, show a modal to ask if the user wants to download
        Modal.confirm({
          title: 'Speakable-Online App Not Detected',
          content: 'The Speakable-Online app does not appear to be installed. Would you like to download it now?',
          onOk() {
            downloadApp();
          },
          onCancel() {
            message.info('You can join the meeting via browser instead.');
          },
        });
      } else {
        // If downloaded but not installed, show a message to install
        message.info('Please install the Speakable-Online application and try again.');
      }
  
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  };

  const RenderBookingInfo = ({ isConsecutive }) => (
    <div>
      <p>{bookingRules.cancelled}</p>
      <p>{bookingRules.completed}</p>
      {isConsecutive && <p>{bookingRules.consecutive}</p>}
    </div>
  );

  useEffect(() => {
    dispatch(connectWebSocket());
  }, [dispatch]);

  useEffect(() => {
    const fetchUTCTime = async () => {
      try {
        const response = await axios.get('http://worldtimeapi.org/api/timezone/Etc/UTC');
        setUtcTime(moment(response.data.utc_datetime));
      } catch (error) {
        console.error("Error fetching UTC time:", error);
        setUtcTime(moment.utc());
      }
    };

    fetchUTCTime();
    const intervalId = setInterval(fetchUTCTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (teacher?._id) {
      setIsLoading(true);
      dispatch(GetBookingsByTeacherID(teacher._id))
        .then(() => setIsLoading(false))
        .catch(error => {
          console.error('Error fetching bookings:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, teacher?._id]);

  useEffect(() => {
    if (lastMessage && lastMessage.type === 'teacherJoined') {
      console.log('Teacher joined response:', lastMessage);
    }
  }, [lastMessage]);

  const formatStudentName = (student) => {
    if (!student || !student.Username) return 'N/A';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  };

  const roomHandler = useCallback(async (id) => {
    setJoiningRoom(true);
    try {
      if (isConnected) {
        dispatch(sendWebSocketMessage({ type: 'teacherJoined', bookingId: id }));
      } else {
        console.warn('WebSocket is not connected');
      }

      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId: id, teacherId: teacher._id }));
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }

      await dispatch(joinRoom(id));
      message.success('Successfully joined the room.');
      setCurrentBookingId(id);

      const roleInfo = encodeURIComponent(JSON.stringify({ role: 'teacher', teacherId: teacher._id }));
      window.open(`/room/meeting/${id}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');

    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  }, [isConnected, dispatch, teacher._id]);

  useEffect(() => {
    return () => {
      if (currentBookingId) {
        dispatch(leaveRoom(currentBookingId)).catch(error => {
          console.error('Error notifying teacher left:', error);
        });
      }
    };
  }, [currentBookingId, dispatch]);

  const convertUTCToLocal = (date, time) => {
    return moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  };

  const renderTime = (time, date) => {
    if (!time || !date) return '';
    const localDateTime = convertUTCToLocal(date, time);
    return hourFormat === '12' 
      ? localDateTime.format('hh:mm A')
      : localDateTime.format('HH:mm');
  };

  const renderDate = (date, start) => {
    if (!date || !start) return 'N/A';
    const localDateTime = convertUTCToLocal(date, start);
    return localDateTime.format('MMMM Do, YYYY');
  };

  const getBookingStatus = (date, start, end, status) => {
    if (!utcTime) return 'N/A';
    const bookingStartUTC = moment.utc(`${date} ${start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${date} ${end}`, 'YYYY-MM-DD HH:mm');
  
    if (status === "Cancelled") return "Cancelled";
    if (utcTime.isAfter(bookingEndUTC)) return "Completed";
    if (utcTime.isSameOrAfter(bookingStartUTC) && utcTime.isBefore(bookingEndUTC)) return "In Progress";
    if (status === "Rescheduled") return "Rescheduled";
    return "Scheduled";
  };

  const flattenBookings = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const flattened = bookings.flatMap(booking => 
      (booking.Scheduled_Dates || []).flatMap(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          console.error('Invalid dateObj:', dateObj);
          return [];
        }
        const date = Object.keys(dateObj)[0];
        const timeSlots = dateObj[date];
        if (!Array.isArray(timeSlots) && typeof timeSlots !== 'object') {
          console.error('Invalid timeSlots:', timeSlots);
          return [];
        }
        return (Array.isArray(timeSlots) ? timeSlots : Object.values(timeSlots))
          .filter(slot => slot && typeof slot === 'object' && slot.start && slot.end)
          .map(slot => {
            const startDateTime = moment.utc(`${date} ${slot.start}`).tz(selectedTimezone);
            const endDateTime = moment.utc(`${date} ${slot.end}`).tz(selectedTimezone);
            return {
              ...booking,
              date,
              start: slot.start,
              end: slot.end,
              localDate: startDateTime.format('YYYY-MM-DD'),
              localStart: startDateTime.format('HH:mm'),
              localEnd: endDateTime.format('HH:mm'),
              slotId: `${booking._id}-${date}-${slot.start}-${slot.end}`,
              sortableTimestamp: startDateTime.valueOf(),
              status: getBookingStatus(date, slot.start, slot.end, booking.Status)
            };
          });
      })
    );
  
    flattened.sort((a, b) => a.sortableTimestamp - b.sortableTimestamp);
  
    const merged = [];
    let currentMerge = null;
  
    for (let i = 0; i < flattened.length; i++) {
      const current = flattened[i];
      
      if (currentMerge &&
          current.date === currentMerge.date && 
          moment(current.start, 'HH:mm').isSame(moment(currentMerge.end, 'HH:mm')) &&
          current.Package_ID?._id === currentMerge.Package_ID?._id &&
          current.Student_ID?._id === currentMerge.Student_ID?._id) {
        currentMerge.end = current.end;
        currentMerge.localEnd = current.localEnd;
        currentMerge.slotId += `-${current.slotId}`;
        currentMerge.isConsecutive = true;
        currentMerge.status = getBookingStatus(currentMerge.date, currentMerge.start, current.end, current.Status);
      } else {
        if (currentMerge) {
          merged.push(currentMerge);
        }
        currentMerge = {...current, isConsecutive: false};
      }
    }
  
    if (currentMerge) {
      merged.push(currentMerge);
    }
  
    return merged;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'blue';
      case 'Cancelled':
        return 'red';
      case 'Rescheduled':
        return 'purple';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: t("TeacherBookings.StudentName"),
      dataIndex: ['Student_ID', 'Username'],
      key: 'studentName',
      render: (text, record) => formatStudentName(record.Student_ID),
    },
    {
      title: t("TeacherBookings.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
      render: (text, record) => record.Package_ID?.Package_Name || 'N/A',
    },
    {
      title: t("TeacherBookings.Date"),
      dataIndex: 'date',
      key: 'date',
      render: (date, record) => renderDate(date, record.start),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("TeacherBookings.Time"),
      key: 'time',
      render: (text, record) => (
        <span>
          {`${renderTime(record.start, record.date)} - ${renderTime(record.end, record.date)}`}
          {record.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              {t("TeacherBookings.ConsecutiveBooking")}
            </Tag>
          )}
        </span>
      ),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("TeacherBookings.Status"),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status || 'N/A'}
        </Tag>
      ),
    },
    {
      title: t("TeacherBookings.Actions"),
      key: 'actions',
      render: (text, record) => {
        const isButtonDisabled = ['Cancelled', 'Completed'].includes(record.status);

        return (
          <span>
            <Tooltip title={bookingRules.cancelled}>
              <Button 
                type="primary"
                onClick={() => showJoinModal(record._id)}
                disabled={isButtonDisabled || joiningRoom}
                loading={joiningRoom}
                style={{ marginRight: '10px' }}
              >
                {joiningRoom ? t("TeacherBookings.JoiningRoom") : t("TeacherBookings.JoinRoom")}
              </Button>
            </Tooltip>
            <Popover 
              content={<RenderBookingInfo isConsecutive={record.isConsecutive} />} 
              title="Booking Rules"
              trigger="click"
            >
              <Button icon={<InfoCircleOutlined />} type="text" />
            </Popover>
          </span>
        );
      },
    },
  ];

  const renderMobileCard = (item) => {
    const isButtonDisabled = ['Cancelled', 'Completed'].includes(item.status);

    return (
      <Card style={{ marginBottom: 16 }}>
        <Space direction="vertical" size="small">
          <Text strong>{t("TeacherBookings.StudentName")}: {formatStudentName(item.Student_ID)}</Text>
          <Text>{t("TeacherBookings.PackageName")}: {item.Package_ID?.Package_Name || 'N/A'}</Text>
          <Text>{t("TeacherBookings.Date")}: {renderDate(item.date, item.start)}</Text>
          <Text>
            {t("TeacherBookings.Time")}: {renderTime(item.start, item.date)} - {renderTime(item.end, item.date)}
            {item.isConsecutive && (
              <Tag color="blue" style={{ marginLeft: 8 }}>
                {t("TeacherBookings.ConsecutiveBooking")}
              </Tag>
            )}
          </Text>
          <Text>{t("TeacherBookings.Status")}: 
            <Tag color={getStatusColor(item.status)} style={{ marginLeft: 8 }}>
            {item.status || 'N/A'}
            </Tag>
          </Text>
          <Tooltip title={bookingRules.cancelled}>
          <Button 
            type="primary"
            onClick={() => showJoinModal(item._id)}
            disabled={isButtonDisabled || joiningRoom}
            loading={joiningRoom}
            style={{ width: '100%' }}
          >
            {joiningRoom ? t("TeacherBookings.JoiningRoom") : t("TeacherBookings.JoinRoom")}
          </Button>
        </Tooltip>
          <Popover 
            content={<RenderBookingInfo isConsecutive={item.isConsecutive} />} 
            title="Booking Rules"
            trigger="click"
          >
            <Button icon={<InfoCircleOutlined />} type="text" style={{ width: '100%' }} />
          </Popover>
        </Space>
      </Card>
    );
  };

  const handleTimezoneChange = (value) => {
    setSelectedTimezone(value);
    localStorage.setItem('selectedTimezone', value);
  };

  const handleHourFormatChange = (value) => {
    setHourFormat(value);
    localStorage.setItem('hourFormat', value);
  };

const flattenedBookings = flattenBookings(Bookings);
  
  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <AdminNav />
      <div style={{ padding: '20px' }}>
        <Card 
          title={t("TeacherBookings.head")} 
          extra={
            <Space>
              {/* <Select
                style={{ width: 200 }}
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                options={moment.tz.names().map(tz => ({ value: tz, label: tz }))}
                showSearch
                placeholder="Select timezone"
              />
              <Select
                style={{ width: 120 }}
                value={hourFormat}
                onChange={handleHourFormatChange}
                options={[
                  { value: '12', label: '12-hour' },
                  { value: '24', label: '24-hour' },
                ]}
              /> */}
              <Button onClick={() => dispatch(GetBookingsByTeacherID(teacher._id))}>Refresh Bookings</Button>
            </Space>
          }
          style={{ width: '100%' }}
        >
          {isMobile ? (
            <List
              dataSource={flattenedBookings}
              renderItem={renderMobileCard}
              pagination={{ pageSize: 10 }}
            />
          ) : (
            <Table 
              columns={columns} 
              dataSource={flattenedBookings}
              rowKey="slotId"
              pagination={{ pageSize: 10 }}
              style={{ width: '100%' }}
              sortDirections={['ascend', 'descend']}
              defaultSortOrder="ascend"
              defaultSortField="sortableTimestamp"
            />
          )}
        </Card>
      </div>
      <Modal
        title="Join Meeting"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>How would you like to join the meeting?</p>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 32px)' }}
              value={selectedBookingId}
              readOnly
              placeholder="Booking ID"
            />
            <Tooltip title="Copy Booking ID">
              <Button icon={<CopyOutlined />} onClick={copyBookingId} />
            </Tooltip>
          </Input.Group>
          <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button onClick={() => handleJoinChoice('browser')}>Join with Browser</Button>
            <Space>
              <Button onClick={() => handleJoinChoice('windows')}>
                Join with Windows App
              </Button>
              {!isAppDownloaded && (
                <Tooltip title="Download Speakable-Online App">
                  <Button 
                    icon={<DownloadOutlined />} 
                    onClick={downloadApp}
                    type="text"
                  />
                </Tooltip>
              )}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default TeacherBookings;