import React, { useState } from 'react';

const styles = {
  calendar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    width: '280px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  monthYear: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#666',
  },
  weekdays: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  weekday: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#666',
  },
  days: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
  },
  day: {
    width: '100%',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background-color 0.2s',
  },
};

const MiniCalendar = ({ onDateSelect, tileClassName }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    onDateSelect(selectedDate);
  };

  return (
    <div style={styles.calendar}>
      <div style={styles.header}>
        <button onClick={prevMonth} style={styles.button}>&lt;</button>
        <h2 style={styles.monthYear}>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>
        <button onClick={nextMonth} style={styles.button}>&gt;</button>
      </div>
      <div style={styles.weekdays}>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} style={styles.weekday}>{day}</div>
        ))}
      </div>
      <div style={styles.days}>
        {[...Array(firstDayOfMonth).keys()].map((_, index) => (
          <div key={`empty-${index}`}></div>
        ))}
        {[...Array(daysInMonth).keys()].map((_, index) => {
          const day = index + 1;
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
          const className = tileClassName({ date }) || '';
          const hasSlots = className.includes('has-slots');
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              style={{
                ...styles.day,
                backgroundColor: hasSlots ? '#e6ffe6' : 'transparent',
                ':hover': {
                  backgroundColor: hasSlots ? '#ccffcc' : '#e6e6e6',
                },
              }}
            >
              {day}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MiniCalendar;