import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FindUserByEmail } from "../store/actions/studentsActions";
import FillOTPPopup from "./FillOTPPopup";
import { useTranslation } from "react-i18next";

const FillEmailPopup = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  const FoundedUser = useSelector((state) => state.students.FoundedUser);
  const [Email, setEmail] = useState("");
  const [FillOTP, setFillOTP] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
    dispatch(FindUserByEmail({ Email }));
  };

  const closeFillOTPFormPopup = () => {
    setFillOTP(false);
  };

  const openFillOTPFormPopup = () => {
    setFillOTP(true);
  };

  useEffect(() => {
    if (FoundedUser !== null && FoundedUser.length !== 0) {
      console.log(t("FillEmailPopup.userFound"));
      openFillOTPFormPopup();
    }
  }, [FoundedUser, t]);

  return (
    <>
      <div className="form-popup">
        <div className="form-popup-content">
          <form onSubmit={SubmitHandler}>
            <h5>{t("FillEmailPopup.heading")}</h5>
            <div className="form-group-sign ">
              <input
                type="Email"
                id="Email"
                name="Email"
                placeholder={t("FillEmailPopup.emailPlaceholder")}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="d-flex mt-4">
              <button type="submit" className="btn btn-outline-success mx-3">
                {t("FillEmailPopup.submit")}
              </button>
              <button
                onClick={handleClose}
                className="btn btn-outline-success mx-3"
              >
                {t("FillEmailPopup.close")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {FillOTP && <FillOTPPopup handleClose={closeFillOTPFormPopup} />}
    </>
  );
};

export default FillEmailPopup;