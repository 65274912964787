import React from "react";
import { AlertTriangle, Home, RefreshCw } from "lucide-react";
import './Booknow.css';

const PaymentFailed = () => {
  return (
    <div className="booknow-main">
      <div className="container">
        <div className="invoice-card">
          <div className="invoice-header">
            <h1 className="invoice-title">Payment Failed</h1>
            <p className="invoice-subtitle">Order Details</p>
          </div>

          <div className="invoice-body">
            <div className="payment-failed-message">
              <AlertTriangle size={48} className="failed-icon" />
              <h2>Payment Failed</h2>
              <p>We're sorry, but there was an issue processing your payment.</p>
              <p>Please try again or contact support if the problem persists.</p>
            </div>

            <div className="order-section">
              <div className="order-status status-failed">
                <AlertTriangle className="status-icon" />
                Status: Failed
              </div>
              <div className="thankyou-message">
                <p className="thankyou-text">
                  We apologize, <span className="username">User</span>!
                </p>
                <p className="item-text">
                  Item: <span className="item-name">Your Purchased Item</span>
                </p>
              </div>
              
            </div>

            <div className="status-description">
              Your payment could not be processed at this time. Please check your payment details and try again.
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <button className="btn btn-secondary">
            <Home className="btn-icon" size={24} />
            Back to Home
          </button>
          <button className="btn btn-primary">
            <RefreshCw className="btn-icon" size={24} />
            Retry Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;