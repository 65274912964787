import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Signup_Student } from "../../store/actions/studentsActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const recaptchref = useRef(null);

  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [verified, setverified] = useState(false);
  const [recaptcha, setrecaptcha] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [savedOtp, setSavedOtp] = useState("");

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    Password: "",
    Email: "",
    recaptcha: "",
  });

  useEffect(() => {
    // This effect will run when the component mounts
    console.log("About component mounted");
    // You can add any initialization logic here
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const validateFirstname = (firstName) => {
    return firstName.length >= 3;
  };

  const validatelastName = (lastName) => {
    return lastName.length >= 3;
  };

  const handleSendOtp = async () => {
    if (!validateEmail(Email)) {
      toast.error(t("About.invalidEmail"));
      return;
    }
    try {
      const response = await fetch("https://speakable.online/api/sendOtpEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: Email }),
      });
      const data = await response.json();
      if (response.ok) {
        setIsOtpSent(true);
        setSavedOtp(data.otp.toString());
        toast.success(t("About.otpSentSuccess"));
      } else {
        toast.error(t("About.otpSendFailed"));
      }
    } catch (error) {
      toast.error(t("About.otpSendError"));
    }
  };

  const handleVerifyOtp = () => {
    if (otp === savedOtp) {
      setVerifiedOtp(true);
      toast.success(t("About.otpVerified"));
    } else {
      toast.error(t("About.otpVerificationFailed"));
      setErrors(prevErrors => ({...prevErrors, otp: t("About.invalidOtp")}));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    const newErrors = {};

    if (!validateFirstname(firstName)) {
      toast.warning(t("About.firstNameLength"));
      formValid = false;
    }

    if (!validatelastName(lastName)) {
      toast.warning(t("About.lastNameLength"));
      formValid = false;
    }

    if (!validateEmail(Email)) {
      toast.warning(t("About.invalidEmail"));
      formValid = false;
    }

    if (!validatePassword(Password)) {
      toast.warning(t("About.passwordLength"));
      formValid = false;
    }

    if (!verified) {
      toast.warning(t("About.recaptchaRequired"));
      formValid = false;
    }

    if (!verifiedOtp) {
      toast.warning(t("About.otpVerificationRequired"));
      formValid = false;
    }

    if (formValid) {
      try {
        await dispatch(
          Signup_Student({
            firstName: firstName,
            lastName: lastName,
            Username: `${firstName} ${lastName}`,
            Email: Email,
            Phone_Number: "",
            Password: Password,
            recaptcha,
          })
        );
        toast.success(t("About.signupSuccess"));
        // Reset form or redirect user
      } catch (error) {
        toast.error(t("About.signupFailed"));
      }
    } else {
      setErrors(newErrors);
    }
  };

  const onChange = (value) => {
    setrecaptcha(value);
    setverified(true);
    setErrors((prevState) => ({
      ...prevState,
      recaptcha: "",
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="About_main_div" id="About">
      <div className="About_main_column_div">
        <div className="About_main_column_left_div">
          <h2>{t("About.leftheading")}</h2>
          <div className="w-100 About_main_column_left_div_col">
            <span>
              <i className="bi bi-people-fill"></i>
            </span>
            <div className="About_main_column_left_div_col_right">
              <h3>{t("About.leftSub1")}</h3>
              <p>{t("About.leftSubpara1")}</p>
            </div>
          </div>
          <div className="w-100 About_main_column_left_div_col mt-2">
            <span>
              <i className="bi bi-people-fill"></i>
            </span>
            <div className="About_main_column_left_div_col_right">
              <h3>{t("About.leftSub2")}</h3>
              <p>{t("About.leftSubpara2")}</p>
            </div>
          </div>
          <div className="w-100 About_main_column_left_div_col mt-2">
            <span>
              <i className="bi bi-people-fill"></i>
            </span>
            <div className="About_main_column_left_div_col_right">
              <h3>{t("About.leftSub3")}</h3>
              <p>{t("About.leftSubpara3")}</p>
            </div>
          </div>
        </div>
        <div className="About_main_column_right_div">
          <div className="About_main_column_right_form_div mt-2">
            <h2>{t("About.Signuptoday")}</h2>
            <form onSubmit={onSubmit}>
              <input
                className="w-100 input_style"
                placeholder={t("About.FirstName")}
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="off"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
              <input
                className="w-100 input_style"
                placeholder={t("About.lastName")}
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="off"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {errors.lastName && (
                <div className="error">{errors.lastName}</div>
              )}
              <div style={{display: 'flex', marginBottom: '10px'}}>
                <input
                  className="w-100 input_style"
                  style={{flex: 1, marginRight: '10px'}}
                  placeholder={t("About.YourEmailaddress")}
                  type="Email"
                  id="Email"
                  name="Email"
                  autoComplete="off"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={!isOtpSent ? handleSendOtp : handleVerifyOtp}
                  disabled={verifiedOtp}
                >
                  {!isOtpSent ? t("About.sendOtp") : verifiedOtp ? t("About.verified") : t("About.verifyOtp")}
                </button>
              </div>
              {errors.Email && <div className="error">{errors.Email}</div>}
              
              {isOtpSent && !verifiedOtp && (
                <input
                  className="w-100 input_style"
                  placeholder={t("About.enterOtp")}
                  type="text"
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              )}
              
              <div style={{position: 'relative'}}>
                <input
                  className="w-100 input_style"
                  placeholder={t("About.YourPassowrd")}
                  type={showPassword ? "text" : "password"}
                  id="Password"
                  name="Password"
                  autoComplete="off"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {errors.Password && (
                <div className="error">{errors.Password}</div>
              )}
              <ReCAPTCHA
                sitekey="6LdyugQqAAAAAJZTuDB8Wg9Z6kDOGv77l0VDmmvg"
                onChange={onChange}
                ref={recaptchref}
              />
              {errors.recaptcha && (
                <div className="error">{errors.recaptcha}</div>
              )}
              <button
                type="submit"
                className="btn btn-outline-light mt-3"
              >
                {t("About.GetStarted")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;