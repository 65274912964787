import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTeachers } from "../../store/actions/teachersActions";
import { Getcourses } from "../../store/actions/coursesActions";
import AdminNav from "./AdminNav";
import { ToastContainer, toast } from "react-toastify";
import { CreatePackage } from "../../store/actions/packagesActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminAddPackage = () => {
  const teachers = useSelector((state) => state.teachers.Teacherslist);
  const courses = useSelector((state) => state.courses.courseslist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    dispatch(GetTeachers());
    dispatch(Getcourses());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    Package_Name: "",
    Teachers_ID: [],
    Course_ID: "",  
    Number_of_Lectures: 0,
    Free_Package: false,
    Discount: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Teachers_ID") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "Discount" ? parseFloat(value) : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.Package_Name ||
      !formData.Teachers_ID.length ||
      !formData.Course_ID ||
      !formData.Number_of_Lectures ||
      formData.Discount < 0 || 
      formData.Discount > 100
    ) {
      const emptyFieldName = !formData.Package_Name
        ? "Name of the Package"
        : formData.Teachers_ID.length === 0
        ? "Teacher"
        : !formData.Course_ID
        ? "Course"
        : !formData.Number_of_Lectures
        ? "Number of Lectures"
        : "Discount";

      toast.error(`Please fill in the ${emptyFieldName} field correctly`);
      return;
    }

    if (isNaN(formData.Number_of_Lectures)) {
      toast.error("Number of Lectures should be a numeric value");
      return;
    }
    console.log(formData);

    await dispatch(CreatePackage(formData));

    setFormData({
      Package_Name: "",
      Teachers_ID: [],
      Course_ID: "",
      Number_of_Lectures: 0,
      Free_Package: false,
      Discount: 0,
    });

    navigate("/Admin-Dashboard/Packages");
  };

  const handleCheckboxChange = (id, type) => {
    setFormData((prevData) => {
      if (type === "Course_ID") {
        return { ...prevData, [type]: id };  
      } else {
        const selectedItems = prevData[type].includes(id)
          ? prevData[type].filter((item) => item !== id)
          : [...prevData[type], id];
        return { ...prevData, [type]: selectedItems };
      }
    });
  };

  const handleFreeTrialCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setFormData({
      ...formData,
      Free_Package: isChecked,
    });
  };

  return (
    <>
      <AdminNav />
      <div className="AddCoursestyle">
        <form onSubmit={handleSubmit}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="form-group">
            <label htmlFor="Package_Name">{t("AdminAddPackage.header")}</label>
            <input
              type="text"
              className="form-control"
              id="Package_Name"
              name="Package_Name"
              value={formData.Package_Name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Teachers_ID">
              {t("AdminAddPackage.SelectTeacher")}
            </label>{" "}
            &nbsp;&nbsp;
            {teachers?.map((values) => (
              <div key={values._id} className="form-check">
                <input
                  type="checkbox"
                  id={values._id}
                  value={values._id}
                  checked={formData.Teachers_ID.includes(values._id)}
                  onChange={() =>
                    handleCheckboxChange(values._id, "Teachers_ID")
                  }
                  className="form-check-input"
                />
                <label htmlFor={values._id} className="form-check-label">
                  {values.Username}
                </label>
              </div>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="Course_ID">
              {t("AdminAddPackage.SelectCourse")}
            </label>{" "}
            &nbsp;&nbsp;
            {courses?.map((values) => (
              <div key={values._id} className="form-check">
                <input
                  type="radio"  // Changed to radio button
                  id={values._id}
                  value={values._id}
                  checked={formData.Course_ID === values._id}  // Changed condition
                  onChange={() => handleCheckboxChange(values._id, "Course_ID")}
                  className="form-check-input"
                />
                <label htmlFor={values._id} className="form-check-label">
                  {values.Course_Name}
                </label>
              </div>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="Number_of_Lectures">
              {t("AdminAddPackage.NumberofLectures")}
            </label>
            <input
              type="number"
              className="form-control"
              id="Number_of_Lectures"
              name="Number_of_Lectures"
              value={formData.Number_of_Lectures}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Discount">{t("AdminAddPackage.Discount")}</label>
            <div className="input-group">
              <input
                type="number"
                className="form-control"
                id="Discount"
                name="Discount"
                value={formData.Discount}
                onChange={handleChange}
                min="0"
                max="100"
                step="0.1"
                required
              />
              <div className="input-group-append">
                <span className="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div className="form-check form-switch mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={formData.Free_Package}
              onChange={handleFreeTrialCheckboxChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("AdminAddPackage.MakeThis")}
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-outline-success mt-3 btn-course"
          >
            {t("AdminAddPackage.Submit")}
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminAddPackage;