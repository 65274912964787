import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentDetails, getOrderDetails } from "../../store/actions/paymentActions";
import { useNavigate } from "react-router-dom";
import { Home, CheckCircle, XCircle, Clock, Loader } from "lucide-react";
import { toast } from 'react-toastify';
import './Booknow.css';

// Function to get cookie value
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const Booknow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.students.user);
  const [packageData, setPackageData] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const orderIdFromCookie = getCookie('orderId');
    
    if (orderIdFromCookie) {
      setOrderId(orderIdFromCookie);
    } else {
      console.error("No order ID found in cookie");
      toast.error("Error retrieving order information. Please try again or contact support.");
    }

    const storedPackageData = localStorage.getItem("pendingPurchase");
    if (storedPackageData) {
      setPackageData(JSON.parse(storedPackageData));
    }
  }, []);

  useEffect(() => {
    const fetchOrderStatus = async () => {
      if (orderId) {
        try {
          const result = await dispatch(getOrderDetails(orderId)).unwrap();
          setOrderStatus(result.status);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching order status:", error);
          toast.error("Error checking payment status. Please try again or contact support.");
          setLoading(false);
        }
      }
    };

    fetchOrderStatus();
    
    // Set up polling for order status updates
    const intervalId = setInterval(fetchOrderStatus, 5000); // Check every 5 seconds
    
    return () => clearInterval(intervalId);
  }, [orderId, dispatch]);

  useEffect(() => {
    // Run fetchPaymentDetails in the background
    const fetchPaymentDetailsInBackground = async () => {
      if (orderId) {
        try {
          await dispatch(fetchPaymentDetails());
          // No need to update state or UI here
        } catch (error) {
          console.error("Error fetching payment details in background:", error);
          // No need to show an error toast as this is a background operation
        }
      }
    };

    fetchPaymentDetailsInBackground();
  }, [orderId, dispatch]);

  useEffect(() => {
    if (orderStatus === 'COMPLETED') {
      toast.success("Payment completed successfully!");
      localStorage.removeItem("pendingPurchase");
    }
  }, [orderStatus]);

  const HomeNavigator = () => {
    navigate("/Student-dashboard/dash");
  };

  if (loading) {
    return (
      <div className="booknow-main">
        <div className="loader">
          <img src="/path/to/your/loader-image.png" alt="Loading" className="loader-img" />
        </div>
      </div>
    );
  }

  if (!packageData) {
    return (
      <div className="booknow-main">
        <p className="no-order-text">No package data available.</p>
      </div>
    );
  }

  return (
    <div className="bn-main">
      <div className="bn-container">
        <div className="bn-invoice-card">
          <div className="bn-invoice-header">
            <h1 className="bn-invoice-title">Payment Status</h1>
          </div>
          <div className="bn-invoice-body">
            {orderStatus === 'PENDING' && (
              <>
                <div className="bn-order-status bn-status-pending">
                  <Loader className="bn-status-icon" />
                  Status: PENDING
                </div>
                <p className="bn-pending-message">
                  Your payment is being processed. Please wait...
                </p>
              </>
            )}
            {orderStatus === 'COMPLETED' && (
              <>
                <div className="bn-order-status bn-status-completed">
                  <CheckCircle className="bn-status-icon" />
                  Status: COMPLETED
                </div>
                <div className="bn-thankyou-message">
                  <p className="bn-thankyou-text">
                    Thank you, <span className="bn-username">{user?.Username}</span>!
                  </p>
                  <p className="bn-item-text">
                    Item: <span className="bn-item-name">{packageData.packageName}</span>
                  </p>
                </div>
                <div className="bn-order-detail bn-hours-detail">
                  <Clock className="bn-hours-icon" size={24} />
                  <span className="bn-detail-label">Hours Purchased:</span> {packageData.hours}
                </div>
                <div className="bn-order-details">
                  <div className="bn-order-detail">
                    <span className="bn-detail-label">Total Amount:</span> {packageData.amount}
                  </div>
                </div>
              </>
            )}
            {orderStatus === 'CANCELED' && (
              <>
                <div className="bn-order-status bn-status-failed">
                  <XCircle className="bn-status-icon" />
                  Payment Canceled
                </div>
                <p className="bn-failed-message">
                  Your payment was canceled. Please try again or contact support if you need assistance.
                </p>
              </>
            )}
            {(orderStatus === 'FAILED' || !orderStatus) && (
              <>
                <div className="bn-order-status bn-status-failed">
                  <XCircle className="bn-status-icon" />
                  Payment Failed
                </div>
                <p className="bn-failed-message">
                  We're sorry, but your payment could not be processed successfully.
                  Please try again or contact support if the issue persists.
                </p>
              </>
            )}
          </div>
        </div>
        <div className="bn-action-buttons">
          {orderStatus === 'COMPLETED' && (
            <button className="bn-btn bn-btn-secondary" onClick={() => navigate("/Student-dashboard/Payments")}>
              <Home className="bn-btn-icon" size={24} />
              Invoice Page
            </button>
          )}
          <button className="bn-btn bn-btn-secondary" onClick={HomeNavigator}>
            <Home className="bn-btn-icon" size={24} />
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Booknow;