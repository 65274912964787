import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { changeTeacherPassword, fetchTeacherDetails } from "../../store/actions/teachersActions";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Select from 'react-select';
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import MiniCalendar from './MiniCalendar'; 

const TeacherProfile = () => {
  const student = useSelector((state) => state.students.user);
  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.teachers.currentTeacher);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState('America/New_York');
  const [hourFormat, setHourFormat] = useState('24');
  const [currentLanguage, setCurrentLanguage] = useState("Language");
  const [t, i18n] = useTranslation("global");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [minDate] = useState(new Date());
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (student && student._id) {
          await dispatch(fetchTeacherDetails(student._id)).unwrap();
        } else {
          throw new Error("Student data is not available");
        }
      } catch (err) {
        console.error("Failed to fetch teacher details:", err);
        setError("Failed to load teacher details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, student]);

  useEffect(() => {
    console.log(student);
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }

    const storedHourFormat = localStorage.getItem('hourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, []);

  const handleDateChange = (date, timezone) => {
    const localDate = moment(date).tz(timezone);
    if (localDate.format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {
      setSelectedDate(localDate.toDate());
    }
  };

  useEffect(() => {
    if (student && student.Availability && student.Availability.slots) {
      setAvailableSlots(student.Availability.slots);
    }
  }, [student]);

  const handlePasswordChangeClick = () => {
    setShowPasswordChange(!showPasswordChange);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordError("New passwords don't match");
      return;
    }
    if (newPassword.length < 6) {
      setPasswordError("New password must be at least 6 characters long");
      return;
    }
    try {
      await dispatch(changeTeacherPassword({
        teacherId: formData._id,
        currentPassword,
        newPassword
      })).unwrap();
      setShowPasswordChange(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
    } catch (error) {
      setPasswordError(error.message || 'An error occurred while changing the password');
    }
  };

  const formData = student || {}; 

  const handleEditAvailability = (id) => {
    const availabilityData = {
      _id: formData._id,
      Availability: formData.Availability,
      selectedDates: selectedDates,
      selectedDays: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true
      },
      userTimezone: selectedTimezone,
      hourFormat: hourFormat,
      currentMonth: currentMonth.toISOString(),
      minDate: minDate.toISOString()
    };

    navigate(`/Teacher-dashboard/Edit-Availability/${id}`, {
      state: { formData: availabilityData },
    });
  };

  const handleCalendarClick = (value) => {
    setSelectedDate(value);
  };

  const handleHourFormatChange = (event) => {
    setHourFormat(event.target.value);
    localStorage.setItem('hourFormat', event.target.value);
  };

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('selectedTimezone', timezone);
    // Recalculate the selected date in the new timezone
    setSelectedDate(moment(selectedDate).tz(timezone).toDate());
  };


  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const tileClassName = ({ date }) => {
    const dateString = moment(date).tz(selectedTimezone).format('YYYY-MM-DD');
    return availableSlots.some(slot => {
      const start = moment.utc(slot.start).tz(selectedTimezone);
      const end = moment.utc(slot.end).tz(selectedTimezone);
      return start.format('YYYY-MM-DD') === dateString || 
             (start.isBefore(end) && end.format('YYYY-MM-DD') === dateString);
    }) ? "has-slots" : null;
  };

  const handleChangeLanguage = (lang) => {
    setCurrentLanguage(lang === "pl" ? "Polski" : "English");
    i18n.changeLanguage(lang);
  };

  const renderTime = (timeString) => {
    if (!timeString) return '';
    const time = moment.utc(timeString).tz(selectedTimezone);
    return hourFormat === '12' 
      ? time.format('hh:mm A')
      : time.format('HH:mm');
  };

  const renderAvailability = () => {
    if (!availableSlots.length) return <p>No availability set.</p>;

    const selectedDateString = moment(selectedDate).tz(selectedTimezone).format('YYYY-MM-DD');
    const slotsForSelectedDate = availableSlots.filter(slot => {
      const slotStart = moment.utc(slot.start).tz(selectedTimezone);
      const slotEnd = moment.utc(slot.end).tz(selectedTimezone);
      return slotStart.format('YYYY-MM-DD') === selectedDateString || 
             (slotStart.isBefore(slotEnd) && slotEnd.format('YYYY-MM-DD') === selectedDateString);
    });

    if (!slotsForSelectedDate.length) {
      return <p>No availability for selected date.</p>;
    }

    return (
      <div className="availability-item p-2 mb-2">
        <span>{moment(selectedDate).tz(selectedTimezone).format('YYYY-MM-DD')} ({moment(selectedDate).tz(selectedTimezone).format('dddd')})</span>
        <div className="d-flex flex-wrap justify-content-center TimeListBox">
          {slotsForSelectedDate.map((slot, index) => {
            const start = moment.utc(slot.start).tz(selectedTimezone);
            const end = moment.utc(slot.end).tz(selectedTimezone);
            const crossesMidnight = !start.isSame(end, 'day');
            return (
              <div className="TimeItemBox" key={index}>
                <ul>
                  <li>
                    {renderTime(slot.start)} - {renderTime(slot.end)}
                    {crossesMidnight && ` (Ends ${end.format('MMM D')})`}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <AdminNav />
      <div className="StudentProfile_mainPage_style">
        <div className="StudentProfile_header_style">
          <div className="Profile_header_imgdiv">
            <img
              src={`https://ik.imagekit.io/8s3jwexmv/${formData?.Profile_Image}`}
              alt=""
            />
          </div>
        </div>

        <div className="row StudentName_style">
          <h1 className="studentName">{formData?.Username}</h1>
          <p className="studenttext">{formData?.UserType}</p>
        </div>

        <div className="row">
          {/* User Details */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-person userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.Email")}:</p>
                <p className="userDetailTextData">{formData?.Email}</p>
              </div>
            </div>
          </div>

          {/* Address */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-geo-alt userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.Address")}:</p>
                <p className="userDetailTextData">{formData?.Address}</p>
              </div>
            </div>
          </div>

          {/* Phone */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-telephone userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.Phone")}:</p>
                <p className="userDetailTextData">{formData?.Phone_Number}</p>
              </div>
            </div>
          </div>

          {/* Password change */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-key userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.Password")}</p>
                <button
                  className="btn btn-outline-primary"
                  onClick={handlePasswordChangeClick}
                >
                  {showPasswordChange ? 'Cancel' : t("TeacherProfile.ChangePassword")}
                </button>
                {showPasswordChange && (
                  <form onSubmit={handlePasswordChange} className="mt-3">
                    <input
                      type="password"
                      className="form-control mb-2"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      className="form-control mb-2"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      className="form-control mb-2"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    {passwordError && <p className="text-danger">{passwordError}</p>}
                    <button type="submit" className="btn btn-success">
                      Save New Password
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="col-md-6">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-box-arrow-right userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.Description")}</p>
                <p className="userDetailTextData">{formData?.Description}</p>
              </div>
            </div>
          </div>

          {/* Short Title */}
          <div className="col-md-6">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-box-arrow-right userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p className="userDetailTextTitle">{t("TeacherProfile.ShortTitle")}</p>
                <p className="userDetailTextData">{formData?.Short_Title}</p>
              </div>
            </div>
          </div>

          {/* Preferred Timezone */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-h-square-fill userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Preferred Timezone:</p>
                <Select
                  id="timezone"
                  className="form-control"
                  value={timezoneOptions.find(option => option.value === selectedTimezone)}
                  onChange={handleTimezoneChange}
                  options={timezoneOptions}
                  isSearchable
                />
              </div>
            </div>
          </div>

          {/* Hour Format */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-clock userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Hour Format:</p>
                <select
                  value={hourFormat}
                  onChange={handleHourFormatChange}
                  className="form-control"
                >
                  <option value="12">12-hour</option>
                  <option value="24">24-hour</option>
                </select>
              </div>
            </div>
          </div>

          {/* Language */}
          <div className="col-md-6 col-xs-12">
            <div className="userDetail">
              <div className="userDetaildiv">
                <i className="bi bi-globe userDetailIcon"></i>
              </div>
              <div className="userDetailText">
                <p>Language:</p>
                <div className="dropdown">
                  <button
                    className="btn btn-outline-success dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i className="bi bi-globe-central-south-asia mx-2"></i>
                    {currentLanguage}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleChangeLanguage("en")}
                      >
                        <img
                          style={{ border: "0.01px solid rgb(8 8 8 / 46%)", marginRight: "15px" }}
                          width={25}
                          height={15}
                          src={require("../../assets/English.webp")}
                          alt="English"
                        />{" "}
                        English
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleChangeLanguage("pl")}
                      >
                        {/* Language (continued) */}
                        <img
                          style={{ border: "0.01px solid rgb(8 8 8 / 46%)", marginRight: "15px" }}
                          width={25}
                          height={15}
                          src={require("../../assets/Polish.png")}
                          alt="Polish"
                        />{" "}
                        Polish
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Availability */}
          <div className="col-md-6">
          <div className="userDetail">
            <div className="userDetaildiv">
              <i className="bi bi-calendar-check userDetailIcon"></i>
            </div>
            <div className="userDetailText">
              <p className="userDetailTextTitle">{t("TeacherProfile.Availability")}</p>
              <div className="calendar">
                <MiniCalendar
                  onDateSelect={handleCalendarClick}
                  tileClassName={tileClassName}
                  selectedDates={availableSlots.map(slot => moment(slot.start).format('YYYY-MM-DD'))}
                  currentMonth={currentMonth}
                  setCurrentMonth={setCurrentMonth}
                />
                {renderAvailability()}
              </div>
              <button
                className="btn btn-outline-success mt-3"
                onClick={() => handleEditAvailability(formData._id)}
              >
                {t("TeacherProfile.EditAvailability")}
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default TeacherProfile;