import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { fetch5Teachers } from '../../store/actions/teachersActions';
import noimage from "../../assets/No-Image-Placeholder.svg.webp";

const CustomArrow = ({ direction, onClick }) => {
  return (
    <div 
      className={`ot-custom-arrow ot-${direction}-arrow`} 
      onClick={onClick}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
  );
};

const NextArrow = (props) => <CustomArrow direction="next" {...props} />;
const PrevArrow = (props) => <CustomArrow direction="prev" {...props} />;

const OurTeacher = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const teachersData = useSelector((state) => state.teachers.Teacherslist);

  useEffect(() => {
    dispatch(fetch5Teachers());
  }, [dispatch]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="ot-our-teachers" id="OurTeachers">
      <div className="ot-section-header">
        <h2 className="ot-animate-text">{t("header.OurTeachers")}</h2>
        <p className="ot-animate-text">{t("header.Meetourteam")}</p>
      </div>
      <div className="ot-teacher-slider">
        <Slider {...sliderSettings}>
          {teachersData?.map((teacher) => (
            <div key={teacher._id} className="ot-teacher-card">
              <div className="ot-teacher-card-inner">
                <div className="ot-teacher-image-container">
                  <img 
                    src={teacher.Profile_Image ? `https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image}` : noimage} 
                    alt={`${teacher.Username || t("OurTeachers.Teacher")}${t("OurTeachers.ProfileOf")}`} 
                    className="ot-teacher-image" 
                  />
                  <div className="ot-teacher-overlay">
                    <Link to={`/TeacherDetails/${teacher._id}`} className="ot-view-profile">
                      {t("OurTeachers.ViewProfile")}
                    </Link>
                  </div>
                </div>
                <div className="ot-teacher-info">
                  <h3 className="ot-teacher-name">{teacher.Username?.split(' ')[0] || t("OurTeachers.Unknown")}</h3>
                  <p className="ot-teacher-title">{teacher.Short_Title || t("OurTeachers.NoTitleAvailable")}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default OurTeacher;