import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

export const teacherJoinSession = createAsyncThunk(
  "teacherSession/joinSession",
  async ({ bookingId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/teacher-join-session/${bookingId}/${teacherId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeacherSessionStatus = createAsyncThunk(
  "teacherSession/getStatus",
  async ({ bookingId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/teacher-session-status/${bookingId}/${teacherId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);