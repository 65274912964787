import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import { DownloadOutlined, MessageOutlined } from "@ant-design/icons";
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend } from 'recharts';
import { Tooltip, Modal, Input, Select, Button, Tabs, Card, List, Avatar, Badge, Tag, Spin, Progress, message } from 'antd';
import { FaRegQuestionCircle, FaMoneyBillWave, FaBookOpen, FaCheckCircle, FaClock, FaBan, FaSearch, FaCalendarAlt, FaChartBar, FaListUl, FaUser } from 'react-icons/fa';
import moment from 'moment-timezone';
import axios from 'axios';
import Cookies from 'js-cookie';
import { GetBookingsByTeacherID } from '../../store/actions/bookingActions';
import { GetPackageByTeacherID } from '../../store/actions/packagesActions';
import { teacherJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import YourMaterialModal from './YourMaterialModal';
import ChatPopup from '../../components/admin-dashboard-components/ChatPopup';

const { Option } = Select;
const { TabPane } = Tabs;

const TeacherDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const teacher = useSelector((state) => state.students.user);
  const packages = useSelector((state) => state.packages.Teacher_Packages);
  const bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const [selectedTimezone, setSelectedTimezone] = useState(localStorage.getItem('selectedTimezone') || 'America/New_York');
  const [isYourMaterialModalVisible, setIsYourMaterialModalVisible] = useState(false);
  const [date, setDate] = useState(moment().tz(selectedTimezone).toDate());
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [teacherJoinedStatus, setTeacherJoinedStatus] = useState('Not joined');
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isDateFiltered, setIsDateFiltered] = useState(false);
  const [stats, setStats] = useState({
    income: 0,
    totalLectures: 0,
    completedSessions: 0,
    pendingOrScheduledSessions: 0,
    cancelledSessions: 0,
    rescheduledSessions: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [monthlyBookingsData, setMonthlyBookingsData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [showTimezonePrompt, setShowTimezonePrompt] = useState(false);
  const [initialTimezone, setInitialTimezone] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [datesWithBookings, setDatesWithBookings] = useState([]);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const students = useSelector((state) => state.students.studentslist);

  const chatUsers = useMemo(() => {
    const formattedStudents = students?.map(student => ({
      id: student._id,
      name: student.Username,
      email: student.Email,
      type: 'student',
      avatar: student.Profile_Image?.[0] ? 
        `https://ik.imagekit.io/8s3jwexmv/${student.Profile_Image[0]}` : 
        null
    })) || [];
  
    return [
      ...formattedStudents,
      {
        id: '667fd808df2795ef057c93b5',
        name: 'admin',
        email: 'admin@speakable.com',
        type: 'admin',
        avatar: null
      }
    ];
  }, [students]);

  useEffect(() => {
    setDate(moment(date).tz(selectedTimezone).toDate());
  }, [selectedTimezone]);

  useEffect(() => {
    dispatch(GetBookingsByTeacherID(teacher._id));
    dispatch(GetPackageByTeacherID(teacher._id));
    fetchTimezone();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, teacher._id]);

  useEffect(() => {
    setDate(moment(date).tz(selectedTimezone).toDate());
  }, [selectedTimezone]);

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = moment(date).tz(selectedTimezone).format('YYYY-MM-DD');
      return datesWithBookings.includes(dateString) ? (
        <div className="booked-date-indicator">
          L
        </div>
      ) : null;
    }
    return null;
  };

  const renderTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const momentTime = moment.utc().hour(hours).minute(minutes);
    return hourFormat === '12' 
      ? momentTime.tz(selectedTimezone).format('hh:mm A')
      : momentTime.tz(selectedTimezone).format('HH:mm');
  };

  useEffect(() => {
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }

    const storedHourFormat = localStorage.getItem('hourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, []);

  useEffect(() => {
    if (bookings?.length > 0) {
      const mergedBookings = mergeConsecutiveBookings(bookings);
      const completedSessions = mergedBookings.filter(booking => booking.Status === 'Completed').length;
      const pendingOrScheduledSessions = mergedBookings.filter(booking => ['Scheduled'].includes(booking.Status)).length;
      const rescheduledSessions = mergedBookings.filter(booking => booking.Status === 'Rescheduled').length;
      const cancelledSessions = mergedBookings.filter(booking => booking.Status === 'Cancelled').length;

      setStats({
        income: completedSessions * teacher.Purchase_Price,
        totalLectures: mergedBookings.length,
        completedSessions,
        pendingOrScheduledSessions,
        cancelledSessions,
        rescheduledSessions
      });

      const currentMonth = moment().month();
      const currentYear = moment().year();
      const daysInMonth = moment().daysInMonth();
      
      const bookingsThisMonth = mergedBookings.filter(booking => 
        moment(booking.createdAt).month() === currentMonth &&
        moment(booking.createdAt).year() === currentYear
      );

      const dailyBookings = Array(daysInMonth).fill(0);
      bookingsThisMonth.forEach(booking => {
        const day = moment(booking.createdAt).date() - 1; 
        dailyBookings[day]++;
      });

      const chartData = dailyBookings.map((count, index) => ({
        day: index + 1,
        bookings: count
      }));

      setMonthlyBookingsData(chartData);

      setPieChartData([
        { name: 'Completed', value: completedSessions },
        { name: 'Pending', value: pendingOrScheduledSessions },
        { name: 'Rescheduled', value: rescheduledSessions },
        { name: 'Cancelled', value: cancelledSessions },
      ]);

      const bookingDates = new Set();
      mergedBookings.forEach(booking => {
        booking.Scheduled_Dates?.forEach(dateObj => {
          const date = Object.keys(dateObj)[0];
          const startDateTime = moment.tz(`${date} ${dateObj[date][0].start}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
          const endDateTime = moment.tz(`${date} ${dateObj[date][0].end}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
          
          // Add both start and end dates to account for midnight crossings
          bookingDates.add(startDateTime.format('YYYY-MM-DD'));
          if (!startDateTime.isSame(endDateTime, 'day')) {
            bookingDates.add(endDateTime.format('YYYY-MM-DD'));
          }
        });
      });
      setDatesWithBookings(Array.from(bookingDates));
    }
  }, [bookings, selectedTimezone]);

  


  const formatStudentName = (student) => {
    if (!student) return '';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  };

  const mergeConsecutiveBookings = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const mergedBookings = [];
    let currentMergedBooking = null;

    bookings.forEach(booking => {
      if (!booking.Scheduled_Dates || !Array.isArray(booking.Scheduled_Dates)) {
        mergedBookings.push(booking);
        return;
      }

      booking.Scheduled_Dates.forEach(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          return;
        }
        
        const date = Object.keys(dateObj)[0];
        let timeSlots = dateObj[date];
        if (!Array.isArray(timeSlots)) {
          timeSlots = [timeSlots];
        }

        timeSlots.sort((a, b) => moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm')));

        timeSlots.forEach(slot => {
          if (!currentMergedBooking) {
            currentMergedBooking = {
              ...booking,
              Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
              isConsecutive: false
            };
          } else {
            const lastDate = Object.keys(currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1])[0];
            const lastSlots = currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1][lastDate];
            const lastSlot = lastSlots[lastSlots.length - 1];

            if (date === lastDate && moment(slot.start, 'HH:mm').isSame(moment(lastSlot.end, 'HH:mm'))) {
              lastSlot.end = slot.end;
              lastSlot.isConsecutive = true;
              currentMergedBooking.isConsecutive = true;
            } else {
              mergedBookings.push(currentMergedBooking);
              currentMergedBooking = {
                ...booking,
                Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
                isConsecutive: false
              };
            }
          }
        });
      });
    });

    if (currentMergedBooking) {
      mergedBookings.push(currentMergedBooking);
    }

    return mergedBookings;
  };

  const fetchTimezone = async () => {
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = ipResponse.data.ip;
  
      const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
      const detectedTimezone = timezoneResponse.data.timezone;
      setInitialTimezone(detectedTimezone);
  
      if (detectedTimezone !== selectedTimezone) {
        setShowTimezonePrompt(true);
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const handleTimezoneChange = (newTimezone) => {
    localStorage.setItem('selectedTimezone', newTimezone);
    setSelectedTimezone(newTimezone);
    setShowTimezonePrompt(false);
    Cookies.remove('keepCurrentTimezone');
  };

  const handleKeepCurrentTimezone = () => {
    setShowTimezonePrompt(false);
    Cookies.set('keepCurrentTimezone', 'true', { expires: 10/24 });
  };

  const handleCalendarClick = (value) => {
    const selectedDate = moment(value).tz(selectedTimezone).startOf('day');
    const endOfSelectedDate = selectedDate.clone().endOf('day');
  
    const filtered = bookings?.filter(booking =>
      booking?.Scheduled_Dates?.some(dateObj => {
        const bookingDate = Object.keys(dateObj)[0];
        const bookingStartTime = dateObj[bookingDate][0].start;
        const bookingEndTime = dateObj[bookingDate][0].end;
  
        const bookingStartDateTime = moment.tz(`${bookingDate} ${bookingStartTime}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
        const bookingEndDateTime = moment.tz(`${bookingDate} ${bookingEndTime}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  
        return (
          (bookingStartDateTime.isSameOrAfter(selectedDate) && bookingStartDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingEndDateTime.isSameOrAfter(selectedDate) && bookingEndDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingStartDateTime.isBefore(selectedDate) && bookingEndDateTime.isAfter(endOfSelectedDate))
        );
      })
    );
  
    setFilteredBookings(filtered);
    setDate(selectedDate.toDate());
    setIsDateFiltered(true);
  };

  const resetDateFilter = () => {
    setFilteredBookings([]);
    setIsDateFiltered(false);
  };

  const isWithinScheduledTime = (scheduledDate, startTimeUTC, endTimeUTC) => {
    const now = moment().tz(selectedTimezone);
    const startDateTime = moment.tz(`${scheduledDate} ${startTimeUTC}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
    const endDateTime = moment.tz(`${scheduledDate} ${endTimeUTC}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
    
    if (endDateTime.isBefore(startDateTime)) {
      endDateTime.add(1, 'day');
    }
    
    const tenMinutesBefore = startDateTime.clone().subtract(10, 'minutes');
  
    return now.isSameOrAfter(tenMinutesBefore) && now.isSameOrBefore(endDateTime);
  };

  const statsData = [
    { name: t('TeacherDash.Income'), value: stats.income, icon: FaMoneyBillWave, color: 'text-success' },
    { name: t('TeacherDash.TotalLectures'), value: stats.totalLectures, icon: FaBookOpen, color: 'text-primary' },
    { name: t('TeacherDash.CompletedSessions'), value: stats.completedSessions, icon: FaCheckCircle, color: 'text-info' },
    { name: t('TeacherDash.PendingSessions'), value: stats.pendingOrScheduledSessions, icon: FaClock, color: 'text-warning' },
    { name: t('TeacherDash.CancelledSessions'), value: stats.cancelledSessions, icon: FaBan, color: 'text-danger' },
    { name: t('TeacherDash.Rescheduled'), value: stats.rescheduledSessions, icon: FaBan, color: 'text-secondary' },
  ];

  const filteredBookingsList = mergeConsecutiveBookings(isDateFiltered ? filteredBookings : bookings)?.filter(booking =>
    booking?.Student_ID?.Username.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (filterStatus === 'All' || booking.Status === filterStatus)
  );

  const handleMaterialsClick = (e, bookingId) => {
    e.stopPropagation();
    setSelectedBookingId(bookingId);
    setIsYourMaterialModalVisible(true);
  };

  const joinRoom = useCallback(async (bookingId) => {
    setJoiningRoom(true);
    try {
      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId, teacherId: teacher._id }));
      
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
  
      message.success('Successfully joined the room.');
      setCurrentBookingId(bookingId);
      
      const roleInfo = encodeURIComponent(JSON.stringify({ role: 'teacher', teacherId: teacher._id }));
      window.open(`/room/meeting/${bookingId}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
      
      setTeacherJoinedStatus('Joined');
  
      const sessionStatusResult = await dispatch(getTeacherSessionStatus({ bookingId, teacherId: teacher._id }));
      console.log('Session status:', sessionStatusResult.payload);
  
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  }, [dispatch, teacher._id]);

  const renderJoinButton = (booking) => {
    const scheduledDate = Object.keys(booking.Scheduled_Dates[0])[0];
    const startTime = booking.Scheduled_Dates[0][scheduledDate][0].start;
    const endTime = booking.Scheduled_Dates[0][scheduledDate][0].end;
    
    const isWithinTime = isWithinScheduledTime(scheduledDate, startTime, endTime);
  
    return (
      <>
        <Button
          onClick={() => joinRoom(booking._id)}
          className={`join-room-btn ${isWithinTime ? "btn-success" : "btn-secondary"}`}
          disabled={!isWithinTime}
        >
          {t("TeacherDash.JoinRoom")}
        </Button>
        {booking.Status !== "Cancelled" && !isWithinTime && (
          <Tooltip title={t("TeacherDash.JoinRoomTooltip")}>
            <FaRegQuestionCircle className="ms-2 text-muted" />
          </Tooltip>
        )}
      </>
    );
  };

  const MobileBookingsChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={pieChartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  const DesktopBookingsChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={monthlyBookingsData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="day"
          tick={{ fontSize: 12 }}
          tickFormatter={(value) => value % 5 === 0 ? value : ''}
        />
        <YAxis tick={{ fontSize: 12 }} />
        <RechartsTooltip />
        <Bar dataKey="bookings" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );

  const MobileMeetingsList = () => (
    <List
      itemLayout="vertical"
      dataSource={filteredBookingsList}
      renderItem={(booking) => (
        <Card className="mb-3">
          <List.Item
            key={booking?._id}
            actions={[
              renderJoinButton(booking),
              <Button
                type="default"
                size="small"
                onClick={(e) => handleMaterialsClick(e, booking._id)}
              >
                {t('TeacherDash.YourMaterial')}
              </Button>
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<FaUser />} />}
              title={formatStudentName(booking?.Student_ID)}
              description={
                <>
                  <Badge 
                    status={
                      booking.Status === 'Completed' ? 'success' :
                      booking.Status === 'Scheduled' ? 'processing' :
                      booking.Status === 'Cancelled' ? 'error' :
                      'default'
                    } 
                    text={booking.Status} 
                  />
                  {booking.Scheduled_Dates?.map((dateObj, dateIndex) => {
                    const originalDate = Object.keys(dateObj)[0];
                    return (
                      <div key={`${dateIndex}-${originalDate}`}>
                        {dateObj[originalDate].map((slot, slotIndex) => {
                          const startDateTime = moment.tz(`${originalDate} ${slot.start}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
                          const endDateTime = moment.tz(`${originalDate} ${slot.end}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
                          return (
                            <p key={`${dateIndex}-${originalDate}-${slotIndex}`} className="mb-0">
                              {startDateTime.format('ddd MMM DD YYYY HH:mm')} - {endDateTime.format('HH:mm')}
                              {slot.isConsecutive && (
                                <Tag color="blue" style={{ marginLeft: 8 }}>
                                  {t('TeacherDash.ConsecutiveBooking')}
                                </Tag>
                              )}
                              {!startDateTime.isSame(endDateTime, 'day') && (
                                <Tag color="orange" style={{ marginLeft: 8 }}>
                                  {t('')}
                                </Tag>
                              )}
                            </p>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              }
            />
          </List.Item>
        </Card>
      )}
    />
  );

  return (
    <div className="container-fluid p-4">
      <h1 className="mb-4">{t('TeacherDash.head')}</h1>

      {/* <ChatPopup 
  currentUser={{
    id: teacher._id,
    name: teacher.Username,
    type: 'teacher'
  }}
  availableUsers={chatUsers}
/> */}

<Tooltip title="Chat Feature Coming Soon!" placement="left">
  <div style={{ 
    position: 'fixed', 
    right: '20px',  
    bottom: '20px', 
    zIndex: 1000 
  }}>
    <Button
      icon={<MessageOutlined style={{ fontSize: '20px' }}/>}
      shape="circle"
      size="large"
      disabled
      style={{
        opacity: 0.6,
        cursor: 'not-allowed'
      }}
    >
      <span style={{
        position: 'absolute',
        top: -2,
        right: -2,
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'pulse 2s infinite'
      }} />
    </Button>
  </div>
</Tooltip>
      
      <div className="row mb-4">
        {statsData.map((stat, index) => (
          <div key={index} className="col-6 col-md-4 col-lg-2 mb-3">
            <div className="card h-100">
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <stat.icon className={`fs-1 mb-2 ${stat.color}`} />
                <h5 className="card-title text-center">{stat.name}</h5>
                <p className="card-text text-center fs-4 fw-bold">{stat.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="row mb-4">
        <div className="col-md-12 col-lg-8 mb-4 mb-lg-0">
          <div className="card h-100">
            <div className="card-header">
              <h2>{t('TeacherDash.MonthlyBookings')}</h2>
            </div>
            <div className="card-body">
              {isMobile ? <MobileBookingsChart /> : <DesktopBookingsChart />}
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="card h-100">
            <div className="card-header">
              <h2>{t('TeacherDash.Events')}</h2>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center">
              <Calendar
                value={date}
                onChange={handleCalendarClick}
                className="w-100"
                tileContent={tileContent}
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="card mb-4">
        <div className="card-header">
          <h2>{t('TeacherDash.Meetings')}</h2>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mt-3">
            <Input
              placeholder={t('TeacherDash.SearchStudent')}
              prefix={<FaSearch />}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', maxWidth: 200, marginBottom: isMobile ? 16 : 0 }}
            />
            <Select
              defaultValue="All"
              style={{ width: '100%', maxWidth: 120 }}
              onChange={(value) => setFilterStatus(value)}
            >
              <Option value="All">{t('TeacherDash.AllStatus')}</Option>
              <Option value="Scheduled">{t('TeacherDash.Scheduled')}</Option>
              <Option value="Completed">{t('TeacherDash.Completed')}</Option>
              <Option value="Rescheduled">{t('TeacherDash.Rescheduled')}</Option>
              <Option value="Cancelled">{t('TeacherDash.Cancelled')}</Option>
            </Select>
          </div>
          {isDateFiltered && (
            <div className="mt-3">
              <Button onClick={resetDateFilter}>
                {t('TeacherDash.ShowAllMeetings')}
              </Button>
            </div>
          )}
        </div>
        <div className="card-body">
          {filteredBookingsList.length > 0 ? (
            isMobile ? (
              <MobileMeetingsList />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>{t('TeacherDash.Meetingshead1')}</th>
                      <th>{t('TeacherDash.Meetingshead2')}</th>
                      <th>{t('TeacherDash.Meetingshead3')}</th>
                      <th>{t('TeacherDash.Meetingshead4')}</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBookingsList?.map((booking, index) => (
                      <tr key={booking?._id}>
                        <td>{formatStudentName(booking?.Student_ID)}</td>
                        <td>
                          {booking.Scheduled_Dates?.map((dateObj, dateIndex) => {
                            const originalDate = Object.keys(dateObj)[0];
                            return (
                              <div key={`${dateIndex}-${originalDate}`}>
                                {dateObj[originalDate].map((slot, slotIndex) => {
                                  const startDateTime = moment.tz(`${originalDate} ${slot.start}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
                                  const endDateTime = moment.tz(`${originalDate} ${slot.end}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
                                  return (
                                    <p key={`${dateIndex}-${originalDate}-${slotIndex}`}>
                                      Date: {startDateTime.format('ddd MMM DD YYYY')}
                                      <br />
                                      Time: {startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}
                                      {slot.isConsecutive && (
                                        <Tag color="blue" style={{ marginLeft: 8 }}>
                                          {t('TeacherDash.ConsecutiveBooking')}
                                        </Tag>
                                      )}
                                      {!startDateTime.isSame(endDateTime, 'day') && (
                                        <Tag color="orange" style={{ marginLeft: 8 }}>
                                          {t('')}
                                        </Tag>
                                      )}
                                    </p>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </td>
                        <td>{booking?.Status}</td>
                        <td>
                          {renderJoinButton(booking)}
                        </td>
                        <td>
                          <button 
                            className="btn btn-warning" 
                            onClick={(e) => handleMaterialsClick(e, booking._id)}
                          >
                            {t('TeacherDash.YourMaterial')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          ) : (
            <p>{t('TeacherDash.NoMeetingsAvailable')}</p>
          )}
        </div>
      </div>

      {showTimezonePrompt && (
        <Modal
          title="Timezone Update"
          open={showTimezonePrompt}
          onOk={() => handleTimezoneChange(initialTimezone)}
          onCancel={handleKeepCurrentTimezone}
          footer={[
            <Button key="keep" onClick={handleKeepCurrentTimezone} style={{ color: '#107314', borderColor: '#107314' }}>
              No, Keep Current
            </Button>,
            <Button key="update" type="primary" onClick={() => handleTimezoneChange(initialTimezone)} style={{ backgroundColor: '#107314', borderColor: '#107314' }}>
              Yes, Update
            </Button>,
          ]}
        >
          <p>
            Your detected timezone ({initialTimezone}) is different from the saved timezone ({selectedTimezone}).
            Would you like to update it?
          </p>
        </Modal>
      )}

      <Modal
        visible={isJoiningRoom}
        title={t("TeacherDash.JoiningRoom")}
        footer={null}
        closable={false}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <p style={{ marginTop: 16 }}>{t("TeacherDash.ConnectingToRoom")}</p>
        </div>
      </Modal>

      <YourMaterialModal
        isVisible={isYourMaterialModalVisible}
        onClose={() => setIsYourMaterialModalVisible(false)}
        bookingId={selectedBookingId}
      />
    </div>
  );
};

export default TeacherDashboard;  