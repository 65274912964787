import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentDetails, getOrderDetails } from "../../store/actions/paymentActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, Button, Input, Space, Card, List, Typography, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useMediaQuery } from 'react-responsive';

const { Text } = Typography;

const Payments = () => {
  const dispatch = useDispatch();
  const paymentsFromStore = useSelector((state) => state.payments.fetchedPaymentDetails);
  const currentStudentId = useSelector((state) => state.students.user?._id); // New line to get current student's ID
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPaymentDetails());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const updatePaymentStatuses = async () => {
      if (paymentsFromStore.length > 0) {
        const filteredPayments = paymentsFromStore.filter(payment => payment.Student_ID?._id === currentStudentId);
        const updatedPayments = await Promise.all(
          filteredPayments.map(async (payment) => {
            try {
              const result = await dispatch(getOrderDetails(payment.OrderID)).unwrap();
              return { ...payment, Status: result.status };
            } catch (error) {
              console.error(`Error fetching status for order ${payment.OrderID}:`, error);
              return payment;
            }
          })
        );
        setPayments(updatedPayments);
      }
    };

    updatePaymentStatuses();
  }, [paymentsFromStore, dispatch, currentStudentId]);

  const handleDownloadInvoice = (payment) => {
    if (payment?._id) {
      navigate(`/Student-dashboard/Payments/Invoice/${payment._id}`, {
        state: payment,
      });
    } else {
      console.error("Payment ID is undefined");
    }
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.querySelector('input').select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: t("Studentpayment.OrderID"),
      dataIndex: 'OrderID',
      key: 'orderId',
      ...getColumnSearchProps('OrderID'),
    },
    {
      title: t("Studentpayment.StudentName"),
      dataIndex: ['Student_ID', 'Username'],
      key: 'studentName',
      render: (text, record) => record.Student_ID?.Username || record.invoice?.studentName || 'N/A',
      ...getColumnSearchProps(['Student_ID', 'Username']),
    },
    {
      title: t("Studentpayment.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
      render: (text, record) => record.Package_ID?.Package_Name || record.invoice?.packageName || 'N/A',
      ...getColumnSearchProps(['Package_ID', 'Package_Name']),
    },
    {
      title: t("Studentpayment.Status"),
      dataIndex: 'Status',
      key: 'status',
      ...getColumnSearchProps('Status'),
    },
    {
      title: t("Studentpayment.Amount"),
      dataIndex: 'Purchase_Amount',
      key: 'amount',
      sorter: (a, b) => a.Purchase_Amount - b.Purchase_Amount,
    },
    {
      title: t("Studentpayment.Sessions"),
      dataIndex: 'Sessions',
      key: 'sessions',
    },
    {
      title: t("Studentpayment.Invoice"),
      key: 'invoice',
      render: (_, record) =>
        record.Status === 'COMPLETED' ? (
          <Button onClick={() => handleDownloadInvoice(record)}>
            {t("Studentpayment.Invoice")}
          </Button>
        ) : null,
    },
  ];

  const renderMobileCard = (item) => (
    <Card style={{ marginBottom: 16 }}>
      <Space direction="vertical" size="small">
        <Text strong>{t("Studentpayment.OrderID")}: {item.OrderID || 'N/A'}</Text>
        <Text>{t("Studentpayment.StudentName")}: {item.Student_ID?.Username || item.invoice?.studentName || 'N/A'}</Text>
        <Text>{t("Studentpayment.PackageName")}: {item.Package_ID?.Package_Name || item.invoice?.packageName || 'N/A'}</Text>
        <Text>{t("Studentpayment.Status")}: {item.Status || 'N/A'}</Text>
        <Text>{t("Studentpayment.Amount")}: {item.Purchase_Amount || 'N/A'}</Text>
        <Text>{t("Studentpayment.Sessions")}: {item.Sessions || 'N/A'}</Text>
        {item.Status === 'Completed' && (
          <Button onClick={() => handleDownloadInvoice(item)} style={{ width: '100%' }}>
            {t("Studentpayment.Invoice")}
          </Button>
        )}
      </Space>
    </Card>
  );

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <AdminNav />
      <div className="Student_mainPage_style" style={{ padding: '20px' }}>
        <div className="Student_header_style">
          <h6 className="text-dark">{t("Studentpayment.header")}</h6>
        </div>
        <div className="Student_list_style mt-3" style={{ overflowX: 'auto' }}>
          {isMobile ? (
            <List
              dataSource={payments}
              renderItem={renderMobileCard}
              pagination={{ pageSize: 10 }}
            />
          ) : (
            <Table 
              columns={columns} 
              dataSource={payments} 
              rowKey="_id"
              pagination={{ pageSize: 10 }}
              scroll={{ x: 'max-content' }}
              style={{ minWidth: '100%' }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Payments;
