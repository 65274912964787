import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';

// Import a Lottie animation JSON file
import loadingAnimation2 from '../components/loadingAnimation2.json';

const Loader2 = ({ 
  loading = true, 
  size = 1000,
  text,
  fullScreen = false,
  customStyles = {}
}) => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation2,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ...(fullScreen ? {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 9999
    } : {}),
    ...customStyles
  };

  if (!loading) return null;

  return (
    <div style={containerStyles}>
      <Lottie options={defaultOptions} height={size} width={size} />
      {text && <p style={{ marginTop: '20px', fontSize: '18px', color: '#333' }}>{t(text)}</p>}
    </div>
  );
};

Loader2.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string,
  fullScreen: PropTypes.bool,
  customStyles: PropTypes.object
};

export default Loader2;