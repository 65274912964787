import { createSlice } from "@reduxjs/toolkit";
import { generateMeetingToken } from "../actions/meetingActions";

const initialState = {
  token: null,
  loading: false,
  error: null,
};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateMeetingToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateMeetingToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(generateMeetingToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default meetingSlice.reducer;