import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetBookingsByStudentID } from "../../store/actions/bookingActions";
import { getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import AdminNav from "../admin-dashboard-components/AdminNav";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { Table, Button, Card, Tag, message, List, Typography, Space, Modal, Spin, Progress, Tooltip, Popover } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Loader2 from "../Loader2";
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

const { Text } = Typography;

const Bookings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const student = useSelector((state) => state.students.user);
  const Bookings = useSelector((state) => state.bookings.StudentID_Booking);
  const selectedTimezone = useSelector((state) => state.students.user.Timezone);
  
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isLoading, setIsLoading] = useState(true);
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [teacherJoined, setTeacherJoined] = useState(false);
  const [waitTimeLeft, setWaitTimeLeft] = useState(10);
  const [utcTime, setUtcTime] = useState(null);

  const bookingRules = {
    reschedule: "Meetings can't be rescheduled within 24 hours of the start time.",
    oneTimeReschedule: "Once rescheduled, a meeting can't be rescheduled again.",
    cancelled: "Cancelled meetings can't be joined, edited, or rescheduled.",
    consecutive: "Consecutive slots must be rescheduled individually as they are made up of multiple meetings."
  };

  const RenderBookingInfo = ({ isConsecutive }) => (
    <div>
      <p>{bookingRules.reschedule}</p>
      <p>{bookingRules.oneTimeReschedule}</p>
      <p>{bookingRules.cancelled}</p>
      {isConsecutive && <p>{bookingRules.consecutive}</p>}
    </div>
  );

  useEffect(() => {
    const fetchUTCTime = async () => {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/Etc/UTC');
        setUtcTime(moment(response.data.utc_datetime));
      } catch (error) {
        console.error("Error fetching UTC time:", error);
        // Fallback to local UTC time if API fails
        setUtcTime(moment.utc());
      }
    };

    fetchUTCTime();
    // Fetch UTC time every minute
    const intervalId = setInterval(fetchUTCTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      try {
        if (student?._id) {
          const result = await dispatch(GetBookingsByStudentID(student._id));
          console.log("Raw bookings data:", result); 
          console.log("Processed bookings data:", flattenBookings(result)); 
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
        message.error("Failed to fetch bookings. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchBookings();
  }, [student?._id, dispatch]);

  const checkTeacherStatus = useCallback(async () => {
    if (!currentBookingId || !currentTeacherId) return;

    try {
      const result = await dispatch(getTeacherSessionStatus({ 
        bookingId: currentBookingId, 
        teacherId: currentTeacherId 
      }));

      if (result.payload && result.payload.isActive) {
        setTeacherJoined(true);
      }
    } catch (error) {
      console.error("Error checking teacher status:", error);
    }
  }, [currentBookingId, currentTeacherId, dispatch]);

  useEffect(() => {
    let intervalId;
    if (isJoiningRoom && !teacherJoined) {
      intervalId = setInterval(checkTeacherStatus, 5000); // Check every 5 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isJoiningRoom, teacherJoined, checkTeacherStatus]);

  useEffect(() => {
    let timerId;
    if (teacherJoined) {
      timerId = setInterval(() => {
        setWaitTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerId);
            setIsJoiningRoom(false);
            navigate(`/Student/room/meeting/${currentBookingId}`);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [teacherJoined, currentBookingId, navigate]);

  const joinRoom = (id, teacherId) => {
    setCurrentBookingId(id);
    setCurrentTeacherId(teacherId);
    setIsJoiningRoom(true);
    setTeacherJoined(false);
    setWaitTimeLeft(10);
  };

  const handleCancelJoining = () => {
    setIsJoiningRoom(false);
    setCurrentBookingId(null);
    setCurrentTeacherId(null);
    setTeacherJoined(false);
    setWaitTimeLeft(10);
  };

  const EditBooking = (id, date, start, end, teacherId) => {
    navigate(`/Student-dashboard/Bookings/edit-Booking/${id}`, {
      state: { 
        selectedDate: date, 
        currentStart: start, 
        currentEnd: end,
        teacherId: teacherId
      }
    });
  };

  const convertUTCToLocal = (date, time) => {
    return moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  };

  const renderTime = (time, date) => {
    if (!time || !date) return '';
    const localDateTime = convertUTCToLocal(date, time);
    return hourFormat === '12' 
      ? localDateTime.format('hh:mm A')
      : localDateTime.format('HH:mm');
  };

  const renderDate = (date, start) => {
    if (!date || !start) return 'N/A';
    const localDateTime = convertUTCToLocal(date, start);
    return localDateTime.format('MMMM Do, YYYY');
  };

  const isEditableBooking = (date, startTime) => {
    if (!utcTime) return false;  // If UTC time hasn't been fetched yet, don't allow editing
    const bookingStartUTC = moment.utc(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
    
    // Check if current UTC time is at least 24 hours before the booking start time
    return utcTime.isBefore(bookingStartUTC.subtract(24, 'hours'));
  };

  const getBookingStatus = (date, start, end, status) => {
    if (!utcTime) return 'N/A';  // If UTC time hasn't been fetched yet, return N/A
    const bookingStartUTC = moment.utc(`${date} ${start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${date} ${end}`, 'YYYY-MM-DD HH:mm');
  
    if (status === "Cancelled") return "Cancelled";
    if (utcTime.isAfter(bookingEndUTC)) return "Completed";
    if (utcTime.isSameOrAfter(bookingStartUTC) && utcTime.isBefore(bookingEndUTC)) return "In Progress";
    if (status === "Rescheduled") return "Rescheduled";
    return "Scheduled";
  };

  const flattenBookings = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const flattened = bookings.flatMap(booking => 
      (booking.Scheduled_Dates || []).flatMap(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          console.error('Invalid dateObj:', dateObj);
          return [];
        }
        const date = dateObj.date || Object.keys(dateObj)[0];
        const timeSlots = dateObj.slots || dateObj[date];
        if (!Array.isArray(timeSlots) && typeof timeSlots !== 'object') {
          console.error('Invalid timeSlots:', timeSlots);
          return [];
        }
        return (Array.isArray(timeSlots) ? timeSlots : [timeSlots])
          .filter(slot => slot && typeof slot === 'object' && slot.start && slot.end)
          .map(slot => {
            const startDateTime = moment.utc(`${date} ${slot.start}`, 'YYYY-MM-DD HH:mm');
            const localStartDateTime = startDateTime.tz(selectedTimezone);
            const localEndDateTime = moment.utc(`${date} ${slot.end}`, 'YYYY-MM-DD HH:mm').tz(selectedTimezone);
            return {
              ...booking,
              date,
              start: slot.start,
              end: slot.end,
              localDate: localStartDateTime.format('YYYY-MM-DD'),
              localStart: localStartDateTime.format('HH:mm'),
              localEnd: localEndDateTime.format('HH:mm'),
              slotId: `${booking._id}-${date}-${slot.start}-${slot.end}`,
              sortableTimestamp: startDateTime.valueOf(),
              status: getBookingStatus(date, slot.start, slot.end, booking.Status)
            };
          });
      })
    );

    flattened.sort((a, b) => a.sortableTimestamp - b.sortableTimestamp);

    const merged = [];
    let currentMerge = null;

    for (let i = 0; i < flattened.length; i++) {
      const current = flattened[i];
      const currentDateTime = moment.utc(current.sortableTimestamp);
      
      if (currentMerge) {
        const mergeEndDateTime = moment.utc(`${currentMerge.date} ${currentMerge.end}`, 'YYYY-MM-DD HH:mm');
        
        if (currentDateTime.isSame(mergeEndDateTime) &&
            current.Package_ID._id === currentMerge.Package_ID._id &&
            current.Teacher_ID[0]._id === currentMerge.Teacher_ID[0]._id) {
          currentMerge.end = current.end;
          currentMerge.localEnd = current.localEnd;
          currentMerge.slotId += `-${current.slotId}`;
          currentMerge.isConsecutive = true;
          currentMerge.status = getBookingStatus(currentMerge.date, currentMerge.start, current.end, current.Status);
        } else {
          merged.push(currentMerge);
          currentMerge = {...current, isConsecutive: false};
        }
      } else {
        currentMerge = {...current, isConsecutive: false};
      }
    }

    if (currentMerge) {
      merged.push(currentMerge);
    }

    return merged;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'blue';
      case 'Cancelled':
        return 'red';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: t("StudentBooking.PackageName"),
      dataIndex: ['Package_ID', 'Package_Name'],
      key: 'packageName',
    },
    {
      title: t("StudentBooking.TeacherName"),
      dataIndex: 'Teacher_ID',
      key: 'teacherName',
      render: (teachers) => teachers?.map(teacher => teacher?.Username).join(", "),
    },
    {
      title: t("StudentBooking.Date"),
      dataIndex: 'date',
      key: 'date',
      render: (date, record) => renderDate(date, record.start),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("StudentBooking.Time"),
      key: 'time',
      render: (text, record) => (
        <span>
          {`${renderTime(record.start, record.date)} - ${renderTime(record.end, record.date)}`}
          {record.isConsecutive && (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              {t('Calendar.ConsecutiveBooking')}
            </Tag>
          )}
        </span>
      ),
      sorter: (a, b) => a.sortableTimestamp - b.sortableTimestamp,
    },
    {
      title: t("StudentBooking.Status"),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status || 'N/A'}
        </Tag>
      ),
    },
    {
      title: t("StudentBooking.Actions"),
      key: 'actions',
      render: (text, record) => {
        if (!utcTime) return null;
        
        const bookingStartUTC = moment.utc(`${record.date} ${record.start}`, 'YYYY-MM-DD HH:mm');
        const bookingEndUTC = moment.utc(`${record.date} ${record.end}`, 'YYYY-MM-DD HH:mm');
        
        const isBookingPast = utcTime.isAfter(bookingEndUTC);
        const isJoinable = (record.status === "Scheduled" || record.status === "Rescheduled" || record.status === "In Progress") && !isBookingPast;
        const isEditable = record.status === "Scheduled" && !isBookingPast && isEditableBooking(record.date, record.start);
    
        return (
          <span>
            {!isBookingPast && (
              <>
                <Tooltip title={bookingRules.cancelled}>
                  <Button 
                    type="primary"
                    onClick={() => joinRoom(record._id, record.Teacher_ID?.[0]?._id)}
                    style={{ marginRight: '10px', backgroundColor: '#74be57', borderColor: '#44643c' }}
                    disabled={!isJoinable}
                  >
                    {t("StudentBooking.JoinRoom")}
                  </Button>
                </Tooltip>
                <Tooltip title={
                  isEditable ? 
                    (record.isConsecutive ? bookingRules.consecutive : bookingRules.reschedule) : 
                    (record.status === "Rescheduled" ? bookingRules.oneTimeReschedule : bookingRules.reschedule)
                }>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => EditBooking(record._id, record.date, record.start, record.end, record.Teacher_ID?.[0]?._id)}
                    disabled={!isEditable}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </>
            )}
            <Popover 
              content={<RenderBookingInfo isConsecutive={record.isConsecutive} />} 
              title="Booking Rules"
              trigger="click"
            >
              <Button icon={<InfoCircleOutlined />} type="text" style={{ marginLeft: '10px' }} />
            </Popover>
          </span>
        );
      },
    }
    // {
    //   title: t("StudentBooking.Actions"),
    //   key: 'actions',
    //   render: (text, record) => {
    //     if (!utcTime) return null;
        
    //     const bookingStartUTC = moment.utc(`${record.date} ${record.start}`, 'YYYY-MM-DD HH:mm');
    //     const bookingEndUTC = moment.utc(`${record.date} ${record.end}`, 'YYYY-MM-DD HH:mm');
        
    //     const isBookingPast = utcTime.isAfter(bookingEndUTC);
    //     const isJoinable = (record.status === "Scheduled" || record.status === "Rescheduled" || record.status === "In Progress") && !isBookingPast;
    
    //     return (
    //       <span>
    //         {!isBookingPast && (
    //           <>
    //             <Tooltip title={bookingRules.cancelled}>
    //               <Button 
    //                 type="primary"
    //                 onClick={() => joinRoom(record._id, record.Teacher_ID?.[0]?._id)}
    //                 style={{ marginRight: '10px', backgroundColor: '#74be57', borderColor: '#44643c' }}
    //                 disabled={!isJoinable}
    //               >
    //                 {t("StudentBooking.JoinRoom")}
    //               </Button>
    //             </Tooltip>
    //             {/* Replaced editable button with non-clickable informational button */}
    //             <Tooltip title="Rescheduling feature is under development">
    //               <Button
    //                 icon={<EditOutlined />}
    //                 disabled={true}
    //                 style={{ cursor: 'not-allowed' }}
    //               >
    //                 Contact Admin to Reschedule
    //               </Button>
    //             </Tooltip>
    //           </>
    //         )}
    //         <Popover 
    //           content={<RenderBookingInfo isConsecutive={record.isConsecutive} />} 
    //           title="Booking Rules"
    //           trigger="click"
    //         >
    //           <Button icon={<InfoCircleOutlined />} type="text" style={{ marginLeft: '10px' }} />
    //         </Popover>
    //       </span>
    //     );
    //   },
    // }
  ];

  const renderMobileCard = (item) => {
    if (!utcTime) return null;

    const bookingStartUTC = moment.utc(`${item.date} ${item.start}`, 'YYYY-MM-DD HH:mm');
    const bookingEndUTC = moment.utc(`${item.date} ${item.end}`, 'YYYY-MM-DD HH:mm');
    
    const isBookingPast = utcTime.isAfter(bookingEndUTC);
    const isJoinable = (item.status === "Scheduled" || item.status === "Rescheduled" || item.status === "In Progress") && !isBookingPast;
    const isEditable = item.status === "Scheduled" && !isBookingPast && isEditableBooking(item.date, item.start);

    return (
      <Card 
        style={{ marginBottom: 16 }}
        extra={
          <Popover 
            content={<RenderBookingInfo isConsecutive={item.isConsecutive} />} 
            title="Booking Rules"
            trigger="click"
          >
            <Button icon={<InfoCircleOutlined />} type="text" />
          </Popover>
        }
      >
        <Space direction="vertical" size="small">
          <Text strong>{t("StudentBooking.PackageName")}: {item.Package_ID?.Package_Name || 'N/A'}</Text>
          <Text>{t("StudentBooking.TeacherName")}: {item.Teacher_ID?.map(teacher => teacher?.Username).join(", ") || 'N/A'}</Text>
          <Text>{t("StudentBooking.Date")}: {renderDate(item.date, item.start)}</Text>
          <Text>{t("StudentBooking.Time")}: {renderTime(item.start, item.date)} - {renderTime(item.end, item.date)}
            {item.isConsecutive && (
              <Tag color="blue" style={{ marginLeft: 8 }}>
                {t('Calendar.ConsecutiveBooking')}
              </Tag>
            )}
          </Text>
          <Text>{t("StudentBooking.Status")}: 
            <Tag color={getStatusColor(item.status)} style={{ marginLeft: 8 }}>
              {item.status || 'N/A'}
            </Tag>
          </Text>
          {!isBookingPast && (
            <>
              <Tooltip title={bookingRules.cancelled}>
                <Button 
                  type="primary"
                  onClick={() => joinRoom(item._id, item.Teacher_ID?.[0]?._id)}
                  style={{ width: '100%', marginBottom: '8px', backgroundColor: '#74be57', borderColor: '#44643c' }}
                  disabled={!isJoinable}
                >
                  {t("StudentBooking.JoinRoom")}
                </Button>
              </Tooltip>
              <Tooltip title={
                isEditable ? 
                  (item.isConsecutive ? bookingRules.consecutive : bookingRules.reschedule) : 
                  (item.status === "Rescheduled" ? bookingRules.oneTimeReschedule : bookingRules.reschedule)
              }>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => EditBooking(item._id, item.date, item.start, item.end, item.Teacher_ID?.[0]?._id)}
                  style={{ width: '100%' }}
                  disabled={!isEditable}
                >
                  Edit
                </Button>
              </Tooltip>
            </>
          )}
        </Space>
      </Card>
    );
  };

  const flattenedBookings = flattenBookings(Bookings);

  if (isLoading) {
    return (
      <Loader2 
        loading={true}
        text="Loading Bookings"
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }

  return (
    <>
      <AdminNav />
      <div style={{ padding: '20px' }}>
        <Card title={t("StudentBooking.header")} style={{ width: '100%' }}>
          {isMobile ? (
            <List
              dataSource={flattenedBookings}
              renderItem={renderMobileCard}
              pagination={{ pageSize: 10 }}
            />
          ) : (
            <Table 
              columns={columns} 
              dataSource={flattenedBookings}
              rowKey="slotId"
              pagination={{ pageSize: 10 }}
              style={{width: '100%'}}
              sortDirections={['ascend', 'descend']}
              defaultSortOrder="ascend"
              defaultSortField="sortableTimestamp"
            />
          )}
        </Card>
      </div>
      <Modal
        visible={isJoiningRoom}
        title="Joining Room"
        footer={null}
        onCancel={handleCancelJoining}
      >
        <div style={{ textAlign: 'center' }}>
          {!teacherJoined ? (
            <>
              <Spin size="large" />
              <p style={{ marginTop: 16 }}>Waiting for the teacher to join...</p>
            </>
          ) : (
            <>
              <Progress type="circle" percent={Math.round((10 - waitTimeLeft) / 10 * 100)} />
              <p style={{ marginTop: 16 }}>Teacher is setting up. Please wait for {waitTimeLeft} seconds...</p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Bookings;