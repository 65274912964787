import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

export const fetchAllpackages = createAsyncThunk(
  "packages/fetchAllpackages",
  async () => {
    const response = await axios.get(`getpackages`);
    return response.data;
  }
);

export const Delete_Package = createAsyncThunk(
  "packages/Delete_Package",
  async (id) => {
    const response = await axios.get(`Delete_Package/${id}`);
    console.log(response);
    return response.data;
  }
);

export const CreatePackage = createAsyncThunk(
  "packages/CreatePackage",
  async (formdata) => {
    const response = await axios.post(`Add_Package`, formdata);
    return response.data;
  }
);

export const updatePackage = createAsyncThunk(
  "packages/updatePackage",
  async ({ PackageID, formData }) => {
    const response = await axios.post(`Update_Package/${PackageID}`, formData);
    return response.data;
  }
);

export const fetchPackage = createAsyncThunk(
  "packages/fetchPackage",
  async (PackageID) => {
    const response = await axios.get(`fetchPackage/${PackageID}`);
    return response.data.package;
  }
);

export const GetPackageByTeacherID = createAsyncThunk(
  "packages/GetPackageByTeacherID",
  async (TeacherID) => {
    const response = await axios.get(`GetPackageByTeacherID/${TeacherID}`);
    return response.data.package;
  }
);

export const MakePayment = createAsyncThunk(
  "packages/MakePayment",
  async (data) => {
    const response = await axios.post(`Make-Payment`, data);
    return response.data;
  }
);

export const Search_Free_Package = createAsyncThunk(
  "packages/Search_Free_Package",
  async () => {
    try {
      const response = await axios.get("Search_Free_Package");
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      throw error;
    }
  }
);

export const fetchStudentPackages = createAsyncThunk(
  "packages/fetchStudentPackages",
  async (studentId) => {
    try {
      const response = await axios.get(`student-packages/${studentId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching student packages:', error);
      throw error;
    }
  }
);

export const SearchPackagebyPackageName = createAsyncThunk(
  "packages/SearchPackagebyPackageName",
  async (input) => {
    try {
      const response = await axios.post(`SearchPackagebyPackageName/${input}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      throw error;
    }
  }
);

export const deductFreeHoursFromPackage = createAsyncThunk(
  "packages/deductFreeHours",
  async ({ packageId, hours, studentId }) => {
    console.log("Sending data to deductFreeHours:", { packageId, hours, studentId });
    const response = await axios.post(`packages/deductFreeHours`, { packageId, hours, studentId });
    return response.data;
  }
);

export const addFreeHour = createAsyncThunk(
  "packages/addFreeHour",
  async ({ StudentID, PackageID, Free_hour_used }) => {
    try {
      const response = await axios.post('use-free-hour', { StudentID, PackageID, Free_hour_used });
      return response.data;
    } catch (error) {
      console.error('Error using/returning free hour:', error);
      throw error;
    }
  }
);

export const getStudentFreeHourUsage = createAsyncThunk(
  "packages/getStudentFreeHourUsage",
  async (StudentID) => {
    try {
      const response = await axios.get(`student-free-hour-usage/${StudentID}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching student free hour usage:', error);
      throw error;
    }
  }
);