import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchcourseDetails } from '../store/actions/coursesActions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CourseDetails.css';

const CourseDetails = () => {
  const navigate = useNavigate();
  const { CourseID } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  const courseData = useSelector((state) => state.courses.courseDetails);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    dispatch(fetchcourseDetails(CourseID));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [dispatch, CourseID]);

  

  const truncateDescription = (text, sentences) => {
    if (!text) return '';
    const sentenceRegex = /[^\.!\?]+[\.!\?]+/g;
    const allSentences = text.match(sentenceRegex);
    if (allSentences && allSentences.length > sentences) {
      return allSentences.slice(0, sentences).join(' ');
    }
    return text;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  if (!courseData) {
    return (
      <div className="cd-error-message">
        <strong>Error!</strong>
        <span> Unable to load course data. Please try again later.</span>
      </div>
    );
  }

  const description = courseData.Description || '';
  const truncatedDescription = truncateDescription(description, 4);

  return (
    <div className="cd-container">
      <div className="cd-main-content">
        <div className="cd-image-wrapper">
          <img 
            src={`https://ik.imagekit.io/8s3jwexmv/${courseData.Course_Images}`} 
            alt={courseData.Course_Name}
            className="cd-course-image"
          />
        </div>
        <div className="cd-info-block">
          <h1 className="cd-course-title">{courseData.Course_Name}</h1>
          <p className="cd-course-description">
            {showFullDescription ? description : truncatedDescription}
          </p>
          {description !== truncatedDescription && (
            <button onClick={toggleDescription} className="cd-read-more-btn">
              {showFullDescription ? t("Courses.readLess") : t("Courses.readMore")}
            </button>
          )}
        </div>
      </div>

      <div className="cd-teachers-section">
        <h2 className="cd-section-title">{t("Courses.RelatedTeachers")} </h2>
        <div className="cd-teachers-grid">
          {courseData.Teachers_Details?.map((teacher) => (
            <div key={teacher._id} className="cd-teacher-card">
              <img 
                src={`https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image}`} 
                alt={teacher.Username}
                className="cd-teacher-image"
              />
              <div className="cd-teacher-info">
                <Link to={`/TeacherDetails/${teacher._id}`} className="cd-teacher-name">
                  {teacher.Username.split(' ')[0]}
                </Link>
                <p className="cd-teacher-title">{teacher.Short_Title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;