import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Loader"; 

const StudentRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.students.user);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const jitsiContainerRef = useRef(null);
  const [jitsiAPI, setJitsiAPI] = useState(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    console.log("Component mounted/updated");
    return () => console.log("Component will unmount");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const formatStudentName = useCallback((student) => {
    if (!student || !student.Username) return 'N/A';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  }, []);

  const initJitsi = useCallback(() => {
    if (jitsiContainerRef.current && jitsiContainerRef.current.childNodes.length === 0 && !isInitialized.current) {
      console.log("Initializing Jitsi");
      isInitialized.current = true;
      const domain = "meet.speakable.online";
      const options = {
        roomName: id,
        width: "100%",
        height: "100%",
        parentNode: jitsiContainerRef.current,
        userInfo: {
          displayName: formatStudentName(user),
          email: user.email,
          role: 'participant'
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            "microphone", "camera", "fodeviceselection", "profile", "chat", "recording", "etherpad", "raisehand", "videoquality", "videobackgroundblur", "videobackgroundblur", "fullscreen", "tileview"
          ],
        },
        configOverwrite: {
          disableDeepLinking: true,
        }
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      setJitsiAPI(api);

      api.on("videoConferenceJoined", () => {
        console.log("Student joined");
        api.executeCommand('setRole', 'participant');
        api.executeCommand('displayName', formatStudentName(user));
        console.log("Set display name:", formatStudentName(user));
      });

      api.on("participantJoined", (participant) => {
        console.log("Participant joined:", participant);
      });

      api.on("videoConferenceLeft", () => {
        console.log("Student left");
        navigate('/');
      });

      api.addListener('readyToClose', () => {
        console.log("Jitsi is ready to close");
        navigate('/');
      });

      api.on('displayNameChange', (event) => {
        console.log('Name changed to:', event.displayname);
      });
    }
  }, [id, user, navigate, formatStudentName]);

  useEffect(() => {
    if (!window.JitsiMeetExternalAPI) {
      const script = document.createElement("script");
      script.src = "https://meet.speakable.online/external_api.js";
      script.async = true;
      script.onload = () => {
        if (id && user) {
          initJitsi();
        }
      };
      document.body.appendChild(script);
    } else if (id && user) {
      initJitsi();
    }
  }, [id, user, initJitsi]);

  useEffect(() => {
    if (jitsiAPI && user) {
      jitsiAPI.executeCommand('displayName', formatStudentName(user));
    }
  }, [jitsiAPI, user, formatStudentName]);

  useEffect(() => {
    return () => {
      if (jitsiAPI) {
        console.log("Disposing Jitsi API");
        jitsiAPI.dispose();
        setJitsiAPI(null);
        isInitialized.current = false;
      }
    };
  }, [jitsiAPI]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!id || !user) {
    return <Loader loading={true} text="Loading... Please wait while we set up your meeting." fullScreen={true} />;
  }

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}>
      {isLoading && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          zIndex: 1000,
          backgroundColor: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Loader 
            loading={true} 
            text="Joining the meeting... Please wait." 
            fullScreen={false} 
            size={1000}
            customStyles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </div>
      )}
      <div ref={jitsiContainerRef} style={{ width: '100%', height: '100%' }} />
      <div style={{ position: 'absolute', top: 10, right: 10, background: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
        Student (Participant)
      </div>
    </div>
  );
};

export default StudentRoom;
