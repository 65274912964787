import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllstudents, updateStudent } from "../../store/actions/studentsActions";
import { imageUpload } from "../../store/actions/teachersActions";
import moment from 'moment-timezone'; 
import Select from 'react-select';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import Loader2 from "../Loader2";

const EditStudentProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students.AllStudentlist);
  const [initialTimezone, setInitialTimezone] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [t, i18n] = useTranslation("global");
  const currentStudent = students.find((student) => student._id === id);
  const navigate = useNavigate();
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [formData, setFormData] = useState({
    Username: "",
    firstName: "",
    lastName: "",
    Nickname: "",
    Password: "",
    Phone_Number: "",
    Address: "",
    Email: "",
    Timezone: "",
    Enquiry_Student: [],
    Profile_Image: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchAllstudents());
      } catch (error) {
        console.error("Error fetching students:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (currentStudent) {
      setFormData({
        ...currentStudent,
        Timezone: currentStudent.Timezone || "America/New_York",
      });
    }
  }, [currentStudent]);

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = ipResponse.data.ip;

        const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
        const timezone = timezoneResponse.data.timezone;
        setInitialTimezone(timezone);

        if (timezone !== currentStudent?.Timezone) {
          setShowPrompt(true);
        }
      } catch (error) {
        console.error('Error fetching timezone:', error);
      }
    };

    fetchTimezone();
  }, [currentStudent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setFormData({
      ...formData,
      Timezone: timezone,
    });
    localStorage.setItem('selectedStudentTimezone', timezone);
  };

  const handleHourFormatChange = (event) => {
    const format = event.target.value;
    setHourFormat(format);
    localStorage.setItem('hourFormat', format);
  };

  const handleFileUpload = async (event) => {
    const image = event.target.files[0];
    setIsUploading(true);
    try {
      const uploadResult = await dispatch(imageUpload(image));
      setFormData({
        ...formData,
        Profile_Image: formData.Profile_Image?.length
          ? [...formData.Profile_Image, uploadResult.payload]
          : [uploadResult.payload],
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const student_ID = id;
    const updatedData = {
      ...formData,
      Username: `${formData.firstName} ${formData.lastName}`,
    };
    setIsUpdating(true);
    try {
      await dispatch(updateStudent({ student_ID, updatedData }));
      window.location.reload();
    } catch (error) {
      console.error("Error editing student:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleImageRemoval = async (val) => {
    setFormData({
      ...formData,
      Profile_Image: [...formData.Profile_Image.filter((img) => img !== val)],
    });
  };

  const handlePromptResponse = (response) => {
    if (response === 'yes') {
      const timezone = initialTimezone;
      setFormData({
        ...formData,
        Timezone: timezone,
      });
      localStorage.setItem('selectedTimezone', timezone);
    }
    setShowPrompt(false);
  };

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  if (isLoading) {
    return (
      <Loader2 
        loading={true}
        text="Loading Student Profile"
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }

  return (
    <>
      <div className="Edit_Teachers_main_div">
        <form>
          {formData.Profile_Image?.map((md, index) => (
            <div
              className="col-6 col-sm-6 col-lg-3 mt-2 mt-md-0 mb-md-0 mb-2"
              key={index}
            >
              <a href="#">
                <img
                  className="w-100 active"
                  src={`https://ik.imagekit.io/8s3jwexmv/${md}`}
                  alt={`Image ${index}`}
                />
              </a>
              <span
                className="badge bg-danger badge-pill badge-round ml-1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleImageRemoval(md);
                }}
              >
                {t("EditStudentProfile.Delete")}
              </span>
            </div>
          ))}
          {formData.Profile_Image?.length < 10 && (
            <div className="col-6 col-sm-6 col-lg-3 mt-2 mt-md-0 mb-md-0 mb-2">
              <div className="card-body">
                <p className="card-text">{t("EditStudentProfile.Selectimage")}.</p>
                <input
                  className="form-control"
                  encType="multipart/form-data"
                  type="file"
                  name="images"
                  id="formFile"
                  onChange={handleFileUpload}
                  disabled={isUploading}
                />
                {isUploading && <Loader2 loading={true} size={100} />}
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="FirstName">{t("EditStudentProfile.FirstName")}</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Nickname">{t("EditStudentProfile.NickName")}</label>
            <input
              type="text"
              className="form-control"
              id="Nickname"
              name="Nickname"
              value={formData.Nickname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Email">{t("EditStudentProfile.Email")}</label>
            <input
              type="email"
              className="form-control"
              id="Email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Password">{t("EditStudentProfile.Password")}</label>
            <input
              type="password"
              className="form-control"
              id="Password"
              name="Password"
              value={formData.Password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Phone_Number">{t("EditStudentProfile.PhoneNumber")}</label>
            <input
              type="text"
              className="form-control"
              id="Phone_Number"
              name="Phone_Number"
              value={formData.Phone_Number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Address">Address</label>
            <input
              type="text"
              className="form-control"
              id="Address"
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <label htmlFor="timezone">Timezone</label>
              <Select
                id="timezone"
                className="basic-single"
                value={timezoneOptions.find(option => option.value === formData.Timezone)}
                onChange={handleTimezoneChange}
                options={timezoneOptions}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-12 pl-3 pb-3">
            <div className="form-group">
              <label htmlFor="hourFormat">Hour Format</label>
              <select
                id="hourFormat"
                className="form-control"
                value={hourFormat}
                onChange={handleHourFormatChange}
              >
                <option value="12">12-hour</option>
                <option value="24">24-hour</option>
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={isUpdating}
          >
            {t("EditStudentProfile.Submit")}
          </button>
        </form>
        {showPrompt && (
          <div className="alert alert-info" role="alert">
            <p>Your current timezone is detected as {initialTimezone}. Do you want to update your timezone to this?</p>
            <button onClick={() => handlePromptResponse('yes')} className="btn btn-success">Yes</button>
            <button onClick={() => handlePromptResponse('no')} className="btn btn-danger">No</button>
          </div>
        )}
      </div>
      
      {isUpdating && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}
        >
          <Loader2 
            loading={true}
            text="Updating Profile"
            size={300}
            customStyles={{
              backgroundColor: 'transparent'
            }}
          />
        </div>
      )}
    </>
  );
};

export default EditStudentProfile;