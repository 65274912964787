// import React, { useEffect, useState, useCallback, useMemo } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
// import {
//   GetExistingTeacherAvailability,
//   Updatebooking,
//   fetchAllbookings,
//   fetchExistingBookings,
// } from "../../../store/actions/bookingActions";
// import { useTranslation } from "react-i18next";
// import AdminNav from "../AdminNav";
// import { Button, Card, Calendar, Badge, Modal, Row, Col, Select, Tooltip } from 'antd';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import moment from 'moment-timezone';

// const AdminEditBooking = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const { teacherId, studentId } = location.state || {};
//   const navigate = useNavigate();
//   const { BookingID } = useParams();
//   const [t] = useTranslation("global");
//   const [status, setStatus] = useState("");
//   const [rawBookings, setRawBookings] = useState([]);
//   const [availableSlots, setAvailableSlots] = useState({});
//   const [mergedSlots, setMergedSlots] = useState({});
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedSlot, setSelectedSlot] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const hourFormat = useMemo(() => localStorage.getItem('hourFormat') || '24', []);
//   const selectedTimezone = useMemo(() => localStorage.getItem('selectedStudentTimezone') || 'America/New_York', []);

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'Scheduled': return '#1890ff';
//       case 'Completed': return '#52c41a';
//       case 'Pending': return '#faad14';
//       case 'Cancelled': return '#f5222d';
//       case 'Rescheduled': return '#722ed1';
//       case 'Available': return '#87d068';
//       default: return '#000000';
//     }
//   };

//   const fetchBookings = useCallback(() => {
//     if (studentId && teacherId) {
//       dispatch(fetchExistingBookings({ 
//         studentId: studentId,
//         teacherId: teacherId,
//         timestamp: Date.now()
//       }))
//         .then((result) => {
//           if (fetchExistingBookings.fulfilled.match(result)) {
//             const bookings = result.payload?.bookings;
//             console.log('Raw bookings data:', bookings);
//             if (Array.isArray(bookings)) {
//               setRawBookings(bookings);
//             } else {
//               console.error("Bookings data is not in the expected format:", bookings);
//               setRawBookings([]);
//               toast.error(t("AdminEditBooking.InvalidBookingsData"));
//             }
//           } else {
//             console.error("Fetching bookings failed:", result.error);
//             setRawBookings([]);
//             toast.error(t("AdminEditBooking.FetchBookingsError"));
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching bookings:", error);
//           setRawBookings([]);
//           toast.error(t("AdminEditBooking.FetchBookingsError"));
//         });
//     } else {
//       console.error('Student ID or Teacher ID not provided');
//     }
//   }, [dispatch, studentId, teacherId, t]);

//   useEffect(() => {
//     dispatch(fetchAllbookings());
//     if (BookingID) {
//       dispatch(GetExistingTeacherAvailability(BookingID))
//         .then((result) => {
//           if (GetExistingTeacherAvailability.fulfilled.match(result)) {
//             const availableSlotsData = result.payload.AvailableTimeSlots[0];
//             console.log('Available slots:', availableSlotsData);
//             setAvailableSlots(availableSlotsData);
//           } else {
//             console.error("Fetching available slots failed:", result.error);
//             toast.error(t("AdminEditBooking.FetchAvailableSlotsError"));
//           }
//         });
//     }
//     fetchBookings();
//   }, [dispatch, BookingID, fetchBookings]);

//   useEffect(() => {
//     const mergedData = {};
    
//     // Process available slots
//     Object.entries(availableSlots).forEach(([date, slots]) => {
//       mergedData[date] = slots.map(slot => ({
//         ...slot,
//         status: 'Available'
//       }));
//     });

//     // Process existing bookings
//     rawBookings.forEach(booking => {
//       booking.Dates.forEach(dateObj => {
//         const date = dateObj.date;
//         if (!mergedData[date]) mergedData[date] = [];
//         dateObj.slots.forEach(slot => {
//           const existingSlotIndex = mergedData[date].findIndex(
//             s => s.start === slot.start && s.end === slot.end
//           );
//           if (existingSlotIndex !== -1) {
//             mergedData[date][existingSlotIndex] = {
//               ...slot,
//               status: booking.Status
//             };
//           } else {
//             mergedData[date].push({
//               ...slot,
//               status: booking.Status
//             });
//           }
//         });
//       });
//     });

//     setMergedSlots(mergedData);
//   }, [availableSlots, rawBookings]);

//   const renderTime = useCallback((time) => {
//     if (!time) return '';
//     const [hours, minutes] = time.split(':');
//     const momentTime = moment.utc().hour(hours).minute(minutes);
//     return hourFormat === '12' 
//       ? momentTime.tz(selectedTimezone).format('hh:mm A')
//       : momentTime.tz(selectedTimezone).format('HH:mm');
//   }, [hourFormat, selectedTimezone]);

//   const dateCellRender = useCallback((value) => {
//     const dateStr = value.format('YYYY-MM-DD');
//     const dateSlots = mergedSlots[dateStr] || [];

//     return (
//       <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
//         {dateSlots.map((slot, index) => (
//           <li key={index}>
//             <Tooltip title={`${renderTime(slot.start)} - ${renderTime(slot.end)}`}>
//               <Badge 
//                 status="default" 
//                 color={getStatusColor(slot.status)} 
//                 text={`${slot.status} (${renderTime(slot.start)})`} 
//               />
//             </Tooltip>
//           </li>
//         ))}
//       </ul>
//     );
//   }, [mergedSlots, renderTime, getStatusColor]);

//   const onSelect = useCallback((value) => {
//     const dateStr = value.format('YYYY-MM-DD');
//     setSelectedDate(dateStr);
//     setIsModalVisible(true);
//   }, []);

//   const handleSlotSelect = useCallback((slot) => {
//     setSelectedSlot(slot);
//   }, []);

//   const handleSubmit = async () => {
//     if (!selectedDate || !selectedSlot) {
//       toast.error(t("AdminEditBooking.SelectDateAndTime"));
//       return;
//     }
    
//     const updatedData = {
//       status: status || "Rescheduled",
//       Scheduled_Dates: [
//         {
//           [selectedDate]: [
//             {
//               start: moment.tz(`${selectedDate} ${selectedSlot.start}`, selectedTimezone).utc().format('HH:mm'),
//               end: moment.tz(`${selectedDate} ${selectedSlot.end}`, selectedTimezone).utc().format('HH:mm')
//             }
//           ]
//         }
//       ]
//     };
    
//     try {
//       await dispatch(Updatebooking({ BookingID, updatedData }));
//       toast.success(t("AdminEditBooking.SuccessMessage"));
//       fetchBookings();

//       // Update the local state to reflect the changes
//       setMergedSlots(prevSlots => ({
//         ...prevSlots,
//         [selectedDate]: prevSlots[selectedDate].map(slot => 
//           slot.start === selectedSlot.start && slot.end === selectedSlot.end
//             ? { ...slot, status: status || "Rescheduled" }
//             : slot
//         )
//       }));

//       setIsModalVisible(false);
//       setSelectedSlot(null);
//     } catch (error) {
//       console.error("Error updating booking:", error);
//       toast.error(t("AdminEditBooking.ErrorMessage"));
//     }
//   };

//   return (
//     <>
//       <AdminNav />
//       <div style={{ padding: '20px' }}>
//         <Card title={t("AdminEditBooking.heading")} style={{ marginBottom: 20 }}>
//           {/* <div className="form-group" style={{ marginBottom: '20px' }}>
//             <label htmlFor="Status">{t("AdminEditBooking.Status")}</label>
//             <Select
//               value={status}
//               onChange={(value) => setStatus(value)}
//               style={{ width: 200 }}
//             >
//               <Select.Option value="Scheduled">Scheduled</Select.Option>
//               <Select.Option value="Completed">Completed</Select.Option>
//               <Select.Option value="Pending">Pending</Select.Option>
//               <Select.Option value="Cancelled">Cancelled</Select.Option>
//               <Select.Option value="Rescheduled">Rescheduled</Select.Option>
//             </Select>
//           </div> */}
//           <Calendar
//             dateCellRender={dateCellRender}
//             onSelect={onSelect}
//             style={{ backgroundColor: '#f0f2f5', borderRadius: '8px', padding: '16px' }}
//           />
//         </Card>
//         <Modal
//           title={t("AdminEditBooking.SelectTimeSlot")}
//           visible={isModalVisible}
//           onOk={handleSubmit}
//           onCancel={() => setIsModalVisible(false)}
//           okText={t("AdminEditBooking.ConfirmBooking")}
//           cancelText={t("AdminEditBooking.Cancel")}
//         >
//           <Row gutter={[16, 16]}>
//             <Col span={24}>
//               <h4>{t("AdminEditBooking.AvailableSlots", { date: selectedDate })}</h4>
//               {mergedSlots[selectedDate]?.map((slot, index) => (
//                 <Button
//                   key={index}
//                   icon={<ClockCircleOutlined />}
//                   onClick={() => handleSlotSelect(slot)}
//                   style={{ width: '100%', marginBottom: 8 }}
//                   type={selectedSlot === slot ? 'primary' : 'default'}
//                   disabled={slot.status !== 'Available'}
//                 >
//                   <Badge 
//                     color={getStatusColor(slot.status)} 
//                     text={`${slot.status}: ${renderTime(slot.start)} - ${renderTime(slot.end)}`} 
//                   />
//                 </Button>
//               ))}
//               {(!mergedSlots[selectedDate] || mergedSlots[selectedDate].length === 0) && (
//                 <p>{t("AdminEditBooking.NoAvailableSlots")}</p>
//               )}
//             </Col>
//           </Row>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default AdminEditBooking;


import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetExistingTeacherAvailability,
  Updatebooking,
  fetchAllbookings,
  fetchExistingBookings,
  fetchBookingsExcludingStudent
} from "../../../store/actions/bookingActions";
import { useTranslation } from "react-i18next";
import AdminNav from "../AdminNav";
import { Card, Button, Modal, Spin } from 'antd';
import moment from 'moment-timezone';

const styles = {
  calendar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  monthYear: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#666',
  },
  weekdays: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  weekday: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#666',
  },
  days: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
  },
  day: {
    width: '100%',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background-color 0.2s',
  },
  slotContainer: {
    marginTop: '16px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  slot: {
    padding: '8px',
    margin: '4px 0',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  statusIndicator: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '4px',
  },
};

const statusColors = {
  Scheduled: '#5C7153',
  Cancelled: '#F44336',
  Rescheduled: '#FFC107',
  Completed: '#2196F3',
  Available: '#4CAF50',
  OtherStudentBooking: '#FF9800',
};

const MobileCalendar = ({
  selectedTeacherId,
  availability,
  existingBookings,
  otherStudentBookings,
  selectedSlots,
  handleSlotToggle,
  selectedTimezone,
  renderDateTime,
  t
}) => {
  const [currentDate, setCurrentDate] = useState(moment().tz(selectedTimezone));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDateSlots, setSelectedDateSlots] = useState([]);

  const prevMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, 'month').startOf('month'));
  };

  const nextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, 'month').startOf('month'));
  };

  const getMergedAndFilteredSlotsForDate = useCallback((date) => {
    const dateStr = date.format('YYYY-MM-DD');
  
    const otherStudentSlots = otherStudentBookings.flatMap(booking => 
      booking.slots.filter(slot => 
        moment.utc(`${booking.date}T${slot.start}`).tz(selectedTimezone).format('YYYY-MM-DD') === dateStr
      ).map(slot => ({
        start: moment.utc(`${booking.date}T${slot.start}`).format(),
        end: moment.utc(`${booking.date}T${slot.end}`).format(),
        status: 'OtherStudentBooking'
      }))
    );
  
    const bookedSlots = existingBookings.flatMap(booking => 
      booking.Dates.filter(d => d.date === dateStr)
        .flatMap(d => d.slots.map(slot => ({
          start: moment.utc(`${d.date}T${slot.start}`).format(),
          end: moment.utc(`${d.date}T${slot.end}`).format(),
          status: booking.Status,
          bookingId: booking._id
        })))
    );
  
    const availableSlots = availability.filter(slot => 
      moment.utc(slot.start).tz(selectedTimezone).format('YYYY-MM-DD') === dateStr
    ).map(slot => ({
      start: moment.utc(slot.start).format(),
      end: moment.utc(slot.end).format(),
      status: 'Available'
    }));
  
    const allSlots = [...otherStudentSlots, ...bookedSlots, ...availableSlots];
  
    allSlots.sort((a, b) => moment(a.start).diff(moment(b.start)));
  
    const mergedSlots = allSlots.reduce((acc, current) => {
      const overlap = acc.find(slot => 
        (moment(current.start).isBetween(slot.start, slot.end, null, '[]') ||
         moment(current.end).isBetween(slot.start, slot.end, null, '[]') ||
         (moment(current.start).isSameOrBefore(slot.start) && moment(current.end).isSameOrAfter(slot.end)))
      );
  
      if (!overlap) {
        acc.push(current);
      } else {
        const priority = ['Scheduled', 'OtherStudentBooking', 'Rescheduled', 'Available'];
        if (priority.indexOf(current.status) <= priority.indexOf(overlap.status)) {
          const index = acc.indexOf(overlap);
          acc[index] = current;
        }
      }
  
      return acc;
    }, []);
  
    return mergedSlots;
  }, [availability, existingBookings, otherStudentBookings, selectedTimezone]);

  const handleDateClick = (day) => {
    const clickedDate = currentDate.clone().date(day);
    setCurrentDate(clickedDate);
    
    const slotsForDate = getMergedAndFilteredSlotsForDate(clickedDate);
    
    setSelectedDateSlots(slotsForDate);
    setIsModalVisible(true);
  };

  const availableDates = useMemo(() => {
    const dates = new Set();
    [...availability, ...existingBookings.flatMap(b => b.Dates), ...otherStudentBookings].forEach(slot => {
      const date = moment.utc(slot.start || slot.date).tz(selectedTimezone).format('YYYY-MM-DD');
      dates.add(date);
    });
    return dates;
  }, [availability, existingBookings, otherStudentBookings, selectedTimezone]);

  const renderSlot = (slot) => {
    const isSelected = selectedSlots[`${slot.start}-${slot.end}`];
    const slotStyle = {
      ...styles.slot,
      backgroundColor: isSelected ? '#1890ff' : statusColors[slot.status] || statusColors.Available,
      color: slot.status === 'Available' ? '#000' : '#fff',
    };

    return (
      <div
        key={`${slot.start}-${slot.end}`}
        style={slotStyle}
        onClick={() => slot.status === 'Available' && handleSlotToggle(slot)}
      >
        <span style={{
          ...styles.statusIndicator,
          backgroundColor: statusColors[slot.status]
        }}></span>
        {renderDateTime(slot.start)} - {renderDateTime(slot.end)}
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>
          {slot.status === 'OtherStudentBooking' ? t('MobileCalendar.BookedByOther') : slot.status}
        </span>
      </div>
    );
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={styles.calendar}>
      <div style={styles.header}>
        <button onClick={prevMonth} style={styles.button}>&lt;</button>
        <h2 style={styles.monthYear}>
          {currentDate.format('MMMM YYYY')}
        </h2>
        <button onClick={nextMonth} style={styles.button}>&gt;</button>
      </div>
      <div style={styles.weekdays}>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} style={styles.weekday}>{day}</div>
        ))}
      </div>
      <div style={styles.days}>
        {[...Array(currentDate.startOf('month').day()).keys()].map((_, index) => (
          <div key={`empty-${index}`}></div>
        ))}
        {[...Array(currentDate.daysInMonth()).keys()].map((_, index) => {
          const day = index + 1;
          const date = currentDate.clone().date(day);
          const dateStr = date.format('YYYY-MM-DD');
          const hasSlots = availableDates.has(dateStr);
          const hasOtherStudentBooking = otherStudentBookings.some(booking => 
            booking.slots.some(slot => 
              moment.utc(slot.start).tz(selectedTimezone).format('YYYY-MM-DD') === dateStr
            )
          );
          
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              style={{
                ...styles.day,
                backgroundColor: hasOtherStudentBooking ? '#ffe6e6' : hasSlots ? '#e6ffe6' : 'transparent',
                color: currentDate.date() === day ? '#4CAF50' : 'inherit',
                fontWeight: currentDate.date() === day ? 'bold' : 'normal',
              }}
            >
              {day}
            </button>
          );
        })}
      </div>
      
      <Modal
        title={t('MobileCalendar.AvailableSlots')}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            {t('MobileCalendar.Close')}
          </Button>
        ]}
      >
        <div style={styles.slotContainer}>
          {selectedDateSlots.length > 0 ? (
            selectedDateSlots.map(renderSlot)
          ) : (
            <p>{t('MobileCalendar.NoSlotsAvailable')}</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

const AdminEditBooking = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { teacherId, studentId } = location.state || {};
  const navigate = useNavigate();
  const { BookingID } = useParams();
  const [t] = useTranslation("global");
  const [status, setStatus] = useState("Rescheduled");
  const [rawBookings, setRawBookings] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [otherStudentBookings, setOtherStudentBookings] = useState([]);
  const hourFormat = useMemo(() => localStorage.getItem('hourFormat') || '24', []);
  const selectedTimezone = useMemo(() => localStorage.getItem('selectedStudentTimezone') || 'America/New_York', []);

  const fetchBookings = useCallback(() => {
    if (studentId && teacherId) {
      Promise.all([
        dispatch(fetchExistingBookings({ 
          studentId: studentId,
          teacherId: teacherId,
          timestamp: Date.now()
        })),
        dispatch(fetchBookingsExcludingStudent({ 
          studentId: studentId, 
          teacherId: teacherId 
        }))
      ]).then(([existingBookingsResult, otherBookingsResult]) => {
        if (fetchExistingBookings.fulfilled.match(existingBookingsResult)) {
          const bookings = existingBookingsResult.payload?.bookings;
          console.log('Raw bookings data:', bookings);
          if (Array.isArray(bookings) && bookings.length > 0) {
            setRawBookings(bookings);
            const booking = bookings.find(b => b._id === BookingID);
            if (booking) {
              setCurrentBooking(booking);
            } else {
              toast.error(t("AdminEditBooking.BookingNotFound"));
            }
          } else if (Array.isArray(bookings) && bookings.length === 0) {
            console.log("No bookings found for this user and teacher.");
            setRawBookings([]);
          } else {
            console.error("Bookings data is not in the expected format:", bookings);
            setRawBookings([]);
            toast.error(t("AdminEditBooking.InvalidBookingsData"));
          }
        } else {
          console.error("Fetching bookings failed:", existingBookingsResult.error);
          setRawBookings([]);
          toast.error(t("AdminEditBooking.FetchBookingsError"));
        }

        if (fetchBookingsExcludingStudent.fulfilled.match(otherBookingsResult)) {
          setOtherStudentBookings(otherBookingsResult.payload);
        } else {
          console.error("Fetching other student bookings failed:", otherBookingsResult.error);
          toast.error(t("AdminEditBooking.FetchOtherBookingsError"));
        }
      }).catch((error) => {
        console.error("Error fetching bookings:", error);
        setRawBookings([]);
        setOtherStudentBookings([]);
        toast.error(t("AdminEditBooking.FetchBookingsError"));
      });
    } else {
      console.error('Student ID or Teacher ID not provided');
    }
  }, [dispatch, studentId, teacherId, BookingID, t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAllbookings());
    if (teacherId) {
      dispatch(GetExistingTeacherAvailability(teacherId)).then((result) => {
          if (GetExistingTeacherAvailability.fulfilled.match(result)) {
            const availableSlotsData = result.payload.AvailableTimeSlots;
            console.log('Available slots:', availableSlotsData);
            setAvailableSlots(availableSlotsData);
          } else {
            console.error("Fetching available slots failed:", result.error);
            toast.error(t("AdminEditBooking.FetchAvailableSlotsError"));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.error('Teacher ID not provided');
      toast.error(t("AdminEditBooking.TeacherIdMissing"));
      setIsLoading(false);
    }
    fetchBookings();
  }, [dispatch, teacherId, fetchBookings, t]);

  const renderDateTime = useCallback((dateTime) => {
    if (!dateTime) return '';
    const momentTime = dateTime.includes('T') 
      ? moment.utc(dateTime)
      : moment.utc(`1970-01-01T${dateTime}`);
    return hourFormat === '12' 
      ? momentTime.tz(selectedTimezone).format('YYYY-MM-DD hh:mm A')
      : momentTime.tz(selectedTimezone).format('YYYY-MM-DD HH:mm');
  }, [hourFormat, selectedTimezone]);

  const handleSlotToggle = useCallback((slot) => {
    setSelectedSlots(prevSlots => {
      const key = `${slot.start}-${slot.end}`;
      if (prevSlots[key]) {
        const { [key]: _, ...rest } = prevSlots;
        return rest;
      } else {
        return { ...prevSlots, [key]: slot };
      }
    });
  }, []);

  const handleSubmit = async () => {
    if (Object.keys(selectedSlots).length === 0) {
      toast.error(t("AdminEditBooking.SelectDateAndTime"));
      return;
    }
    
    const updatedData = {
      status: status,
      Scheduled_Dates: Object.values(selectedSlots).map(slot => ({
        [moment.utc(slot.start).format('YYYY-MM-DD')]: [
          {
            start: moment.utc(slot.start).format('HH:mm'),
            end: moment.utc(slot.end).format('HH:mm')
          }
        ]
      }))
    };
    
    try {
      await dispatch(Updatebooking({ BookingID, updatedData }));
      toast.success(t("AdminEditBooking.SuccessMessage"));
      fetchBookings();
      setIsModalVisible(false);
      setSelectedSlots({});
      navigate("/Admin-Dashboard/Bookings", { state: { refreshBookings: true } });
    } catch (error) {
      console.error("Error updating booking:", error);
      toast.error(t("AdminEditBooking.ErrorMessage"));
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <AdminNav />
      <div style={{ padding: '20px' }}>
        <Card title={t("AdminEditBooking.heading")} style={{ marginBottom: 20 }}>
          {currentBooking && (
            <div style={{ marginBottom: '20px' }}>
              <h3>{t("AdminEditBooking.CurrentBooking")}</h3>
              <p>{t("AdminEditBooking.CurrentDate")}: {renderDateTime(moment.utc(currentBooking.Dates[0].date).format())}</p>
              <p>{t("AdminEditBooking.CurrentTime")}: 
                {renderDateTime(`${currentBooking.Dates[0].date}T${currentBooking.Dates[0].slots[0].start}Z`)} - 
                {renderDateTime(`${currentBooking.Dates[0].date}T${currentBooking.Dates[0].slots[0].end}Z`)}
              </p>
              <p>{t("AdminEditBooking.CurrentStatus")}: {currentBooking.Status}</p>
            </div>
          )}
          <MobileCalendar
            selectedTeacherId={teacherId}
            availability={availableSlots}
            existingBookings={rawBookings}
            otherStudentBookings={otherStudentBookings}
            selectedSlots={selectedSlots}
            handleSlotToggle={handleSlotToggle}
            selectedTimezone={selectedTimezone}
            renderDateTime={renderDateTime}
            t={t}
          />
          <Button 
            type="primary" 
            onClick={() => setIsModalVisible(true)} 
            disabled={Object.keys(selectedSlots).length === 0}
            style={{ marginTop: '20px' }}
          >
            {t("AdminEditBooking.RescheduleBooking")}
          </Button>
        </Card>
        <Modal
          title={t("AdminEditBooking.ConfirmRescheduling")}
          visible={isModalVisible}
          onOk={handleSubmit}
          onCancel={() => setIsModalVisible(false)}
          okText={t("AdminEditBooking.ConfirmRescheduling")}
          cancelText={t("AdminEditBooking.Cancel")}
        >
          <p>{t("AdminEditBooking.SelectedSlots")}:</p>
          {Object.values(selectedSlots).map((slot, index) => (
            <p key={index}>
              {renderDateTime(slot.start)} - {renderDateTime(slot.end)}
            </p>
          ))}
        </Modal>
      </div>
    </>
  );
};

export default AdminEditBooking;