import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DirectLinkPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isModerator } = location.state || {};
  const [copied, setCopied] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);

  const bookings = useSelector((state) => state.bookings.Allbookinglist);
  const filteredBooking = bookings.find((booking) => booking._id === id);

  useEffect(() => {
    if (!filteredBooking) {
      navigate('/');
    }
  }, [filteredBooking, navigate]);

  const getDirectLink = () => {
    return `https://meet.speakable.online/${id}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(getDirectLink()).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const joinMeeting = () => {
    window.open(getDirectLink(), '_blank');
  };

  // Temporary: Function to toggle test mode
  const toggleTestMode = () => {
    setIsTestMode(prev => !prev);
  };

  if (!filteredBooking) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1>Unable to join the meeting automatically</h1>
      <p>Please use the direct link below to join the meeting:</p>
      <div style={{ 
        marginTop: '20px', 
        padding: '10px', 
        border: '1px solid #ccc', 
        borderRadius: '5px',
        backgroundColor: '#f0f0f0'
      }}>
        <a href={getDirectLink()} target="_blank" rel="noopener noreferrer">{getDirectLink()}</a>
      </div>
      <button onClick={copyToClipboard} style={{ marginTop: '10px', padding: '5px 10px' }}>
        {copied ? 'Copied!' : 'Copy Link'}
      </button>
      <button onClick={joinMeeting} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
        Join Meeting
      </button>
      {isModerator && (
        <div style={{ marginTop: '20px', fontWeight: 'bold' }}>
          You are joining as a Teacher (Moderator)
        </div>
      )}
      {!isModerator && (
        <div style={{ marginTop: '20px', fontWeight: 'bold' }}>
          You are joining as a Student (Participant)
        </div>
      )}
      
      {/* Temporary: Test mode toggle button */}
      <button onClick={toggleTestMode} style={{ marginTop: '20px', background: isTestMode ? 'yellow' : 'white' }}>
        {isTestMode ? 'Disable Test Mode' : 'Enable Test Mode'}
      </button>
      
      {/* Temporary: Display test information */}
      {isTestMode && (
        <div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px' }}>
          <h3>Test Information:</h3>
          <p>Meeting ID: {id}</p>
          <p>Is Moderator: {isModerator ? 'Yes' : 'No'}</p>
          <p>Booking Details: {JSON.stringify(filteredBooking)}</p>
        </div>
      )}
    </div>
  );
};

export default DirectLinkPage;