import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CookieConsent = ({ onAccept }) => {
  const [showConsent, setShowConsent] = useState(false);
  const { t } = useTranslation('global');

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowConsent(false);
    onAccept();
  };

  const handleDecline = () => {
    setShowConsent(false);
    // You might want to handle declined consent differently
  };

  if (!showConsent) return null;

  return (
    <div className="cookie-consent-overlay">
      <div className="cookie-consent-modal">
        <h2>{t('cookieConsent.title')}</h2>
        <p>{t('cookieConsent.message')}</p>
        <div className="cookie-consent-buttons">
          <button onClick={handleAccept}>{t('cookieConsent.accept')}</button>
          <button onClick={handleDecline}>{t('cookieConsent.decline')}</button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;