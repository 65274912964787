import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useWebSocket } from '../hooks/useWebSocket';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  Badge,
  InputAdornment,
  Alert,
  Snackbar,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Close as CloseIcon,
  Send as SendIcon,
  Chat as ChatIcon,
  Search as SearchIcon,
  MinimizeRounded as MinimizeIcon,
  EmojiEmotions as EmojiIcon,
  AttachFile as AttachFileIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

const ChatPopup = ({ currentUser, availableUsers }) => {
    const { socket, isConnected, error, isInitializing } = useWebSocket(currentUser.id);
    const [isOpen, setIsOpen] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [unreadCounts, setUnreadCounts] = useState({});
    const [isTyping, setIsTyping] = useState({});
    const [notification, setNotification] = useState(null);
    const messagesEndRef = useRef(null);
    const typingTimeoutRef = useRef({});
    const [userFilter, setUserFilter] = useState('all');
    const [displayedUsers, setDisplayedUsers] = useState([]);

    const scrollToBottom = useCallback(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const canChatWith = useCallback((targetUser) => {
        if (!currentUser || !targetUser) return false;

        switch (currentUser.type) {
            case 'admin':
                // Admin can chat with everyone
                return true;
            case 'student':
                // Students can only chat with teachers and admins
                return ['teacher', 'admin'].includes(targetUser.type);
            case 'teacher':
                // Teachers can chat with students and admins
                return ['student', 'admin'].includes(targetUser.type);
            default:
                return false;
        }
    }, [currentUser]);

    const getAvailableTabs = useCallback(() => {
        switch (currentUser.type) {
            case 'admin':
                return [
                    { value: 'all', label: 'All' },
                    { value: 'student', label: 'Students' },
                    { value: 'teacher', label: 'Teachers' }
                ];
            case 'student':
                return [
                    { value: 'all', label: 'All' },
                    { value: 'teacher', label: 'Teachers' }
                ];
            case 'teacher':
                return [
                    { value: 'all', label: 'All' },
                    { value: 'student', label: 'Students' }
                ];
            default:
                return [{ value: 'all', label: 'All' }];
        }
    }, [currentUser.type]);

    useEffect(() => {
        let filtered = users.filter(user => canChatWith(user));
        
        // Apply type filter if not 'all'
        if (userFilter !== 'all') {
            filtered = filtered.filter(user => user.type === userFilter);
        }

        // Apply search filter
        if (searchQuery) {
            filtered = filtered.filter(user => 
                user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setDisplayedUsers(filtered);
    }, [users, userFilter, searchQuery, canChatWith]);

    // Initialize users from availableUsers
    useEffect(() => {
        setUsers(availableUsers.map(user => ({
            ...user,
            online: false // Initially set all users as offline
        })));
    }, [availableUsers]);

    // Handle incoming socket events
    useEffect(() => {
        if (isConnected && socket && currentUser?.id) {
            // Message handler
            socket.on('message', (data) => {
                if (!data?.from) return;

                setMessages(prev => ({
                    ...prev,
                    [data.from]: [...(prev[data.from] || []), data]
                }));

                if (activeChat?.id !== data.from) {
                    setUnreadCounts(prev => ({
                        ...prev,
                        [data.from]: (prev[data.from] || 0) + 1
                    }));

                    setNotification({
                        type: 'info',
                        message: `New message from ${data.fromUser?.username || 'Unknown User'}`
                    });
                }
                scrollToBottom();
            });

            // Typing status handler
            socket.on('userTyping', ({ from, isTyping: typing }) => {
                if (!from) return;
                setIsTyping(prev => ({ ...prev, [from]: typing }));
            });

            // Online status handlers
            socket.on('userOnline', (data) => {
                if (!data?.userId) return;
                setUsers(prev => prev.map(user => 
                    user.id === data.userId ? { ...user, online: true } : user
                ));
            });

            socket.on('userOffline', (data) => {
                if (!data?.userId) return;
                setUsers(prev => prev.map(user => 
                    user.id === data.userId ? { ...user, online: false } : user
                ));
            });

            // Get initial online users
            socket.emit('getOnlineUsers');

            return () => {
                socket.off('message');
                socket.off('userTyping');
                socket.off('userOnline');
                socket.off('userOffline');
            };
        }
    }, [socket, isConnected, activeChat, currentUser?.id, scrollToBottom]);

    const handleSendMessage = useCallback(() => {
        if (!newMessage?.trim() || !activeChat?.id || !socket || !currentUser?.id) return;

        const messageData = {
            to: activeChat.id,
            content: newMessage.trim(),
            timestamp: new Date().toISOString()
        };

        socket.emit('message', messageData);

        // Optimistically add message
        setMessages(prev => ({
            ...prev,
            [activeChat.id]: [...(prev[activeChat.id] || []), {
                content: newMessage,
                from: currentUser.id,
                timestamp: messageData.timestamp
            }]
        }));

        setNewMessage('');
        scrollToBottom();
    }, [newMessage, activeChat?.id, socket, currentUser?.id, scrollToBottom]);

    const handleTyping = useCallback(() => {
        if (!activeChat?.id || !socket) return;

        socket.emit('typing', {
            to: activeChat.id,
            isTyping: true
        });

        if (typingTimeoutRef.current[activeChat.id]) {
            clearTimeout(typingTimeoutRef.current[activeChat.id]);
        }

        typingTimeoutRef.current[activeChat.id] = setTimeout(() => {
            socket.emit('typing', {
                to: activeChat.id,
                isTyping: false
            });
        }, 2000);
    }, [activeChat?.id, socket]);

    const handleChatSelect = useCallback((user) => {
        if (!user?.id) return;
        
        setActiveChat(user);
        setUnreadCounts(prev => ({ ...prev, [user.id]: 0 }));
        
        // Request chat history
        if (socket) {
            socket.emit('getChatHistory', { userId: user.id });
        }
    }, [socket]);

    // Filter users
    useEffect(() => {
        let filtered = [...users];
        
        // Filter by type
        if (userFilter !== 'all') {
            filtered = filtered.filter(user => user.type === userFilter);
        }

        // Filter by search
        if (searchQuery) {
            filtered = filtered.filter(user => 
                user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setDisplayedUsers(filtered);
    }, [users, userFilter, searchQuery]);

    const formatTime = useCallback((timestamp) => {
        if (!timestamp) return '';
        try {
            return new Date(timestamp).toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
            });
        } catch (error) {
            return '';
        }
    }, []);

    return (
        <>
            {/* Chat Toggle Button */}
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': { bgcolor: 'primary.dark' },
                    zIndex: 1000,
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <Badge 
                    badgeContent={Object.values(unreadCounts).reduce((a, b) => a + b, 0)} 
                    color="error"
                >
                    <ChatIcon />
                </Badge>
            </IconButton>

            {/* Notifications */}
            <Snackbar
                open={!!notification}
                autoHideDuration={3000}
                onClose={() => setNotification(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert 
                    onClose={() => setNotification(null)} 
                    severity={notification?.type || 'info'}
                    sx={{ width: '100%' }}
                >
                    {notification?.message}
                </Alert>
            </Snackbar>

            {/* Chat Window */}
            {isOpen && (
                <Paper
                    sx={{
                        position: 'fixed',
                        bottom: minimized ? '80px' : '20px',
                        right: '20px',
                        width: '330px',
                        height: minimized ? 'auto' : '480px',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 1000,
                        boxShadow: 3,
                        borderRadius: 2,
                        overflow: 'hidden',
                    }}
                >
                    {/* Header */}
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: 'primary.main',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box display="flex" alignItems="center" gap={1}>
                            {activeChat && (
                                <IconButton 
                                    size="small" 
                                    color="inherit" 
                                    onClick={() => setActiveChat(null)}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <Typography variant="h6">
                                {activeChat ? activeChat.name : 'Messages'}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton 
                                size="small" 
                                color="inherit" 
                                onClick={() => setMinimized(!minimized)}
                            >
                                <MinimizeIcon />
                            </IconButton>
                            <IconButton 
                                size="small" 
                                color="inherit" 
                                onClick={() => setIsOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {!minimized && (
                        <>
                            {/* Connection Status */}
                            {!isConnected && (
                                <Alert severity="warning" sx={{ borderRadius: 0 }}>
                                    {isInitializing ? 'Connecting...' : 'Disconnected'}
                                </Alert>
                            )}

                            {/* Main Content */}
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden',
                                }}
                            >
                                {!activeChat ? (
                                    // Users List View
                                    <>
                                        <Tabs
                                            value={userFilter}
                                            onChange={(_, newValue) => setUserFilter(newValue)}
                                            variant="fullWidth"
                                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                                        >
                                            {getAvailableTabs().map((tab) => (
                                                <Tab 
                                                    key={tab.value} 
                                                    label={tab.label} 
                                                    value={tab.value} 
                                                />
                                            ))}
                                        </Tabs>

                                        <Box sx={{ p: 1 }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                placeholder="Search users..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>

                                        <List sx={{ flex: 1, overflow: 'auto' }}>
                                            {displayedUsers.map((user) => (
                                                <ListItem
                                                    key={user.id}
                                                    button
                                                    onClick={() => handleChatSelect(user)}
                                                >
                                                    <ListItemAvatar>
                                                        <Badge
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            variant="dot"
                                                            color={user.online ? 'success' : 'error'}
                                                        >
                                                            <Avatar src={user.avatar}>
                                                                {user.name?.[0] || '?'}
                                                            </Avatar>
                                                        </Badge>
                                                    </ListItemAvatar>
                                                    <ListItemText 
                                                        primary={user.name}
                                                        secondary={
                                                            <>
                                                                {user.email}
                                                                <Typography 
                                                                    component="span" 
                                                                    variant="caption" 
                                                                    sx={{ ml: 1, color: 'text.secondary' }}
                                                                >
                                                                    ({user.type})
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                    {unreadCounts[user.id] > 0 && (
                                                        <Badge badgeContent={unreadCounts[user.id]} color="error" />
                                                    )}
                                                </ListItem>
                                            ))}
                                            {displayedUsers.length === 0 && (
                                                <Box p={2} textAlign="center">
                                                    <Typography color="text.secondary">
                                                        No users found
                                                    </Typography>
                                                </Box>
                                            )}
                                        </List>
                                    </>
                                ) : (
                                    // Chat View
                                    <>
                                        {/* Messages Container */}
                                        <Box
                                            sx={{
                                                flex: 1,
                                                overflow: 'auto',
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1,
                                            }}
                                        >
                                            {messages[activeChat.id]?.map((message, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: message.from === currentUser.id ? 'flex-end' : 'flex-start',
                                                        gap: 1,
                                                    }}
                                                >
                                                    {message.from !== currentUser.id && (
                                                        <Avatar sx={{ width: 32, height: 32 }}>
                                                            {activeChat.name[0]}
                                                        </Avatar>
                                                    )}
                                                    <Box
                                                        sx={{
                                                            maxWidth: '70%',
                                                            p: 1.5,
                                                            borderRadius: 2,
                                                            bgcolor: message.from === currentUser.id ? 'primary.main' : 'grey.100',
                                                            color: message.from === currentUser.id ? 'white' : 'text.primary',
                                                        }}
                                                    >
                                                        <Typography variant="body2">
                                                            {message.content}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                display: 'block',
                                                                textAlign: 'right',
                                                                mt: 0.5,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            {formatTime(message.timestamp)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ))}
                                            {isTyping[activeChat.id] && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Avatar sx={{ width: 32, height: 32 }}>
                                                        {activeChat.name[0]}
                                                    </Avatar>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                                                    >
                                                        typing...
                                                    </Typography>
                                                </Box>
                                            )}
                                            <div ref={messagesEndRef} />
                                        </Box>

                                        {/* Message Input */}
                                        <Box
                                            sx={{
                                                p: 2,
                                                borderTop: 1,
                                                borderColor: 'divider',
                                                bgcolor: 'background.paper',
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                size="small"
                                                placeholder="Type a message..."
                                                value={newMessage}
                                                onChange={(e) => {
                                                    setNewMessage(e.target.value);
                                                    handleTyping();
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        handleSendMessage();
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                size="small"
                                                                disabled={!newMessage.trim()}
                                                                onClick={handleSendMessage}
                                                                color="primary"
                                                            >
                                                                <SendIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton size="small" color="primary">
                                                                <EmojiIcon />
                                                            </IconButton>
                                                            <IconButton size="small" color="primary">
                                                                <AttachFileIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                multiline
                                                maxRows={4}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                </Paper>
            )}
        </>
    );
};

ChatPopup.propTypes = {
    currentUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['admin', 'student', 'teacher']).isRequired,
    }).isRequired,
    availableUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string,
            type: PropTypes.oneOf(['student', 'teacher', 'admin']).isRequired,
            avatar: PropTypes.string,
        })
    ).isRequired,
};

ChatPopup.defaultProps = {
    availableUsers: []
};

export default ChatPopup;