import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const Calendar = ({ 
  availability, 
  existingBookings = [], 
  selectedSlots, 
  handleSlotToggle, 
  handleWeekSelection, 
  selectedTimezone,
  freeHours,
  usedHours
}) => {
  const calendarRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation("global");

  // Console log existing bookings and availability before processing
  console.log('Existing bookings before processing:', existingBookings);
  console.log('Availability before processing:', availability);

  const statusColors = {
    Scheduled: '#5C7153',
    Cancelled: '#F44336',
    Rescheduled: '#FFC107',
    Completed: '#2196F3',
    Available: '#4CAF50',
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.changeView(windowWidth < 768 ? 'dayGridWeek' : 'dayGridMonth');
    }
  }, [windowWidth]);

  const formatTime = (date) => moment.tz(date, selectedTimezone).format('HH:mm');

  const renderEventContent = useCallback((eventInfo) => {
    const status = eventInfo.event.extendedProps.status;
    const isBooked = status !== 'Available';
    const isSelected = selectedSlots.hasOwnProperty(eventInfo.event.id);
    const isFreeHour = isSelected && usedHours < freeHours;
  
    const handleToggle = (e) => {
      e.stopPropagation();
      if (!isBooked) handleSlotToggle(eventInfo.event);
    };

    const startTime = moment.tz(eventInfo.event.start, selectedTimezone);
    const endTime = moment.tz(eventInfo.event.end, selectedTimezone);

    return (
      <div 
        className={`fc-content ${status.toLowerCase()}-slot ${isFreeHour ? 'free-hour-slot' : ''}`}
        style={{ 
          backgroundColor: statusColors[status] || statusColors.Scheduled,
          color: '#ffffff',
          padding: '4px 6px',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2px' }}>
          <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
            {startTime.format('HH:mm')} - {endTime.format('HH:mm')}
          </span>
          {!isBooked && (
            <input
              type="checkbox"
              checked={isSelected}
              onChange={handleToggle}
              style={{ marginLeft: '4px' }}
            />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontSize: '0.8em', opacity: 0.9 }}>{status}</span>
          {isBooked && (
            <span style={{ 
              fontSize: '0.7em', 
              padding: '2px 4px', 
              backgroundColor: 'rgba(255,255,255,0.2)', 
              borderRadius: '3px' 
            }}>
              {status}
            </span>
          )}
        </div>
      </div>
    );
  }, [selectedSlots, handleSlotToggle, freeHours, usedHours, statusColors, selectedTimezone]);

  const events = useMemo(() => {
    const convertToTimezone = (date) => moment.tz(date, 'UTC').tz(selectedTimezone);

    const bookedSlots = existingBookings.map(booking => ({
      id: booking.id,
      start: convertToTimezone(booking.start).toDate(),
      end: convertToTimezone(booking.end).toDate(),
      title: booking.title,
      backgroundColor: statusColors[booking.extendedProps.status] || statusColors.Scheduled,
      borderColor: statusColors[booking.extendedProps.status] || statusColors.Scheduled,
      textColor: '#ffffff',
      extendedProps: { status: booking.extendedProps.status },
      display: 'block'
    }));

    const availableSlots = availability.map(slot => ({
      id: slot.id,
      start: convertToTimezone(slot.start).toDate(),
      end: convertToTimezone(slot.end).toDate(),
      title: "Available",
      backgroundColor: statusColors.Available,
      borderColor: statusColors.Available,
      textColor: '#ffffff',
      extendedProps: { status: "Available" },
      display: 'block'
    }));

    let mergedSlots = [...availableSlots];
    bookedSlots.forEach(bookedSlot => {
      const index = mergedSlots.findIndex(slot => 
        moment(slot.start).isSame(bookedSlot.start) && 
        moment(slot.end).isSame(bookedSlot.end)
      );
      if (index !== -1) {
        mergedSlots[index] = bookedSlot;
      } else {
        mergedSlots.push(bookedSlot);
      }
    });

    // Log the processed events for debugging
    console.log('Processed events for calendar with timezone conversion:');
mergedSlots.forEach(slot => {
  const startMoment = moment(slot.start);
  const endMoment = moment(slot.end);
  console.log(`ID: ${slot.id}
    Start Date: ${startMoment.format('YYYY-MM-DD')}
    Start Time: ${startMoment.format('HH:mm:ss')}
    End Date: ${endMoment.format('YYYY-MM-DD')}
    End Time: ${endMoment.format('HH:mm:ss')}
    Title: ${slot.title}
    Timezone: ${startMoment.format('Z')} (${startMoment.format('z')})`
  );
});

    return mergedSlots;
  }, [existingBookings, availability, statusColors, selectedTimezone]);

  return (
    <div className="calendar-container">
      <style>{`
        .fc-event {
          cursor: pointer;
        }
        .fc-event-main {
          overflow: visible !important;
        }
        .scheduled-slot, .cancelled-slot, .rescheduled-slot, .completed-slot {
          z-index: 2;
        }
        .available-slot {
          z-index: 1;
        }
        .fc-daygrid-event {
          white-space: normal !important;
          align-items: normal !important;
          margin-bottom: 2px;
        }
        .fc-daygrid-block-event .fc-event-time {
          font-weight: bold;
        }
        .fc-daygrid-day-events {
          margin-bottom: 1px;
        }
      `}</style>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={windowWidth < 768 ? 'dayGridWeek' : 'dayGridMonth'}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: windowWidth < 768 ? 'dayGridWeek,dayGridMonth' : ''
        }}
        events={events}
        eventContent={renderEventContent}
        height="auto"
        weekNumbers={windowWidth >= 768}
        weekNumberContent={(arg) => (
          <Button 
            onClick={() => handleWeekSelection(arg.date, moment(arg.date).endOf('week').toDate())}
            size="small"
          >
            {t("Calendar.week")} {arg.num}
          </Button>
        )}
        selectable={true}
        timeZone={selectedTimezone}
      />
      <div className="calendar-communication">
        <p>{t("Calendar.lessonLengthNote")}</p>
      </div>
    </div>
  );
};

export default Calendar;