import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

export const generateMeetingToken = createAsyncThunk(
    "meeting/generateToken",
    async (roomId, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token'); // or however you store your auth token
        console.log('Auth token being sent:', token);
  
        const response = await axios.post('/generate-meeting-token', 
          { roomId },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        console.log('Meeting token response:', response.data);
        return response.data.token;
      } catch (error) {
        console.error('Error in generateMeetingToken:', error.response?.data || error.message);
        return rejectWithValue(error.response?.data || 'Failed to generate meeting token');
      }
    }
  );