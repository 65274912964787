import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { FaTimes, FaClock, FaMoneyBillWave, FaShoppingCart, FaPercent } from 'react-icons/fa';

const PurchasePopup = ({ 
  pack, 
  onClose, 
  onPurchase, 
  availableHours, 
  convertPrice, 
  calculateDiscountedPrice
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const totalHours = pack.Number_of_Lectures;
  const remainingHours = totalHours - availableHours;
  const pricePerHour = pack.Package_Amount / pack.Number_of_Lectures;
  const fullPrice = pack.Package_Amount;

  const [selectedHours, setSelectedHours] = useState(null);
  const [price, setPrice] = useState(0);
  const [isFullPackage, setIsFullPackage] = useState(false);

  useEffect(() => {
    if (selectedHours) {
      const isFullPack = selectedHours.value === remainingHours;
      setIsFullPackage(isFullPack);
      const newPrice = calculateDiscountedPrice(pack, selectedHours.value);
      setPrice(newPrice);
    }
  }, [selectedHours, pack, calculateDiscountedPrice, remainingHours]);

  const handlePurchase = () => {
    onPurchase(pack._id, selectedHours.value, isFullPackage ? 'full' : 'partial', selectedHours.value);
    navigate(`/Student-dashboard/CheckDetails/${pack._id}`, {
      state: {
        packageName: pack.Package_Name,
        hours: selectedHours.value,
        totalAmount: price 
      }
    });
  };

  const generateHourOptions = () => {
    const options = [];
    for (let i = 5; i <= remainingHours; i += 5) {
      options.push({ value: i, label: `${i} ${t("StudentWallet.Hours")}` });
    }
    if (options.length === 0 || options[options.length - 1].value !== remainingHours) {
      options.push({ value: remainingHours, label: `${remainingHours} ${t("StudentWallet.Hours")}` });
    }
    return options;
  };

  const hourOptions = generateHourOptions();

  useEffect(() => {
    if (hourOptions.length > 0) {
      setSelectedHours(hourOptions[0]);
    }
  }, []);

  const handleHourSelection = (selectedOption) => {
    setSelectedHours(selectedOption);
  };

  const calculateDiscount = () => {
    if (!isFullPackage) return 0;
    const fullPriceForSelected = selectedHours.value * pricePerHour;
    return ((fullPriceForSelected - price) / fullPriceForSelected) * 100;
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2 className="popup-title">{t("StudentWallet.ChoosePaymentOption")}</h2>
          <button onClick={onClose} className="popup-close-button">
            <FaTimes />
          </button>
        </div>

        <div className="popup-info-box">
          <span className="popup-info-label">
            <FaClock className="mr-2" />
            {t("StudentWallet.AvailableHours")}
          </span>
          <span className="popup-info-value">{availableHours}</span>
        </div>

        <div className="popup-info-box">
          <span className="popup-info-label">
            <FaClock className="mr-2" />
            {t("StudentWallet.TotalPackageHours")}
          </span>
          <span className="popup-info-value">{totalHours}</span>
        </div>

        <div className="popup-info-box">
          <span className="popup-info-label">
            <FaClock className="mr-2" />
            {t("StudentWallet.RemainingHours")}
          </span>
          <span className="popup-info-value">{remainingHours}</span>
        </div>

        <div className="popup-info-box">
          <span className="popup-info-label">
            <FaMoneyBillWave className="mr-2" />
            {t("StudentWallet.FullPackagePrice")}
          </span>
          <span className="popup-info-value">{convertPrice(fullPrice)}</span>
        </div>

        {remainingHours > 0 ? (
          <>
            <label htmlFor="hours" className="popup-select-label">
              {t("StudentWallet.ChooseHours")}:
            </label>
            <Select
              id="hours"
              value={selectedHours}
              onChange={handleHourSelection}
              options={hourOptions}
              isSearchable
              className="react-select-container"
              classNamePrefix="react-select"
            />

            {selectedHours && (
              <>
                <div className="popup-selected-payment">
                  {t("StudentWallet.SelectedPayment")}: 
                  <span className="font-bold ml-1">
                    {selectedHours.value} {t("StudentWallet.Hours")}
                  </span>
                </div>

                <div className="popup-price-breakdown">
                  {isFullPackage ? (
                    <>
                      <div className="popup-original-price">
                        {t("StudentWallet.OriginalPrice")}: {convertPrice(selectedHours.value * pricePerHour)}
                      </div>
                      <div className="popup-discounted-price">
                        {t("StudentWallet.DiscountedPrice")}: {convertPrice(price)}
                      </div>
                      <div className="popup-discount-percentage">
                        <FaPercent className="mr-2" />
                        {t("StudentWallet.Discount")}: {calculateDiscount().toFixed(2)}%
                      </div>
                    </>
                  ) : (
                    <div className="popup-regular-price">
                      {t("StudentWallet.Price")}: {convertPrice(price)}
                    </div>
                  )}
                </div>

                <button
                  onClick={handlePurchase}
                  className="popup-purchase-button"
                >
                  <FaShoppingCart className="mr-2" />
                  {t("StudentWallet.PaySelected")}
                </button>
              </>
            )}
          </>
        ) : (
          <p className="popup-no-hours">{t("StudentWallet.NoMoreHoursAvailable")}</p>
        )}
      </div>
    </div>
  );
};

export default PurchasePopup;