import React, { useEffect } from 'react';
import { Modal, Button, Upload, List, message } from 'antd';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { uploadToLocal, listLocalFiles, deleteLocalFile, downloadLocalFile } from '../../store/actions/teachersActions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const YourMaterialModal2 = ({ isVisible, onClose, bookingId }) => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const { localFiles, fileLoading, fileError, downloadLoading, downloadError } = useSelector(state => {
    console.log("Redux state:", state);
    return state.teachers || {};
  });

  useEffect(() => {
    if (isVisible && bookingId) {
      console.log("Fetching files for bookingId:", bookingId);
      dispatch(listLocalFiles(bookingId));
    }
  }, [isVisible, bookingId, dispatch]);

  useEffect(() => {
    console.log("Local files updated:", localFiles);
  }, [localFiles]);

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append('bookingId', bookingId);
    formData.append('file', file);

    try {
      await dispatch(uploadToLocal({ formData, bookingId }));
      message.success(t('YourMaterialModal2.fileUploadSuccess', { fileName: file.name }));
      if (onSuccess) onSuccess();
      dispatch(listLocalFiles(bookingId));
    } catch (error) {
      console.error('File upload failed:', error);
      message.error(t('YourMaterialModal2.fileUploadFailed', { fileName: file.name }));
      if (onError) onError(error);
    }
  };

  const handleDelete = async (fileId, fileName) => {
    try {
      await dispatch(deleteLocalFile({ bookingId, fileName }));
      message.success(t('YourMaterialModal2.fileDeleteSuccess'));
      dispatch(listLocalFiles(bookingId));
    } catch (error) {
      console.error('File deletion failed:', error);
      message.error(t('YourMaterialModal2.fileDeleteFailed'));
    }
  };

  const handleDownload = async (fileName) => {
    try {
      await dispatch(downloadLocalFile({ bookingId, fileName }));
      // The actual download will be handled by the action creator
    } catch (error) {
      console.error('File download failed:', error);
      message.error(t('YourMaterialModal2.fileDownloadFailed'));
    }
  };

  return (
    <Modal
      title={t("YourMaterialModal2.title")}
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('YourMaterialModal2.close')}
        </Button>,
      ]}
    >
      {fileError && <p style={{ color: 'red' }}>{t('YourMaterialModal2.error')}: {fileError}</p>}
      {downloadError && <p style={{ color: 'red' }}>{t('YourMaterialModal2.downloadError')}: {downloadError}</p>}
      
      {fileLoading || downloadLoading ? (
        <p>{t('YourMaterialModal2.loading')}</p>
      ) : localFiles && localFiles.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={localFiles}
          renderItem={(file) => (
            <List.Item
              actions={[
                <Button 
                  key="download" 
                  type="text" 
                  icon={<DownloadOutlined />} 
                  onClick={() => handleDownload(file.name)}
                  loading={downloadLoading}
                >
                  {t('YourMaterialModal2.download')}
                </Button>,
              ]}
            >
              <List.Item.Meta 
                title={file.originalName || t('YourMaterialModal2.unnamedFile')}
                description={file.createdAt ? moment(file.createdAt).format('MMMM D, YYYY, h:mm A') : t('YourMaterialModal2.dateNotAvailable')}
              />
            </List.Item>
          )}
        />
      ) : (
        <p>{t('YourMaterialModal2.noFilesAvailable')}</p>
      )}
    </Modal>
  );
};

export default YourMaterialModal2;