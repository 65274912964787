import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminNav from "../AdminNav";
import { notification, Select, Button, Checkbox, TimePicker, Modal, Row, Col, Typography, Divider, Switch } from "antd";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../Calendar.css";
import { updateTeacher, fetchTeacherDetails } from "../../../store/actions/teachersActions";
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { 
  addDays, 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek,
  endOfWeek,
  addMonths,
  isSameMonth,
  isSameDay,
  parseISO
} from 'date-fns';
import "./AdminEditTeacherEditAvailability.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;
const { Option } = Select;

const timeZones = moment.tz.names();
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const CustomDay = ({ day, selectedDates }) => {
  const dateStr = format(day, 'yyyy-MM-dd');
  const isSelected = selectedDates[dateStr];

  return (
    <div className={`custom-day ${isSelected ? 'selected' : ''}`}>
      {format(day, 'd')}
    </div>
  );
};

const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth }) => {
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;

  const changeMonth = (amount) => {
    setCurrentMonth(addMonths(currentMonth, amount));
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      const dateStr = format(cloneDay, 'yyyy-MM-dd');
      days.push(
        <div
          className={`calendar-day ${
            !isSameMonth(day, firstDayOfMonth) ? "disabled" :
            isSameDay(day, new Date()) ? "today" : ""
          } ${selectedDates[dateStr] ? "selected" : ""}`}
          key={day}
          onClick={() => onDateToggle(dateStr)}
        >
          <span>{format(day, dateFormat)}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="mini-calendar-container">
      <div className="calendar-header">
        <button onClick={() => changeMonth(-1)}>&lt;</button>
        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
        <button onClick={() => changeMonth(1)}>&gt;</button>
      </div>
      <div className="calendar-days">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-header" key={day}>{day}</div>
        ))}
      </div>
      <div className="calendar-body">
        {rows}
      </div>
    </div>
  );
};

const AdminEditTeacherEditAvailability = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const teacherData = useSelector(state => state.teachers.currentTeacher);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [userTimezone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [hourFormat, setHourFormat] = useState('24');
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isLongRangeModalVisible, setIsLongRangeModalVisible] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });

  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  const [availability, setAvailability] = useState(() => {
    return daysOfWeek.reduce((acc, day) => {
      acc[day] = { 
        enabled: false, 
        slots: [],
        isEditing: false 
      };
      return acc;
    }, {});
  });

  useEffect(() => {
    dispatch(fetchTeacherDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (teacherData) {
      console.log("Received teacherData:", teacherData);
      
      const newTimezone = teacherData.userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      setUserTimezone(newTimezone);
      setHourFormat(teacherData.hourFormat || '24');
      setMinDate(new Date());
      
      if (teacherData.Availability && teacherData.Availability.slots) {
        const newSelectedDates = {};
        const newAvailability = daysOfWeek.reduce((acc, day) => {
          acc[day] = { 
            enabled: false, 
            slots: new Set(), // Initialize as Set
            isEditing: false 
          };
          return acc;
        }, {});
        
        teacherData.Availability.slots.forEach(slot => {
          const startDate = dayjs.utc(slot.start).tz(newTimezone);
          const endDate = dayjs.utc(slot.end).tz(newTimezone);
          const dateStr = startDate.format('YYYY-MM-DD');
          const dayOfWeek = startDate.format('dddd');
          
          newSelectedDates[dateStr] = true;
          
          newAvailability[dayOfWeek].enabled = true;
          
          // Add the slot to the Set
          newAvailability[dayOfWeek].slots.add(`${startDate.format('HH:mm')}-${endDate.format('HH:mm')}`);
        });
        
        // Convert Sets back to arrays for easier rendering
        Object.keys(newAvailability).forEach(day => {
          newAvailability[day].slots = Array.from(newAvailability[day].slots).map(slotStr => {
            const [fromTime, toTime] = slotStr.split('-');
            return {
              id: uuidv4(),
              fromTime: dayjs(fromTime, 'HH:mm').tz(newTimezone),
              toTime: dayjs(toTime, 'HH:mm').tz(newTimezone)
            };
          });
        });
        
        setSelectedDates(newSelectedDates);
        setAvailability(newAvailability);


        const dateKeys = Object.keys(newSelectedDates);
        if (dateKeys.length > 0) {
          setState({
            selection: {
              startDate: parseISO(dateKeys[0]),
              endDate: parseISO(dateKeys[dateKeys.length - 1]),
              key: 'selection'
            }
          });
        }
      }
      
      if (teacherData.selectedDays) {
        setSelectedDays(teacherData.selectedDays);
      }
    }
  }, [teacherData]);

  const handleTimezoneChange = (newTimezone) => {
    setUserTimezone(newTimezone);
    setAvailability(prev => {
      const updatedAvailability = { ...prev };
      Object.keys(updatedAvailability).forEach(day => {
        updatedAvailability[day].slots = updatedAvailability[day].slots.map(slot => ({
          ...slot,
          fromTime: slot.fromTime.tz(newTimezone),
          toTime: slot.toTime.tz(newTimezone)
        }));
      });
      return updatedAvailability;
    });
  };

  const handleCheckboxChange = (day) => {
    setAvailability(prev => ({
      ...prev,
      [day]: { ...prev[day], enabled: !prev[day].enabled },
    }));
  };

  const handleTimeChange = (day, index, field, newValue) => {
    setAvailability(prev => {
      const newSlots = prev[day].slots.map((slot, i) => {
        if (i === index) {
          if (field === 'fromTime') {
            const toTime = newValue.add(25, 'minute');
            return { ...slot, fromTime: newValue, toTime };
          }
          return { ...slot, [field]: newValue };
        }
        return slot;
      });
      return { ...prev, [day]: { ...prev[day], slots: newSlots } };
    });
  };

  const handleAddSlot = (day) => {
    setAvailability(prev => {
      const fromTime = dayjs().hour(12).minute(0);
      const toTime = fromTime.add(25, 'minute');
      const newSlot = {
        id: uuidv4(),
        fromTime,
        toTime
      };
      return { 
        ...prev, 
        [day]: { 
          ...prev[day], 
          slots: [...prev[day].slots, newSlot]
        } 
      };
    });
  };

  const handleAddConsecutiveSlot = (day, index) => {
    setAvailability(prev => {
      const updatedSlots = [...prev[day].slots];
      const currentSlot = updatedSlots[index];
      const newFromTime = currentSlot.toTime;
      const newToTime = newFromTime.add(25, 'minute');
      updatedSlots.splice(index + 1, 0, { id: uuidv4(), fromTime: newFromTime, toTime: newToTime });
      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const handleRemoveSlot = (day, index) => {
    setAvailability(prev => {
      const newSlots = prev[day].slots.filter((_, i) => i !== index);
      return { ...prev, [day]: { ...prev[day], slots: newSlots } };
    });
  };

  const handleEditClick = (day) => {
    setAvailability(prev => ({
      ...prev,
      [day]: { ...prev[day], isEditing: !prev[day].isEditing },
    }));
  };

  const handleRangeChange = (item) => {
    const newSelectedDates = { ...selectedDates };
    let currentDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
  
    while (currentDate <= endDate) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const dayName = format(currentDate, 'EEEE');
      newSelectedDates[dateStr] = selectedDays[dayName];
      currentDate = addDays(currentDate, 1);
    }
  
    setSelectedDates(newSelectedDates);
    setState({ ...state, ...item });
  };

  const handleDateToggle = (dateStr) => {
    setSelectedDates(prev => {
      const newSelectedDates = { ...prev, [dateStr]: !prev[dateStr] };
      const selectedDatesList = Object.keys(newSelectedDates).filter(date => newSelectedDates[date]);
      
      if (selectedDatesList.length > 0) {
        setState({
          ...state,
          selection: {
            startDate: new Date(selectedDatesList[0]),
            endDate: new Date(selectedDatesList[selectedDatesList.length - 1]),
            key: 'selection'
          }
        });
      } else {
        setState({
          ...state,
          selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
          }
        });
      }
      
      return newSelectedDates;
    });
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays(prev => {
      const newDays = { ...prev, [day]: !prev[day] };
      
      setAvailability(prevAvail => ({
        ...prevAvail,
        [day]: { ...prevAvail[day], enabled: newDays[day] }
      }));
  
      setSelectedDates(prevDates => {
        const newDates = { ...prevDates };
        const { startDate, endDate } = state.selection;
        let currentDate = new Date(startDate);
  
        while (currentDate <= endDate) {
          const dateStr = format(currentDate, 'yyyy-MM-dd');
          const dayOfWeek = format(currentDate, 'EEEE');
          
          if (dayOfWeek === day) {
            newDates[dateStr] = newDays[day];
          }
  
          currentDate = addDays(currentDate, 1);
        }
  
        return newDates;
      });
  
      return newDays;
    });
  };

  const handleSubmit = async () => {
    try {
      const availabilitySlots = [];
  
      Object.entries(selectedDates).forEach(([date, isSelected]) => {
        if (isSelected) {
          const dayOfWeek = format(new Date(date), 'EEEE');
          if (selectedDays[dayOfWeek] && availability[dayOfWeek]?.slots.length > 0) {
            availability[dayOfWeek].slots.forEach(slot => {
              const slotDate = dayjs.tz(date, userTimezone);
              const fromTime = slotDate.hour(slot.fromTime.hour()).minute(slot.fromTime.minute());
              const toTime = slotDate.hour(slot.toTime.hour()).minute(slot.toTime.minute());
  
              availabilitySlots.push({
                start: fromTime.utc().format('YYYY-MM-DDTHH:mm'),
                end: toTime.utc().format('YYYY-MM-DDTHH:mm')
              });
            });
          }
        }
      });
  
      const updatedData = {
        ...teacherData,
        Availability: { slots: availabilitySlots },
        selectedDays,
        userTimezone,
      };
  
      await dispatch(updateTeacher({
        teacherId: id,
        updatedData,
      }));
  
      notification.success({
        message: 'Success',
        description: 'Teacher availability updated successfully.',
      });
  
      setIsModalVisible(false);
      navigate("/Admin-Dashboard/Teachers");
    } catch (error) {
      console.error("Error editing teacher:", error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error editing the teacher. Please try again.',
      });
    }
  };

  const handleLongRangeSelection = () => {
    const newSelectedDates = {};
    selectedYears.forEach(year => {
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      let currentDate = startOfYear;

      while (currentDate <= endOfYear) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const monthStr = format(currentDate, 'yyyy-MM');
        const dayName = format(currentDate, 'EEEE');

        if (selectedMonths[monthStr] !== false) {
          newSelectedDates[dateStr] = selectedDays[dayName];
        }

        currentDate = addDays(currentDate, 1);
      }
    });

    setSelectedDates(newSelectedDates);
    setState({
      ...state,
      selection: {
        startDate: new Date(Math.min(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        endDate: new Date(Math.max(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        key: 'selection'
      }
    });
    setIsLongRangeModalVisible(false);
  };

  const handleDeleteEntireAvailability = () => {
    setSelectedDates({});
    setAvailability(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { enabled: false, slots: [], isEditing: false };
        return acc;
      }, {})
    );
    setSelectedDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = false;
        return acc;
      }, {})
    );
    setIsDeleteConfirmVisible(false);
  };

  return (
    <>
      <AdminNav />
      <div className="styled-container">
        <div className="calendar-container">
          <h2>Edit Teacher Availability</h2>
          
          <div className="day-selection">
            {Object.keys(selectedDays).map(day => (
              <div key={day} className="day-switch">
                <Switch
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                  className="day-switch-toggle"
                />
                <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                  {day}
                </span>
              </div>
            ))}
          </div>
          
          <DateRangePicker
            onChange={item => handleRangeChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            minDate={minDate}
            dayContentRenderer={day => <CustomDay day={day} selectedDates={selectedDates} />}
          />
          
          <div className="mini-calendar-switch">
            <Switch
              checked={showMiniCalendar}
              onChange={setShowMiniCalendar}
              checkedChildren="Edit Specific Dates"
              unCheckedChildren="Edit Specific Dates"
            />
          </div>
          
          {showMiniCalendar && (
            <MiniCalendar 
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          )}
          
          <div className="action-buttons">
            <Button 
              icon={<CalendarOutlined />}
              onClick={() => setIsLongRangeModalVisible(true)}
              className="select-long-range-button"
            >
              Select Long Range
            </Button>
            <Button 
              onClick={() => setIsModalVisible(true)}
              type="primary"
              className="set-availability-button"
            >
              Set Availability for Selected Dates
            </Button>
            <Button 
              onClick={() => setIsDeleteConfirmVisible(true)}
              type="danger"
              className="delete-availability-button"
            >
              Delete Entire Availability
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="Weekly Availability"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
        width={800}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="modal-content">
            <div className="timezone-selector">
              <label>Timezone availability:</label>
              <Select
                style={{ width: 200 }}
                value={userTimezone}
                onChange={handleTimezoneChange}
                showSearch
                optionFilterProp="children" 
                placeholder="Select a timezone" 
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                } 
              >
                {timeZones.map(tz => (
                  <Option key={tz} value={tz}>
                    {tz}
                  </Option>
                ))}
              </Select>
            </div>
            {daysOfWeek.map((day) => (
              <div key={day} className="day-availability">
                <div className="day-switch">
                  <Switch
                    checked={selectedDays[day]}
                    onChange={() => handleDayCheckboxChange(day)}
                    className="day-switch-toggle"
                  />
                  <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                    {day}
                  </span>
                </div>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditClick(day)}
                  type={availability[day]?.isEditing ? "primary" : "default"}
                  className="edit-button"
                  disabled={!selectedDays[day]}
                  style={{ 
                    opacity: selectedDays[day] ? 1 : 0.5,
                    cursor: selectedDays[day] ? 'pointer' : 'not-allowed'
                  }}
                >
                  {availability[day]?.isEditing ? 'Save' : 'Edit'}
                </Button>
                {selectedDays[day] && availability[day] && (
                  <div className="time-slots">
                    {availability[day].slots.map((slot, index) => (
                      <div key={slot.id} className="time-slot">
                        {availability[day]?.isEditing ? (
                          <>
                            <TimePicker
                              value={slot.fromTime}
                              onChange={(time) => handleTimeChange(day, index, 'fromTime', time)}
                              format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                              minuteStep={5}
                            />
                            <TimePicker
                              value={slot.toTime}
                              onChange={(time) => handleTimeChange(day, index, 'toTime', time)}
                              format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                              minuteStep={5}
                              disabled={true}
                            />
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => handleAddConsecutiveSlot(day, index)}
                              className="add-consecutive-slot-button"
                              disabled={index !== availability[day].slots.length - 1}
                            >
                              Add Consecutive
                            </Button>
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => handleRemoveSlot(day, index)}
                              danger
                              className="delete-button"
                            />
                          </>
                        ) : (
                          <span>
                            {slot.fromTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')} - {slot.toTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')}
                          </span>
                        )}
                      </div>
                    ))}
                    {availability[day]?.isEditing && (
                      <Button icon={<PlusOutlined />} onClick={() => handleAddSlot(day)} className="add-slot-button">
                        Add Slot
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </LocalizationProvider>
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleDeleteEntireAvailability}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the entire availability? This action cannot be undone.</p>
      </Modal>

      <Modal
        title={<Title level={3}><CalendarOutlined /> Select Long Range</Title>}
        visible={isLongRangeModalVisible}
        onCancel={() => setIsLongRangeModalVisible(false)}
        onOk={handleLongRangeSelection}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setIsLongRangeModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLongRangeSelection}>
            Apply Selection
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Years</Title>
          <Row gutter={[16, 16]}>
            {[...Array(10)].map((_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <Col key={year} span={6}>
                  <Checkbox
                    checked={selectedYears.includes(year)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedYears([...selectedYears, year]);
                        setSelectedMonths(prev => ({
                          ...prev,
                          ...Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).reduce((acc, month) => {
                            acc[month] = true;
                            return acc;
                          }, {})
                        }));
                      } else {
                        setSelectedYears(selectedYears.filter(y => y !== year));
                        setSelectedMonths(prev => {
                          const newMonths = { ...prev };
                          Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).forEach(month => {
                            delete newMonths[month];
                          });
                          return newMonths;
                        });
                      }
                    }}
                  >
                    <Text strong>{year}</Text>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </div>

        <Divider />

        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Months</Title>
          {selectedYears.map(year => (
            <div key={year}>
              <Title level={5} style={{ marginBottom: '10px' }}>{year}</Title>
              <Row gutter={[16, 16]}>
                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
                  const monthStr = `${year}-${String(index + 1).padStart(2, '0')}`;
                  return (
                    <Col key={monthStr} span={6}>
                      <Checkbox
                        checked={selectedMonths[monthStr] !== false}
                        onChange={(e) => {
                          setSelectedMonths(prev => ({
                            ...prev,
                            [monthStr]: e.target.checked
                          }));
                        }}
                      >
                        <Text>{month}</Text>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <Divider />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AdminEditTeacherEditAvailability;